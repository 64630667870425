import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../utils/loadingState';
import { domainApi } from 'Api/Domain/domainApi';
import { AppDispatch } from '../../store/store';
import {
  PricingResponseModel,
  ProductPricingModel,
} from 'Api/Domain/Types/types';

interface DomainReducerState {
  loadingState: LoadingState;
  contract: ProductPricingModel | null;
  products: ProductPricingModel[];
  estimatedValue: number | null;
  key: string | null;
}

const initialState = {
  loadingState: LoadingState.NotLoaded,
  contract: null,
  products: [],
  estimatedValue: null,
  key: null,
} satisfies DomainReducerState as DomainReducerState;

const slice = createSlice({
  name: 'domain',
  initialState,
  reducers: {
    clearLoadingState: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.NotLoaded,
      };
    },
    startLoadingDomain: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Loading,
      };
    },
    domainLoaded: (
      state,
      action: PayloadAction<{
        products: ProductPricingModel[];
        contract: ProductPricingModel | null;
        estimatedValue: number;
        key: string;
      }>
    ) => {
      const { products, contract, estimatedValue, key } = action.payload;

      return {
        ...state,
        products: products,
        contract: contract,
        estimatedValue: estimatedValue,
        key,
        loadingState: LoadingState.Loaded,
      };
    },
    noProductsFound: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Loaded,
      };
    },
    errorLoadingDomain: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Failed,
      };
    },
  },
});

export default slice.reducer;

const {
  clearLoadingState,
  startLoadingDomain,
  domainLoaded,
  errorLoadingDomain,
} = slice.actions;

export const reloadDomain = () => async (dispatch: AppDispatch) => {
  dispatch(clearLoadingState());
};

export const fetchDomain =
  (
    authToken: string,
    campaignId: string,
    suburb: string,
    state: string,
    postCode: string,
    estimatedValue: number
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingDomain());
      const {
        data: { products, contract },
      } = await domainApi(authToken).get<PricingResponseModel>('GetPricing', {
        campaignId: campaignId,
        suburb: suburb,
        state: state,
        postcode: postCode,
        country: 'Australia',
        estimatedValue: estimatedValue,
      });

      const key = `${suburb}|${state}|${postCode}`;
      dispatch(domainLoaded({ products, contract, estimatedValue, key }));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(errorLoadingDomain());
      return err.message;
    }
  };
