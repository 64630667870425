import { useEffect } from 'react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setAndShowErrorToast } from '../../store/config';
import { LoadingState } from '../../utils/loadingState';
import { agenciesApi } from 'Api/Agencies/agenciesApi';
import { useAzureAuth } from '../../hooks/useAzureAuth';
import { AppDispatch } from '../../store/store';
import { MarketingItem, MarketingPackage } from 'Api/Agencies/Types/Marketing';
import { AgencyAgentModel, AgencyInfo } from 'Api/Agencies/Types/Agencies';

interface AgenciesState {
  agencyInfo: {
    loadingState: LoadingState;
    info: AgencyInfo;
  };
  marketingPackages: {
    loadingState: LoadingState;
    packages: MarketingPackage[];
  };
  marketingItems: {
    loadingState: LoadingState;
    items: MarketingItem[];
  };
  agents: {
    loadingState: LoadingState;
    items: AgencyAgentModel[];
  };
}

const initialState = {
  agencyInfo: {
    loadingState: LoadingState.NotLoaded,
    info: {
      agreements: [0],
    },
  },
  marketingPackages: {
    loadingState: LoadingState.NotLoaded,
    packages: [],
  },
  marketingItems: {
    loadingState: LoadingState.NotLoaded,
    items: [],
  },
  agents: {
    loadingState: LoadingState.NotLoaded,
    items: [],
  },
} satisfies AgenciesState as AgenciesState;

const slice = createSlice({
  name: 'agencies',
  initialState,
  reducers: {
    startLoadingAgencyInfo: state => {
      return {
        ...state,
        agencyInfo: {
          ...initialState.agencyInfo,
          loadingState: LoadingState.Loading,
        },
      };
    },
    agencyInfoLoaded: (state, action: PayloadAction<AgencyInfo>) => {
      const info = action.payload;
      return {
        ...state,
        agencyInfo: {
          info: { ...info },
          loadingState: LoadingState.Loaded,
        },
      };
    },
    errorLoadingAgencyInfo: state => {
      return {
        ...state,
        agencyInfo: {
          ...initialState.agencyInfo,
          loadingState: LoadingState.Failed,
        },
      };
    },
    startLoadingMarketingPackages: state => {
      return {
        ...state,
        marketingPackages: {
          ...initialState.marketingPackages,
          loadingState: LoadingState.Loading,
        },
      };
    },
    marketingPackagesLoaded: (
      state,
      action: PayloadAction<MarketingPackage[]>
    ) => {
      return {
        ...state,
        marketingPackages: {
          packages: action.payload,
          loadingState: LoadingState.Loaded,
        },
      };
    },
    errorLoadingMarketingPackages: state => {
      return {
        ...state,
        marketingPackages: {
          ...initialState.marketingPackages,
          loadingState: LoadingState.Failed,
        },
      };
    },
    startLoadingMarketingItems: state => {
      return {
        ...state,
        marketingItems: {
          ...initialState.marketingItems,
          loadingState: LoadingState.Loading,
        },
      };
    },
    marketingItemsLoaded: (state, action: PayloadAction<MarketingItem[]>) => {
      return {
        ...state,
        marketingItems: {
          items: action.payload,
          loadingState: LoadingState.Loaded,
        },
      };
    },
    errorLoadingMarketingItems: state => {
      return {
        ...state,
        marketingItems: {
          ...initialState.marketingItems,
          loadingState: LoadingState.Failed,
        },
      };
    },
    startLoadingAgents: state => {
      return {
        ...state,
        agents: {
          ...initialState.agents,
          loadingState: LoadingState.Loading,
        },
      };
    },
    agentsLoaded: (state, action: PayloadAction<AgencyAgentModel[]>) => {
      return {
        ...state,
        agents: {
          items: action.payload,
          loadingState: LoadingState.Loaded,
        },
      };
    },
    errorLoadingAgents: state => {
      return {
        ...state,
        agents: {
          ...initialState.agents,
          loadingState: LoadingState.Failed,
        },
      };
    },
  },
});

export default slice.reducer;

const {
  startLoadingAgencyInfo,
  agencyInfoLoaded,
  errorLoadingAgencyInfo,
  startLoadingMarketingPackages,
  marketingPackagesLoaded,
  errorLoadingMarketingPackages,
  startLoadingMarketingItems,
  marketingItemsLoaded,
  errorLoadingMarketingItems,
  startLoadingAgents,
  agentsLoaded,
  errorLoadingAgents,
} = slice.actions;

export const fetchMarketingPackages =
  (authToken: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingMarketingPackages());
      const { data: marketingPackages } = await agenciesApi(authToken).get<
        MarketingPackage[]
      >('GetMarketingPackagesForAgency');

      dispatch(marketingPackagesLoaded(marketingPackages));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(errorLoadingMarketingPackages());
      return err.message;
    }
  };

export const fetchMarketingItems =
  (authToken: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingMarketingItems());
      const { data: marketingItems } = await agenciesApi(authToken).get<
        MarketingItem[]
      >('GetMarketingItemsForAgency');

      dispatch(marketingItemsLoaded(marketingItems));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(errorLoadingMarketingItems());
      return err.message;
    }
  };

export const fetchAgents =
  (authToken: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingAgents());
      const { data: agents } =
        await agenciesApi(authToken).get<AgencyAgentModel[]>(
          'GetAgentsInAgency'
        );

      dispatch(agentsLoaded(agents));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(errorLoadingAgents());
      return err.message;
    }
  };

export const useFetchAgencyInfo = () => {
  const dispatch = useAppDispatch();
  const [userAccount, getAuthToken] = useAzureAuth();
  const agencyInfoLoadingState = useAppSelector(
    state => state.agencies.agencyInfo.loadingState
  );

  const fetchAgencyInfo = async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingAgencyInfo());

      const authToken = await getAuthToken();

      if (!authToken) return;

      const { data: agencyInfo } = await agenciesApi(authToken).get<AgencyInfo>(
        'GetAgencyInformationForUser'
      );

      dispatch(agencyInfoLoaded(agencyInfo));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(errorLoadingAgencyInfo());
      return err.message;
    }
  };

  useEffect(() => {
    if (agencyInfoLoadingState !== LoadingState.NotLoaded || !userAccount)
      return;
    fetchAgencyInfo(dispatch);
  }, [agencyInfoLoadingState, userAccount]);
};
