import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ConnectionState } from '../utils/connectionState';
import { AppDispatch } from './store';

interface NotificationsState {
  connectionState: ConnectionState;
  connectionUpdatedAt: Date | null;
}

const initialState = {
  connectionState: ConnectionState.NotConnected,
  connectionUpdatedAt: null,
} satisfies NotificationsState as NotificationsState;

const slice = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {
    updateConnectionState: (state, action: PayloadAction<ConnectionState>) => {
      const connectionState = action.payload;
      const connectionUpdatedAt = new Date();

      return {
        ...state,
        connectionState,
        connectionUpdatedAt,
      };
    },
  },
});

export default slice.reducer;

// Actions
const { updateConnectionState } = slice.actions;

export const updateConnection =
  (connectionState: ConnectionState) => async (dispatch: AppDispatch) => {
    dispatch(updateConnectionState(connectionState));
  };
