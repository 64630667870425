import { useCallback } from 'react';
import { PropertyAddress, AustralianState } from '@urbanx/agx-ui-components';
import { useAppSelector } from './useAppSelector';
import StateAgreementFormType from '../constants/StateAgreementFormType';
import { RuralLandSize } from '../constants/RuralConstants';

export const useCanAccessRural = () => {
  const agreements = useAppSelector(
    state => state.agencies.agencyInfo.info?.agreements
  );

  const canAccessRural = useCallback(
    (totalLandArea: number | null, address: PropertyAddress) => {
      switch (address?.state) {
        case AustralianState[AustralianState.QLD]:
          return true;
        case AustralianState[AustralianState.NSW]:
          return (
            (totalLandArea ?? 0) < RuralLandSize ||
            agreements?.includes(StateAgreementFormType.NSWRuralAgreement)
          );
        default:
          return false;
      }
    },
    [agreements]
  );

  return canAccessRural;
};
