import React, { useState } from 'react';
import { useEffect } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { ConnectionState } from 'utils/connectionState';
import getConnection, {
  receiveNotifications,
} from '../Notifications/notifications';
import { updateCampaign } from 'features/campaigns/campaignsReducer';
import { setGeneratedFormDocuments } from 'features/form/formReducer';
import { updateConnection } from 'store/notificationsReducer';
import { PdfProcessedEvent } from 'features/form/types';
import { CampaignUpdatedEvent } from 'Api/Campaigns/Types/types';

interface SetupNotificationsLayoutProps {
  children: React.ReactNode;
}

export const SetupNotificationsLayout = ({
  children,
}: SetupNotificationsLayoutProps) => {
  const [hubConnection, setHubConnection] = useState<HubConnection | null>(
    null
  );
  const [lockResolver, setLockResolver] = useState<
    ((value?: unknown) => void) | null
  >(null);
  const dispatch = useAppDispatch();

  const [userAccount, getAuthToken] = useAzureAuth();
  const { connectionState, connectionUpdatedAt } = useAppSelector(
    state => state.notifications
  );

  // Add a lock to prevent the browser tab from sleeping while it isn't opened
  useEffect(() => {
    if (navigator && navigator.locks && navigator.locks.request) {
      const promise = new Promise(res => {
        setLockResolver(res);
      });

      navigator.locks.request(
        'autopilot_shared_lock',
        {
          mode: 'shared',
        },
        () => {
          return promise;
        }
      );
    }

    return () => {
      if (lockResolver) {
        lockResolver();
      }
    };
  }, []);

  useEffect(() => {
    if (connectionState === ConnectionState.Disconnected) {
      console.error('AutoPilot disconnected.');
    }
  }, [connectionState, connectionUpdatedAt]);

  useEffect(() => {
    if (hubConnection || !userAccount) return;

    dispatch(updateConnection(ConnectionState.Connecting));

    const hubCon = getConnection('campaigns', getAuthToken, () => {
      dispatch(updateConnection(ConnectionState.Connected));
    });

    hubCon.onclose(() => {
      dispatch(updateConnection(ConnectionState.Disconnected));
    });

    hubCon.onreconnecting(() => {
      dispatch(updateConnection(ConnectionState.Reconnecting));
    });

    hubCon.onreconnected(() => {
      dispatch(updateConnection(ConnectionState.Connected));
    });

    receiveNotifications(
      hubCon,
      'PdfProcessedEvent',
      (data: PdfProcessedEvent) => {
        dispatch(setGeneratedFormDocuments(data));
      }
    );

    receiveNotifications(
      hubCon,
      'CampaignUpdatedEvent',
      async (data: CampaignUpdatedEvent) => {
        const authToken = await getAuthToken();
        if (!!authToken) {
          dispatch(updateCampaign(authToken, data.campaignId));
        }
      }
    );

    dispatch(updateConnection(ConnectionState.Connected));

    setHubConnection(hubCon);
  }, [userAccount, hubConnection]);

  return children;
};
