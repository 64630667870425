import React from 'react';
import {
  AgxColumn,
  AgxSelect,
  AgxLabel,
  AgxRow,
  AgxBodyText,
  AgxErrorLabel,
} from '@urbanx/agx-ui-components';
import {
  dropDownNumberOptions,
  maxDropDownNumberValue,
  maxDropDownNumberLabel,
} from '../constants';

const FilterDropDown = ({
  label,
  getIcon = null,
  currentValue,
  minValue,
  maxValue,
  updateMinValue,
  updateMaxValue,
}) => {
  let errorMessage = '';

  if (minValue != null && maxValue != null) {
    if ((maxValue ?? maxDropDownNumberValue) < (minValue ?? 0)) {
      errorMessage = 'Maximum number must be higher than minimum number';
    }
  }

  const maxValueLabel =
    maxValue != null && maxValue === maxDropDownNumberValue
      ? maxDropDownNumberLabel
      : maxValue;

  return (
    <AgxColumn>
      <AgxRow fill centered>
        <AgxRow fill>
          <AgxLabel medium>{label}</AgxLabel>
        </AgxRow>
        {currentValue && (
          <AgxLabel extraClasses="neutral-grey-600" medium>
            {currentValue}
          </AgxLabel>
        )}
      </AgxRow>
      <AgxRow mediumGap centered>
        <AgxSelect
          id={`agx-select${label}DropDownMin`}
          icon={getIcon != null ? getIcon() : null}
          options={dropDownNumberOptions}
          defaultValue={{ label: minValue, value: minValue }}
          onValueChanged={({ value }) =>
            updateMinValue(value != null && value !== '' ? value : null)
          }
          dataTestId={`agx-select${label}DropDownMin`}
          hideOptionalLabel
          isMini
        />
        <AgxBodyText medium>-</AgxBodyText>
        <AgxSelect
          id={`agx-select${label}DropDownMax`}
          options={dropDownNumberOptions}
          defaultValue={{ label: maxValueLabel, value: maxValue }}
          onValueChanged={({ value }) =>
            updateMaxValue(value != null && value !== '' ? value : null)
          }
          dataTestId={`agx-select${label}DropDownMax`}
          hideOptionalLabel
          isMini
        />
      </AgxRow>
      {errorMessage && (
        <AgxRow>
          <AgxErrorLabel error={errorMessage} />
        </AgxRow>
      )}
    </AgxColumn>
  );
};

export default FilterDropDown;
