import { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxSlideUpModal,
  Images,
  PageStatusType,
  FormType,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { concatenateAddress } from 'utils/formatAddress';
import { useFormClearer } from 'hooks/useFormCleaner';
import useFormAddressSelector from 'selectors/useFormAddressSelector';
import HomeIconThemed from '../../elements/CampaignsView/assets/icons/homeIconThemed';
import { reloadCampaigns } from '../../campaigns/campaignsReducer';
import { PrefillSelector } from './components/PrefillSelector';
import { PageTitle } from './components/PageTitle';
import './FormHeader.scss';

export const FormHeader = ({
  currentPage,
  pages,
  setPage,
  displayTitle,
  viewPreFill,
  showListingDetails,
  showBreadcrumbs,
  listingAddress,
  saveForm,
  formTypeValue,
}) => {
  const formAddress = useFormAddressSelector();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clearFormData = useFormClearer();
  const pageStatuses = useAppSelector(state => state.form.pageStatuses);
  const [showBreadcrumbsSlider, setShowBreadcrumbsSlider] = useState(false);

  const formName = formTypeValue => {
    switch (formTypeValue) {
      case FormType.ListingGenerator:
        return 'Agreement';
      case FormType.ListingSubmission:
        return 'Listing Submission';
      case FormType.ContractRequest:
        return 'Contract';
      case FormType.ContractSubmission:
        return 'Contract Submission';
      default:
        return '';
    }
  };

  const { streetAddress, suburb, unitNumber, postalCode, state } =
    formAddress ?? {};

  const address = concatenateAddress(
    [unitNumber, streetAddress, suburb, postalCode, state],
    ', '
  );

  const {
    name: currentPageName,
    title,
    displayPreFill,
    displayListingDetails,
    exitButtonInHeader,
  } = currentPage;

  const filteredPagesWithCurrentPageName = pages
    ?.map((page, pageNumber) => {
      return {
        name: page.name,
        isActive: page.name === currentPageName,
        pageNumber: pageNumber,
        hasBreadcrumbs: page.hasBreadcrumbs,
      };
    })
    ?.filter(pageDetails => pageDetails.hasBreadcrumbs);

  const pagesInProgress = filteredPagesWithCurrentPageName?.map(
    ({ isActive, name, pageNumber }, orderedPageNumber) => {
      const pageStatus =
        pageStatuses?.find(p => p.pageName === name)?.status ??
        PageStatusType.Future;
      const classes = clsx(
        'agx-progress-item',
        isActive ? 'active' : pageStatus
      );
      return (
        <div key={name} className={classes} onClick={() => setPage(pageNumber)}>
          <div className="agx-progress-item-circle">
            <div className="agx-progress-item-text">
              {orderedPageNumber + 1}
            </div>
          </div>
        </div>
      );
    }
  ) ?? <></>;

  const pagesListContent = (
    <AgxColumn>
      {filteredPagesWithCurrentPageName?.map(
        ({ isActive, name, pageNumber }, orderedPageNumber) => {
          const pageStatus =
            pageStatuses?.find(p => p.pageName === name)?.status ??
            PageStatusType.Future;
          return (
            <div
              key={name}
              className={clsx('agx-pageListContainer', isActive && 'isActive')}
              onClick={() => {
                setPage(pageNumber);
                setShowBreadcrumbsSlider(false);
              }}
            >
              <AgxRow centered mediumGap>
                <div
                  className={clsx(
                    'progressCircle',
                    isActive ? 'active' : pageStatus
                  )}
                >
                  <label
                    className={clsx(
                      'agx-progress-item-text',
                      isActive && 'isActive inverted',
                      pageStatus === 'complete' ? '' : 'inverted'
                    )}
                  >
                    {orderedPageNumber + 1}
                  </label>
                </div>
                <p className="ui-body-medium">{name}</p>
              </AgxRow>
              <Images.ChevronRight />
            </div>
          );
        }
      ) ?? <></>}
    </AgxColumn>
  );

  const handleShowBreadcrumbsSlider = () => {
    setShowBreadcrumbsSlider(!showBreadcrumbsSlider);
  };

  // const isMobile = window.matchMedia('(max-width: 699px)').matches;  //ScreenSize() === Breakpoints.Mobile;
  // const isTabletPortrait = window.matchMedia('(max-width: 919px)').matches ; // ScreenSize() === Breakpoints.Tablet_Portrait;

  const validListingAddress = !!(listingAddress ?? address);
  const shouldShowListingDetails = showListingDetails || displayListingDetails;

  const exitForm = async () => {
    await saveForm();
    clearFormData();
    navigate('/');
    dispatch(reloadCampaigns());
  };

  return (
    <div className="formHeader">
      {currentPage && (
        <div className="desktopAndTabletLandscape pageTitle">
          <PageTitle
            onClick={handleShowBreadcrumbsSlider}
            title={displayTitle ?? title}
            breadcrumbsAvailable={showBreadcrumbs}
          />
        </div>
      )}
      {((shouldShowListingDetails && validListingAddress) ||
        showBreadcrumbs) && (
        <>
          {shouldShowListingDetails && showBreadcrumbs && (
            <div className="agx-header-listing-details mobileAndTabletPortrait">
              <AgxBodyText
                small
                secondary
                ellipsisOverflow
                extraClasses="listing-detail-line"
              >
                {formName(formTypeValue)}
              </AgxBodyText>
              <AgxBodyText
                small
                secondary
                ellipsisOverflow
                extraClasses="listing-detail-line"
              >
                {listingAddress ?? address}
              </AgxBodyText>
            </div>
          )}
          <div
            className="iconButtonStyle mobileAndTabletPortrait"
            onClick={exitForm}
          >
            <HomeIconThemed />
          </div>
          {showBreadcrumbs && (
            <div className="agx-header-progress mobileAndTabletPortrait">
              {pagesInProgress}
            </div>
          )}
        </>
      )}
      {currentPage && (
        <div className="mobileAndTabletPortrait pageTitle">
          <PageTitle
            onClick={handleShowBreadcrumbsSlider}
            title={displayTitle ?? title}
            breadcrumbsAvailable={showBreadcrumbs}
          />
        </div>
      )}
      {(viewPreFill || displayPreFill) && <PrefillSelector />}
      {!viewPreFill && exitButtonInHeader && (
        <div className="titleAction">
          <div className="agx-form-header-exit" onClick={exitForm}>
            <Images.CloseOutline className="exit" />
          </div>
        </div>
      )}
      {showBreadcrumbsSlider && (
        <AgxSlideUpModal
          content={pagesListContent}
          onClose={() => setShowBreadcrumbsSlider(false)}
        />
      )}
    </div>
  );
};
