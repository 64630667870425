import React from 'react';
import {
  AgxRow,
  AgxButton,
  Images,
  Breakpoints,
  useWindowSize,
} from '@urbanx/agx-ui-components';
import defaultLogo from '../../elements/CampaignsView/assets/images/Autopilot-logo.svg';
import './DesktopHeader.scss';
import { useAppSelector } from 'hooks/useAppSelector';
import IconBubble from 'layout/components/IconBubble';

interface DesktopHeaderProps {
  onBackToCampaigns: () => void;
  formId?: string;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  onBackToCampaigns,
  formId,
}) => {
  const logo = useAppSelector(
    state =>
      state.agencies.agencyInfo.info?.branding?.logo?.publicPath ?? defaultLogo
  );

  const screenSize = useWindowSize();
  const isMobile = screenSize === Breakpoints.Mobile;
  const isTabletPortrait = screenSize === Breakpoints.Tablet_Portrait;

  return (
    <>
      {(!formId || (formId && !isMobile && !isTabletPortrait)) && (
        <AgxRow centered fill spaceBetween extraClasses="desktopHeader">
          <img className="headerLogo" src={logo} alt="Property Logo" />
          {isMobile || isTabletPortrait ? (
            <IconBubble
              image={<Images.HomeOutline />}
              onClick={onBackToCampaigns}
              extraClasses="backButton"
            />
          ) : (
            <AgxButton
              text="Back"
              primary
              medium
              leftIcon={<Images.HomeOutline />}
              onClick={onBackToCampaigns}
              extraClasses="backButton"
            />
          )}
        </AgxRow>
      )}
    </>
  );
};
