import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FormSettingsState {
  listingAddress: string | null;
  showBreadcrumbs: boolean;
  showListingDetails: boolean;
  displayTitle: string | null;
  displayTitleVisible: boolean;
  formBackgroundInverted: boolean;
  viewPreFill: boolean;
  growContent: boolean;
  noGap: boolean;
}

const initialState = {
  listingAddress: null,
  showBreadcrumbs: false,
  showListingDetails: false,
  displayTitle: null,
  displayTitleVisible: true,
  formBackgroundInverted: false,
  viewPreFill: false,
  growContent: false,
  noGap: false,
} satisfies FormSettingsState as FormSettingsState;

const slice = createSlice({
  name: 'formSettings',
  initialState,
  reducers: {
    setFormSettings: (
      state,
      action: PayloadAction<Partial<FormSettingsState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearFormSettings: state => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export default slice.reducer;

const { setFormSettings, clearFormSettings } = slice.actions;

export { setFormSettings, clearFormSettings };
