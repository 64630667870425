import { useAppDispatch } from './useAppDispatch';
import { clearCmaData } from '../store/cmaPropertySearch/cmaPropertySearchReducer';
import { clearPoolCertificateData } from '../features/poolCertificate/poolCertificateReducer';
import { clearForm } from '../features/form/formReducer';
import { clearFormSettings } from '../features/form/formSettingsReducer';

export const useFormClearer = () => {
  const dispatch = useAppDispatch();

  const clearFormData = () => {
    dispatch(clearForm());
    dispatch(clearFormSettings());
    dispatch(clearPoolCertificateData());
    dispatch(clearCmaData());
  };

  return clearFormData;
};
