import { UploadedDocument } from '@urbanx/agx-ui-components';
import { useAppSelector } from '../hooks/useAppSelector';

const useFormDocumentsSelector = (): UploadedDocument[] => {
  const documents = useAppSelector(state => state.form.generatedFormDocuments);

  return documents ?? [];
};

export default useFormDocumentsSelector;
