import { rpDataApi } from 'Api/RpData/rpDataApi';
import { HasAccessResponse } from 'Api/RpData/Types/types';

export const rpDataHasAccess = async (
  authToken: string
): Promise<HasAccessResponse | null> => {
  try {
    const { data } =
      await rpDataApi(authToken).get<HasAccessResponse>('HasAccess');
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
