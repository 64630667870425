export const stringToTitleCase = (string: string): string => {
  if (!string) return '';

  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const stringToKebabCase = (string: string): string => {
  if (!string) return '';

  return string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
};

// This function takes an array of strings and returns a formatted string
// ["One"] => "One"
// ["One", "Two"] => "One and Two"
// ["One", "Two", "Three"] => "One, Two and Three"
// ["One", "Two", "Three", "Four"] => "One, Two, Three and Four"
export const formatListOfStrings = (items: string[]): string => {
  return items.reduce((result, current, index, array) => {
    const separator =
      index === array.length - 1
        ? ''
        : index === array.length - 2
          ? ' and '
          : ', ';
    return `${result}${current}${separator}`;
  }, '');
};
