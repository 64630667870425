import React from 'react';
import './PrefillSelector.scss';

export const PrefillSelector = () => {
  //TODO: we'll bring this back when prefill is available
  return (
    <div className="prefillSelector">
      {/* <Images.EyeOutline className="rapid" />
      <div className="agx-form-header-rapid-text">Show prefilled</div> */}
    </div>
  );
};
