import React from 'react';
import { useSelector } from 'react-redux';
import FileUpload from '../../elements/FileUpload/FileUpload';

const AttachAsFile = ({ id, defaultValue, onValueChanged }) => {
  const { campaignId } = useSelector(state => state.form);

  return (
    <>
      <FileUpload
        key={`${id}-fileUpload`}
        id={`${id}-fileUpload`}
        documentType="Miscellaneous"
        extensions={['pdf']}
        fileData={defaultValue}
        campaignId={campaignId}
        onValueChanged={({ value }) =>
          onValueChanged({
            value: value && value.length > 0 ? value[0] : null,
          })
        }
        multiple={false}
        required
        validate={undefined}
      />
    </>
  );
};

export default AttachAsFile;
