import React from 'react';
import { AgxRow } from '@urbanx/agx-ui-components';
import { ReactComponent as UrbanXLogo } from '../../assets/icons/urbanx-logo.svg';
import './UrbanXFooter.scss';

export const UrbanXFooter = () => {
  return (
    <AgxRow extraClasses="footerPoweredBy" centered>
      <span>Powered by</span>
      <UrbanXLogo />
    </AgxRow>
  );
};
