import ResumeFormDetails from '../Common/ResumeFormDetails';
import { CampaignStage } from '@urbanx/agx-ui-components';
import AgencyAgreementSigningPanel from './AgencyAgreementSigningPanel';
import { getEnumValue } from 'helpers/enumHelper';

const AgencyAgreementInfoPanel = ({ campaign, currentTab, mobile = false }) => {
  const campaignStage = getEnumValue(CampaignStage, campaign.stage);

  switch (campaignStage) {
    case CampaignStage.AgreementDraft:
      return (
        <ResumeFormDetails
          currentTab={currentTab}
          campaign={campaign}
          mobile={mobile}
        />
      );
    case CampaignStage.AgreementVendorSigning:
    case CampaignStage.AgreementAgentSigning:
    case CampaignStage.AgreementSignedByAllParties:
      return (
        <AgencyAgreementSigningPanel
          currentTab={currentTab}
          campaign={campaign}
          mobile={mobile}
        />
      );
    default:
      return null;
  }
};

export default AgencyAgreementInfoPanel;
