import React from 'react';
import './Card.scss';
import { AgxColumn } from '@urbanx/agx-ui-components';
import clsx from 'clsx';

interface CardProps {
  largeGap?: boolean;
  centerJustified?: boolean;
  children: JSX.Element | JSX.Element[];
}

const Card = ({
  children,
  largeGap = false,
  centerJustified = false,
}: CardProps) => {
  const classes = clsx(
    'AgxCard',
    largeGap && 'largeGap',
    centerJustified && 'centerJustified'
  );

  return <AgxColumn extraClasses={classes}>{children}</AgxColumn>;
};

export default Card;
