import React, { useEffect, useState } from 'react';
import { AgxRow, AgxLabel } from '@urbanx/agx-ui-components';
import './Checkbox.scss';

const Checkbox = ({ id, label, onChange, required, defaultValue = null }) => {
  const [checked, setChecked] = useState(defaultValue ?? false);

  useEffect(() => {
    onChange({ id: id, value: checked });
  }, [checked]);

  return (
    <AgxRow mediumGap fill extraClasses="checkbox">
      <input
        id={id}
        type="checkbox"
        checked={checked}
        required={required}
        onChange={() => setChecked(!checked)}
      />
      <AgxLabel medium htmlFor={id}>
        {label}
      </AgxLabel>
    </AgxRow>
  );
};

export default Checkbox;
