import { useMemo } from 'react';
import { Roles, useAzureAuth } from '../../../hooks/useAzureAuth';
import { User } from '../../../types/Auth';
import {
  AgencyEntityType,
  EntityTypes,
  PartnershipEntityType,
} from '../reportingDashboardReducer';

export const useAvailableEntityTypes = () => {
  const [userAccount] = useAzureAuth();

  const account = userAccount as User;

  const availableEntityTypes = useMemo(() => {
    const roles = account?.roles ?? [];

    let filteredEntityTypes = [...EntityTypes];

    if (!account?.agent?.indexOf('urbanx.io')) {
      filteredEntityTypes = EntityTypes.filter(
        x => x.label !== PartnershipEntityType
      );
    }

    if (roles.includes(Roles.Director)) {
      return filteredEntityTypes;
    }

    return filteredEntityTypes.filter(
      x => x.label !== AgencyEntityType && x.label !== PartnershipEntityType
    );
  }, [account?.roles]);

  return availableEntityTypes;
};
