import React, { useMemo } from 'react';
import clsx from 'clsx';
import {
  AgxButton,
  AgxHeader,
  AgxRow,
  Breakpoints,
  Images,
  useWindowSize,
} from '@urbanx/agx-ui-components';
import {
  AllPropertyDetails,
  PropertySuggestionItem,
} from 'Api/RpData/Types/types';
import RPDataDisclaimer from 'components/common/RPDataDisclaimer';
import { PropertyResultsLoader } from '../PropertyResultsLoader/PropertyResultsLoader';
import { Tabs } from '../PropertySearch';
import { PropertyImageCard } from './PropertyImageCard/PropertyImageCard';
import './PropertyConfirmation.scss';

interface PropertyConfirmationProps {
  confirmationId: string;
  currentTab: Tabs;
  suggestion: PropertySuggestionItem | null;
  allPropertyDetails: AllPropertyDetails[];
  disabled?: boolean;
  loading: boolean;
  onPropertyConfirmed: (propertyDetail: AllPropertyDetails) => void;
  onSearchAgain: () => void;
  onLoadMore?: () => void;
}

export const PropertyConfirmation: React.FC<PropertyConfirmationProps> = ({
  confirmationId,
  currentTab,
  suggestion,
  allPropertyDetails,
  disabled = false,
  loading,
  onPropertyConfirmed,
  onSearchAgain,
  onLoadMore,
}) => {
  const screenSize = useWindowSize();
  const isDesktop = screenSize === Breakpoints.Desktop;

  const title = useMemo(() => {
    if (currentTab === Tabs.Address) {
      return "Is this the property you're looking for?";
    } else if (currentTab === Tabs.LotPlan) {
      return `${suggestion?.suggestionProperties?.length} ${
        suggestion?.suggestionProperties?.length === 1
          ? 'property'
          : 'properties'
      } found for ${suggestion?.suggestionLine1}`;
    }
  }, [currentTab, suggestion]);

  return (
    <div
      className={clsx({
        'agx-propertyConfirmation': true,
        'animate__animated animate__fadeInUp': true,
        singleProperty: suggestion?.suggestionProperties?.length === 1,
      })}
      data-testid="agx-propertySearcherContainer"
    >
      <AgxRow
        spaceBetween={isDesktop}
        justifyCenter={!isDesktop}
        centered
        extraClasses="titleSearchAgain"
      >
        <AgxHeader
          size={3}
          inverse
          centered
          data-testid="agx-PropertySearcherTitle"
        >
          {title}
        </AgxHeader>
        {isDesktop && (
          <AgxButton
            extraClasses="propertySearchAgainBtn"
            id={confirmationId}
            dataTestId={`agx-propertySearchNoBtn`}
            text="Search again"
            rightIcon={<Images.SearchOutline />}
            disabled={disabled}
            onClick={onSearchAgain}
            medium
            hollow
            dark
          />
        )}
      </AgxRow>
      {allPropertyDetails.length > 0 &&
        allPropertyDetails.map((allPropertyDetail, index) => (
          <PropertyImageCard
            key={`propertyDetail${index}`}
            currentTab={currentTab}
            allPropertyDetails={allPropertyDetail}
            onClick={onPropertyConfirmed}
          />
        ))}
      {loading && (
        <PropertyResultsLoader
          isDesktop={isDesktop}
          loadAtATime={Math.min(
            (suggestion?.suggestionProperties.length ?? 0) -
              allPropertyDetails.length,
            10
          )}
        />
      )}
      {!loading &&
        ((suggestion?.suggestionProperties.length ?? 0) >
          allPropertyDetails.length ||
          !isDesktop) && (
          <div className="agx-buttonCollection">
            {(suggestion?.suggestionProperties.length ?? 0) >
              allPropertyDetails.length && (
              <AgxButton
                id={confirmationId}
                dataTestId={`testid-propertySearchYesBtn`}
                text="Load more"
                onClick={onLoadMore}
                hollow
                large
                dark
              />
            )}
            {!isDesktop && (
              <AgxButton
                id={confirmationId}
                dataTestId={`agx-propertySearchNoBtn`}
                text="Search again"
                rightIcon={<Images.SearchOutline />}
                onClick={onSearchAgain}
                disabled={disabled}
                large
                hollow
                dark
              />
            )}
          </div>
        )}
      <RPDataDisclaimer />
    </div>
  );
};
