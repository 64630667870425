import { useState } from 'react';
import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxHeader,
  Images,
} from '@urbanx/agx-ui-components';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { selectContract } from '../../../../../../campaigns/campaignsReducer';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import FormDetailsHeader from '../../Common/FormDetailsHeader';
import { setAndShowErrorToast } from 'store/config';
import { useFormLoader } from 'hooks/useFormLoader';

const ResumeContractSubmission = ({
  campaign,
  contract,
  lastUpdate,
  mobile = false,
}) => {
  const { address, campaignId } = campaign;

  const lastUpdatedOn = `Last updated ${moment(lastUpdate).format(
    'DD/MM/YYYY'
  )}`;

  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(address);

  const dispatch = useDispatch();
  const { loadForm } = useFormLoader();
  const [disableButtons, setDisableButtons] = useState(false);

  const resumeContractSubmission = async () => {
    try {
      setDisableButtons(true);

      await loadForm(campaignId, contract.contractId);
    } catch (err) {
      console.error(err);
      dispatch(setAndShowErrorToast('Failed to resume Contract submission'));
    }

    setDisableButtons(false);
  };

  return (
    <>
      <FormDetailsHeader
        formName="Contract"
        detail={lastUpdatedOn}
        onBack={() => dispatch(selectContract(null))}
      />
      <AgxColumn centered veryLargeGap extraClasses="resumeFormDetails">
        <AgxBodyText small>Submission Incomplete</AgxBodyText>
        <AgxHeader size={2} centered>
          {addressLineOne},
          <br />
          {addressLineTwo}
        </AgxHeader>
        <Images.SparkleForm />
        <AgxColumn mediumGap centered>
          <AgxHeader size={3} centered>
            Let's continue from where you left off
          </AgxHeader>
          <AgxBodyText small centered>
            There are more fields to fill
          </AgxBodyText>
        </AgxColumn>
      </AgxColumn>
      <div className="campaignModalButtons">
        <AgxButton
          hollow={!mobile}
          primary={mobile}
          wide={mobile}
          large
          text="Resume Submission"
          rightIcon={<Images.ArrowForwardOutline />}
          onClick={resumeContractSubmission}
          shrinkOnLargerDevices
          disabled={disableButtons}
        />
      </div>
    </>
  );
};

export default ResumeContractSubmission;
