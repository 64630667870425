import ContentLoader from 'react-content-loader';

const SidebarLeaderboardRowLoader = () => {
  return (
    <div className="leaderboard-row">
      <ContentLoader viewBox="0 0 320 23" style={{ height: 'auto' }}>
        <rect x="0" y="0" rx="0" ry="0" width="23" height="23" />
        <rect x="26" y="0" rx="11.5" ry="11.5" width="23" height="23" />
        <rect x="52" y="0" rx="0" ry="0" width="185" height="23" />
      </ContentLoader>
    </div>
  );
};

export default SidebarLeaderboardRowLoader;
