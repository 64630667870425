import { useMemo } from 'react';
import {
  AgxLegalDescription,
  LegalDescription,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import { LoadingState } from 'utils/loadingState';

interface LegalDescriptionProps {
  id: string;
  hidePropertyType: boolean;
  readonly: boolean;
  legalDescription: LegalDescription;
  onChange: (change: { id: string; value: any }) => void;
  required?: boolean;
  validate?: boolean;
}

const LegalDescriptionElement: React.FC<LegalDescriptionProps> = ({
  id,
  hidePropertyType,
  readonly,
  legalDescription,
  onChange,
  required,
  validate,
}) => {
  const { loadingState: titleReferenceLoadingState } = useAppSelector(
    state => state.rpData.titleReference
  );

  const legalDescriptionMapped = useMemo(
    () => ({
      ...legalDescription,
      parcels: legalDescription.parcels.map(parcel => ({
        ...parcel,
        titleReference:
          titleReferenceLoadingState === LoadingState.Loading
            ? 'loading...'
            : parcel.titleReference,
      })),
    }),
    [legalDescription, titleReferenceLoadingState]
  );

  return (
    <AgxLegalDescription
      id={id}
      key={id}
      hidePropertyType={hidePropertyType}
      readonly={readonly}
      legalDescription={legalDescriptionMapped ?? legalDescription}
      onChange={onChange}
      required={required}
      validate={validate}
    />
  );
};

export default LegalDescriptionElement;
