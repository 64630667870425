import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AgxColumn, AgxRow, Images } from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { LoadingState } from 'utils/loadingState';
import RedirectToProd from 'features/elements/RedirectToProd/RedirectToProd';
import { useAzureAuth } from '../hooks/useAzureAuth';
import { updateAgent, useLoadFeatureToggles } from 'store/agentInfoReducer';
import { useFetchAvailableForms } from 'features/forms/formsReducer';
import { ReactComponent as AutopilotLogo } from './assets/images/Autopilot-logo.svg';
import IconBubble from './components/IconBubble';
import './BrandedLayout.scss';

interface BrandedLayoutProps {
  children: React.ReactNode;
}

const hexToRgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)?.map(x => parseInt(x, 16)) ?? [0, 0, 0];
  return `rgba(${r},${g},${b},${alpha})`;
};

const hexToRgb = (hex: string) => {
  const [r, g, b] = hex.match(/\w\w/g)?.map(x => parseInt(x, 16)) ?? [0, 0, 0];
  return `${r}, ${g}, ${b}`;
};

const setDocumentStyle = (variable: string, value: string | null) => {
  if (value == null) return;

  document.documentElement.style.setProperty(variable, value);
};

const BrandedLayout: React.FC<BrandedLayoutProps> = ({ children }) => {
  const [userAccount] = useAzureAuth();
  const { pathname } = useLocation();
  const fetchAvailableForms = useFetchAvailableForms();
  const { agentId } = useAppSelector(state => state.agentInfo);
  const {
    loadingState: agencyInfoLoadingState,
    info: { branding },
  } = useAppSelector(state => state.agencies.agencyInfo);
  const { availableForms, loadingState: formsLoadingState } = useAppSelector(
    state => state.forms
  );
  const canBeginForm = useMemo(() => {
    return (
      formsLoadingState === LoadingState.Loaded &&
      availableForms.some(af => af.beginsCampaign)
    );
  }, [formsLoadingState, availableForms]);

  const dispatch = useAppDispatch();

  const loadFeatureToggles = useLoadFeatureToggles();

  const isUrbanXStaff = useMemo(() => {
    return agentId && agentId.indexOf('urbanx.io') > 0;
  }, [agentId]);

  useEffect(() => {
    if (userAccount) {
      dispatch(
        updateAgent({
          agency: userAccount.agency ?? null,
          agent: userAccount.agent ?? null,
        })
      );
    }
  }, [userAccount, dispatch, updateAgent]);

  useEffect(() => {
    loadFeatureToggles();
    fetchAvailableForms();
  }, []);

  const navigate = useNavigate();

  const updateBranding = useCallback(() => {
    if (!branding) return;

    // if agent is not urbanx member
    if (!!(window as any)?.APP_CONFIG?.REDIRECT_TO_PROD && !isUrbanXStaff) {
      setDocumentStyle('--brand-primary-color', '#FAFAFA');
    } else {
      setDocumentStyle('--brand-primary-color', branding.backgroundColor);
      if (branding.backgroundColor != null) {
        setDocumentStyle(
          '--brand-primary-color-rgb',
          hexToRgb(branding.backgroundColor)
        );
      }
      setDocumentStyle(
        '--brand-text-primary-color',
        branding.backgroundTextColor
      );
      if (branding.backgroundTextColor != null) {
        setDocumentStyle(
          '--brand-text-primary-color-rgb',
          hexToRgb(branding.backgroundTextColor)
        );
      }
      if (branding.accentColor != null) {
        setDocumentStyle('--brand-secondary-color', branding.accentColor);
        setDocumentStyle('--brand-outline-color', `${branding.accentColor}20`);
        setDocumentStyle(
          '--brand-secondary-color-alpha90',
          `${branding.accentColor}90`
        );
      }
      setDocumentStyle('--brand-text-button-color', branding.buttonTextColor);

      if (
        branding.sidebarBackgroundColor != null &&
        branding.sidebarBackgroundColorOpacity != null
      ) {
        setDocumentStyle(
          '--brand-sidebar-background-color',
          hexToRgba(
            branding.sidebarBackgroundColor,
            parseInt(branding.sidebarBackgroundColorOpacity) * 0.01
          )
        );
      }
      if (
        branding.inboxBackgroundColor != null &&
        branding.inboxBackgroundColorOpacity != null
      ) {
        setDocumentStyle(
          '--brand-inbox-background-color',
          hexToRgba(
            branding.inboxBackgroundColor,
            parseInt(branding.inboxBackgroundColorOpacity) * 0.01
          )
        );
      }
    }
  }, [branding, isUrbanXStaff]);

  useEffect(() => {
    if (agencyInfoLoadingState === LoadingState.Loaded) {
      updateBranding();
    }
  }, [agencyInfoLoadingState, updateBranding]);

  const propertyLogo = useMemo(() => {
    return branding?.logo?.publicPath ?? AutopilotLogo;
  }, [branding]);

  useEffect(() => {
    if (
      agencyInfoLoadingState === LoadingState.Loaded &&
      formsLoadingState === LoadingState.Loaded
    ) {
      // remove the first-paint from DOM
      var div = document.getElementById('first-paint');
      if (div !== null) {
        div.classList.add('page-loaded');
        setTimeout(() => {
          if (div && div.parentNode) {
            div.parentNode.removeChild(div);
          }
        }, 500);
      }
    }
  }, [agencyInfoLoadingState, formsLoadingState]);

  useEffect(() => {
    if (
      formsLoadingState === LoadingState.Loaded &&
      !canBeginForm &&
      pathname === '/'
    ) {
      navigate('/dashboard');
    }
  }, [formsLoadingState, canBeginForm, pathname]);

  // if agent is not urbanx member
  if (!!(window as any)?.APP_CONFIG?.REDIRECT_TO_PROD && !isUrbanXStaff) {
    return <RedirectToProd />;
  }

  return (
    <AgxColumn extraClasses="brandedLayout" data-testid="agx-brandedLayout">
      <AgxRow extraClasses="brandedHeader" centered spaceBetween>
        {propertyLogo && (
          <img
            className="headerLogo"
            src={propertyLogo as string}
            alt="Property Logo"
          />
        )}

        <AgxRow extraClasses="dashboardHeader-card">
          {canBeginForm && (
            <IconBubble
              image={<Images.HomeOutline />}
              onClick={() => {
                navigate('/');
              }}
            />
          )}
          <IconBubble
            image={<Images.TargetOutline />}
            onClick={() => {
              navigate('/dashboard');
            }}
          />
        </AgxRow>
      </AgxRow>
      {children}
    </AgxColumn>
  );
};

export default BrandedLayout;
