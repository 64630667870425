import React from 'react';
import ContentLoader from 'react-content-loader';

const UpcomingSettlementRowLoader = () => {
  return (
    <ContentLoader
      viewBox="0 0 100 50"
      height="50px"
      width="100%"
      preserveAspectRatio="none"
    >
      <rect x="2" y="5" rx="2" ry="2" width="40" height="40" />
      <rect x="50" y="5" rx="2" ry="2" width="25" height="40" />
      <rect x="83" y="5" rx="2" ry="2" width="15" height="40" />
    </ContentLoader>
  );
};

export default UpcomingSettlementRowLoader;
