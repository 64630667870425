export const formatCurrency = (
  elm: number | null,
  digits: number = 2
): string => {
  if (elm == null) return '$0' + (!digits ? '' : '.') + '0'.repeat(digits);

  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: digits,
  });

  const formattedResult = formatter.format(elm);

  if (!digits && formattedResult.includes('.')) {
    return formattedResult.split('.')[0];
  }

  return formattedResult;
};

export const formatCurrencyWithConditionalDecimals = (
  elm: number,
  digits: number = 2
): string => {
  // Check if the value has a decimal point
  if (elm % 1 === 0) {
    // If value is an integer, return it without decimal places
    return new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 0,
    }).format(elm);
  } else {
    // If value has a decimal point, format with the specified decimal places
    return new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: digits,
    }).format(elm);
  }
};
export const formatNumberWithCommasDecimals = (number: number): string => {
  const formatter = new Intl.NumberFormat('en-AU', {
    maximumFractionDigits: 2,
  });

  return formatter.format(number);
};

export const formatCurrencyAsNumber = (
  text: string,
  digits: number = 2
): string => {
  const number = Number(text);

  if (Number.isNaN(number)) return '0.00';

  return number.toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

export const formatNumber = (text: string, digits: number = 0): string => {
  const number = Number(text);

  if (Number.isNaN(number)) return '0';

  // If the number is an integer or if digits is 0, format without decimal places
  if (Number.isInteger(number) || digits === 0) {
    return Math.round(number).toLocaleString();
  }

  return number.toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};
