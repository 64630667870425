// Define specific types for the breakpoints
export const BreakpointPixels = {
  Mobile: 699,
  Tablet_Portrait: 919,
  Tablet_Landscape: 1299,
} as const;

// Define Breakpoints with specific numeric values
export enum Breakpoints {
  Mobile = 0,
  Tablet_Landscape = 1,
  Tablet_Portrait = 2,
  Laptop = 3,
  Desktop = 4,
}

// ScreenSize function with correct return type
export const ScreenSize = (): Breakpoints => {
  if (window.screen.width <= BreakpointPixels.Mobile) return Breakpoints.Mobile;
  if (window.screen.width <= BreakpointPixels.Tablet_Portrait)
    return Breakpoints.Tablet_Portrait;
  if (window.screen.width <= BreakpointPixels.Tablet_Landscape)
    return Breakpoints.Tablet_Landscape;

  return Breakpoints.Desktop;
};
