import { PlaceholderImage } from '@urbanx/agx-ui-components';
import React from 'react';
import './EntityImage.scss';
import clsx from 'clsx';

interface EntityImageProps {
  photoUri: string | null;
  backgroundColour: string | null;
  backgroundTextColour: string | null;
  name: string | null;
  addRingToImage: boolean;
}

const EntityImage = ({
  photoUri,
  backgroundColour,
  backgroundTextColour,
  name,
  addRingToImage,
}: EntityImageProps) => {
  if (!photoUri) {
    return (
      <PlaceholderImage
        title={name ?? ' '}
        size={32}
        backgroundColour={backgroundColour}
        textColour={backgroundTextColour}
      />
    );
  }

  return (
    <div
      className={clsx('entityImage', addRingToImage && 'ring')}
      style={{
        backgroundColor: backgroundColour ?? 'var(--neutral-grey-300)',
      }}
    >
      <img
        src={photoUri}
        alt=""
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          height: 'auto',
        }}
      />
    </div>
  );
};

export default EntityImage;
