import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgxBodyText, AgxColumn, AgxHeader } from '@urbanx/agx-ui-components';
import { useAzureAuth } from 'hooks/useAzureAuth';
import useQuery from 'hooks/useQuery';
import { storeRPDataAccessCode } from '../../rpData/services/storeRPDataAccessCode';
import './Authentication.scss';

interface RPDataAuthenticatedProps {
  children?: React.ReactNode;
}

const RpDataAuthenticated = (props: RPDataAuthenticatedProps) => {
  const query = useQuery();
  const navigate = useNavigate();
  const [, getAuthToken] = useAzureAuth();
  const [errorStoringAccessCode, setErrorStoringAccessCode] = useState(false);

  const storeAccessCode = useCallback(
    async (code: string) => {
      if (!getAuthToken) return;

      const token = await getAuthToken();

      if (!token) return;

      const storedAccessToken = await storeRPDataAccessCode(code, token);

      if (!storedAccessToken) {
        setErrorStoringAccessCode(true);
        return;
      }

      navigate('/');
    },
    [getAuthToken]
  );

  useEffect(() => {
    const code = query.get('code');

    if (!code) {
      navigate('/');
      return;
    }

    storeAccessCode(code);
  }, []);

  if (errorStoringAccessCode) {
    return (
      <AgxColumn extraClasses="authentication-error">
        <AgxColumn extraClasses="authentication-error-body">
          <AgxHeader centered size={3}>
            An error occurred
          </AgxHeader>
          <AgxBodyText>
            Autopilot failed to setup your RPData account. Contact UrbanX
            support at <a href="mailto:support@urbanx.io">support@urbanx.io</a>{' '}
            for assistance.
          </AgxBodyText>
        </AgxColumn>
      </AgxColumn>
    );
  }

  return props.children;
};

export default RpDataAuthenticated;
