import { cleanStreetAddress } from 'utils/formatAddress';
import { stringToTitleCase } from 'utils/formatString';

export const EmailStyle = {
  Formal: 'Formal',
  Casual: 'Casual',
};

export const CreateEmailMessage = (
  emailStyle,
  address,
  signatories,
  agent,
  agencyName
) => {
  switch (emailStyle) {
    case EmailStyle.Formal:
      return formalMessage(address, signatories, agent?.name, agencyName);
    case EmailStyle.Casual:
      return casualMessage(address, signatories, agent?.name, agencyName);
    default:
      return '';
  }
};

function getSignatorySeparator(index, length) {
  if (index === length - 1) {
    return ',';
  } else if (index === length - 2) {
    return ' & ';
  } else {
    return ', ';
  }
}

function getFormattedSignatoryFirstNames(signatories) {
  return signatories
    .map(({ firstName, preferredName }, index) => {
      const name = preferredName || firstName;
      const separator = getSignatorySeparator(index, signatories.length);
      const formattedName = stringToTitleCase(name);

      return `${formattedName}${separator}`;
    })
    .join('');
}

function formalMessage(address, signatories, agentName, agencyName) {
  const signerNames = getFormattedSignatoryFirstNames(signatories);

  return (
    `Hi ${signerNames}\n\n` +
    `Please find attached the listing documentation for ${cleanStreetAddress(
      address
    )}.\n\n` +
    `To proceed, click “Review Documents” above and follow the prompts to complete the agreement.\n\n` +
    `There are questions throughout marked as optional fields. We would appreciate it if you could complete as much information as possible so we can best represent your property.\n\n` +
    `Should you have any questions please do not hesitate to contact me.\n\n` +
    `Kind regards,\n${stringToTitleCase(
      agentName?.firstName
    )} ${stringToTitleCase(agentName?.lastName)}\n${stringToTitleCase(
      agencyName
    )}`
  );
}

const casualMessage = (address, signatories, agentName, agencyName) => {
  const signerNames = getFormattedSignatoryFirstNames(signatories);

  return (
    `Hi ${signerNames}\n\n` +
    `Please find attached the listing documentation for ${cleanStreetAddress(
      address
    )}.\n\n` +
    `To proceed, click “Review Documents” above and follow the prompts to complete the Agreement together with the Material Fact Checklist for your property.\n\n` +
    `Should you have any questions please do not hesitate to contact me.\n\n` +
    `Kind regards,\n${stringToTitleCase(
      agentName?.firstName
    )} ${stringToTitleCase(agentName?.lastName)}\n${stringToTitleCase(
      agencyName
    )}`
  );
};
