import {
  formatNumber,
  formatCurrency,
  AgxCaption,
  AgxRow,
  AgxColumn,
} from '@urbanx/agx-ui-components';
import clsx from 'clsx';
import { StatCardData } from './StatCard';
import { ReactComponent as AboveTargetIcon } from './arrow-circle-up.svg';
import { ReactComponent as BelowTargetIcon } from './arrow-circle-down.svg';

interface GoalInformationProps {
  item: StatCardData;
  isAboveTarget: boolean;
  goal: number;
  centerJustified: boolean;
}

const GoalInformation = ({
  item,
  isAboveTarget,
  goal,
  centerJustified,
}: GoalInformationProps) => {
  const formatValue = (item: StatCardData) => {
    if (item.isPercentage) return `${formatNumber(goal?.toString(), 1)}%`;
    if (item.isCurrency) return formatCurrency(goal, 0);
    return formatNumber(goal.toString());
  };

  const aboveTargetClass = isAboveTarget ? 'above' : 'below';
  const aboveTargetText = isAboveTarget ? 'above target' : 'below target';

  const target = (
    <>
      {isAboveTarget ? <AboveTargetIcon /> : <BelowTargetIcon />}
      <AgxCaption extraClasses={clsx('targetText', aboveTargetClass)}>
        {formatValue(item)}
      </AgxCaption>
    </>
  );

  const targetText = (
    <AgxCaption extraClasses={clsx('targetText', aboveTargetClass)}>
      {aboveTargetText}
    </AgxCaption>
  );

  if (centerJustified) {
    return (
      <AgxRow centered extraClasses="wideGoal">
        {target}
        {targetText}
      </AgxRow>
    );
  }

  return (
    <AgxColumn alignStart extraClasses="targetSection">
      <AgxRow smallGap>{target}</AgxRow>
      {targetText}
    </AgxColumn>
  );
};

export default GoalInformation;
