import { useCallback } from 'react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { rpDataApi } from 'Api/RpData/rpDataApi';
import { setAndShowErrorToast } from '../config';
import { getLatLongForAddress } from '../../utils/getLatLong';
import { LoadingState } from '../../utils/loadingState';
import { useAzureAuth } from '../../hooks/useAzureAuth';
import {
  CMAParams,
  ComparablePropertyModel,
  ComparablePropertyResponseModel,
} from './types';

interface CMAPropertySearchState {
  searchNowEnabled: boolean | null;
  manualSearchEnabled: boolean | null;
  cmaPropertyResults: ComparablePropertyModel[] | null;
  cmaPropertyResultsLoadingState: LoadingState;
  cmaPropertyResultsLoadingMore: boolean;
  page: number;
  totalPages: number;
  totalProperties: number;
  params: CMAParams | null;
}

const initialState = {
  searchNowEnabled: null,
  manualSearchEnabled: null,
  cmaPropertyResults: null,
  cmaPropertyResultsLoadingState: LoadingState.NotLoaded,
  cmaPropertyResultsLoadingMore: false,
  page: 0,
  totalPages: 0,
  totalProperties: 0,
  params: null,
} satisfies CMAPropertySearchState as CMAPropertySearchState;

// Slice
const slice = createSlice({
  name: 'cmaPropertySearch',
  initialState: initialState,
  reducers: {
    cmaPropertySearchLoading: state => {
      return {
        ...state,
        cmaPropertyResults: initialState.cmaPropertyResults,
        cmaPropertyResultsLoadingState: LoadingState.Loading,
        cmaPropertyResultsLoadingMore: false,
      };
    },
    cmaPropertySearchLoaded: (
      state,
      action: PayloadAction<{
        properties: ComparablePropertyModel[] | null;
        page: number;
        totalPages: number;
        totalProperties: number;
        params: CMAParams;
      }>
    ) => {
      const { properties, page, totalPages, totalProperties, params } =
        action.payload;
      return {
        ...state,
        cmaPropertyResults: properties ? [...properties] : null,
        cmaPropertyResultsLoadingState: LoadingState.Loaded,
        page,
        totalPages,
        totalProperties,
        params,
        cmaPropertyResultsLoadingMore: false,
      };
    },
    clearCmaData: state => {
      return {
        ...state,
        ...initialState,
      };
    },
    startLoadingMoreCMAProperties: state => {
      return {
        ...state,
        cmaPropertyResultsLoadingMore: true,
      };
    },
    loadMoreCMAProperties: (
      state,
      action: PayloadAction<{
        properties: ComparablePropertyModel[] | null;
        page: number;
        params: CMAParams;
      }>
    ) => {
      const { properties, page, params } = action.payload;
      return {
        ...state,
        cmaPropertyResults: [
          ...(state.cmaPropertyResults ? state.cmaPropertyResults : []),
          ...(properties ? properties : []),
        ],
        page,
        params,
        cmaPropertyResultsLoadingMore: false,
      };
    },
    setSearchOptionsEnabled: (
      state,
      action: PayloadAction<{
        searchNowEnabled: boolean;
        manualSearchEnabled: boolean;
      }>
    ) => {
      return {
        ...state,
        searchNowEnabled: action.payload.searchNowEnabled,
        manualSearchEnabled: action.payload.manualSearchEnabled,
      };
    },
  },
});

export default slice.reducer;

// Actions
const {
  cmaPropertySearchLoading,
  cmaPropertySearchLoaded,
  clearCmaData,
  startLoadingMoreCMAProperties,
  loadMoreCMAProperties,
  setSearchOptionsEnabled,
} = slice.actions;

export { clearCmaData, setSearchOptionsEnabled };

export const useFetchCMAPropertySearch = () => {
  const dispatch = useAppDispatch();
  const [, getAuthToken] = useAzureAuth();

  const fetchCMAPropertySearch = useCallback(
    async (params: CMAParams) => {
      try {
        dispatch(cmaPropertySearchLoading());

        const authToken = await getAuthToken();

        if (!authToken) return;

        const { data: comparableProperties } = await rpDataApi(
          authToken
        ).get<ComparablePropertyResponseModel>('GetComparableProperties', {
          ...params,
          pageSize: 20,
          page: 0,
        } satisfies CMAParams as CMAParams);

        const comparablePropertyDetails = (await Promise.all(
          comparableProperties?.properties?.map(async property => {
            if (property == null) return null;

            const { address, latitude, longitude } = property;

            if (latitude == null && longitude == null && address != null) {
              const latLng = await getLatLongForAddress(address);

              if (latLng) {
                return {
                  ...property,
                  ...latLng,
                };
              }
            }

            return property;
          })
        )) as ComparablePropertyModel[] | null;

        const cmaPropertySearch = {
          properties: comparablePropertyDetails,
          page: comparableProperties.page,
          totalPages: comparableProperties.totalPages,
          totalProperties: comparableProperties.totalProperties,
          params,
        };

        dispatch(cmaPropertySearchLoaded(cmaPropertySearch));

        return 'success';
      } catch (err: any) {
        console.error(err);
        dispatch(setAndShowErrorToast(err.message));
        return err.message;
      }
    },
    [
      dispatch,
      setAndShowErrorToast,
      cmaPropertySearchLoaded,
      getLatLongForAddress,
      cmaPropertySearchLoading,
      getAuthToken,
    ]
  );

  return fetchCMAPropertySearch;
};

export const useFetchMoreCMAProperties = () => {
  const dispatch = useAppDispatch();
  const [, getAuthToken] = useAzureAuth();
  const cmaPropertySearchState = useAppSelector(
    state => state.cmaPropertySearch
  );

  const fetchMoreCMAProperties = useCallback(async () => {
    try {
      dispatch(startLoadingMoreCMAProperties());

      const authToken = await getAuthToken();

      if (!authToken) return;

      if (
        cmaPropertySearchState.page >=
        cmaPropertySearchState.totalPages - 1
      ) {
        return 'Max page count reached';
      }

      const params = {
        ...cmaPropertySearchState.params,
        page: (cmaPropertySearchState.page ?? 0) + 1,
        pageSize: 20,
      } satisfies CMAParams as CMAParams;

      const { data: comparableProperties } = await rpDataApi(
        authToken
      ).get<ComparablePropertyResponseModel>('GetComparableProperties', params);

      const comparablePropertyDetails = (await Promise.all(
        comparableProperties?.properties?.map(async property => {
          if (property == null) return null;

          const { address, latitude, longitude } = property;

          if (latitude == null && longitude == null && address != null) {
            const latLng = await getLatLongForAddress(address);

            if (latLng) {
              return {
                ...property,
                ...latLng,
              };
            }
          }

          return property;
        })
      )) as ComparablePropertyModel[] | null;

      const cmaPropertySearch = {
        properties: comparablePropertyDetails ?? [],
        page: comparableProperties.page,
        params,
      };

      dispatch(loadMoreCMAProperties(cmaPropertySearch));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      return err.message;
    }
  }, [
    getLatLongForAddress,
    dispatch,
    getAuthToken,
    loadMoreCMAProperties,
    setAndShowErrorToast,
  ]);

  return fetchMoreCMAProperties;
};
