import { useAppDispatch } from './useAppDispatch';
import { setAndShowErrorToast } from 'store/config';

export const useToaster = () => {
  const dispatch = useAppDispatch();

  const showErrorToast = (errorMessage: string) => {
    dispatch(setAndShowErrorToast(errorMessage));
  };

  return { showErrorToast };
};
