import { useMemo } from 'react';
import Labels from './Labels';
import NswLabels from './NswLabels';
import QldLabels from './QldLabels';
import { AustralianState } from '@urbanx/agx-ui-components';

/**
 * Based on the validated stateName, the hook creates a corresponding labels object using new QldLabels() for 'QLD' or new NswLabels() for 'NSW'.
 * The default case creates a new 'QLD' labels object.
 *
 * The hook retrieves the label from the created labels object using the provided key.
 * This ensures that the correct label is returned for the given state and key combination.
 *
 * The hook employs useMemo to optimize performance by memoizing the labels object.
 * This ensures that the labels object is only recreated when either stateName or key changes.
 *
 */
const ValidStates = [AustralianState.NSW, AustralianState.QLD];

const useStateFormLabels = (state, key) => {
  const labels = useMemo(() => {
    if (!ValidStates.includes(state)) {
      return undefined;
    }

    if (!key) {
      return undefined;
    }

    let labelsObj = new Labels();

    switch (state) {
      case AustralianState.QLD:
        labelsObj = new QldLabels();
        break;
      case AustralianState.NSW:
        labelsObj = new NswLabels();
        break;
      default:
        labelsObj = new QldLabels();
    }

    return labelsObj[key];
  }, [state, key]);

  return labels;
};

export default useStateFormLabels;
