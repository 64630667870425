import React from 'react';
import './IconBubble.scss';
import clsx from 'clsx';

interface IconBubbleProps {
  image: React.ReactNode;
  onClick: () => void;
  extraClasses?: string;
}

const IconBubble: React.FC<IconBubbleProps> = ({
  image,
  onClick,
  extraClasses,
}) => {
  const iconBubbleClasses = clsx('icon-bubble', extraClasses && extraClasses);

  return (
    <div className={iconBubbleClasses} onClick={onClick}>
      {image}
    </div>
  );
};

export default IconBubble;
