import {
  AgxColumn,
  AgxHeader,
  SubmitType,
  RenderAnimation,
  RenderAnimationType,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import GenerationStatus from '../GenerationStatus';

interface AgencyAgreementGeneratingProps {
  generationStatus: GenerationStatus;
  submitType: SubmitType;
}

const AgencyAgreementGenerating = ({
  generationStatus,
  submitType,
}: AgencyAgreementGeneratingProps) => {
  const formName = useAppSelector(state => state.form.formName);

  if (generationStatus === GenerationStatus.Generating) {
    return (
      <>
        <RenderAnimation
          icon={
            submitType === SubmitType.GeneratePdf
              ? RenderAnimationType.Settings
              : RenderAnimationType.Mail
          }
        />
        <AgxColumn largeGap fill centered>
          <AgxHeader size={1} inverse centered>
            One moment
          </AgxHeader>
          <AgxHeader secondary size={3}>
            Generating {formName}...
          </AgxHeader>
        </AgxColumn>
      </>
    );
  }

  if (generationStatus === GenerationStatus.Generated) {
    return (
      <>
        <RenderAnimation
          icon={
            submitType === SubmitType.GeneratePdf
              ? RenderAnimationType.Mail
              : RenderAnimationType.Champagne
          }
        />
        <AgxHeader size={1} centered inverse>
          Generated!
        </AgxHeader>
      </>
    );
  }
};

export default AgencyAgreementGenerating;
