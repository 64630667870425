import React, { useEffect, useState } from 'react';
import { AgxRadio, AgxDatePicker, AgxLabel } from '@urbanx/agx-ui-components';
import moment from 'moment';

export const LengthOfAgreement = ({
  onLengthOfAgreementChange,
  specificDateChange,
  defaultLengthOfAgreement = null,
  defaultSpecificDate = null,
  days,
  validate,
  restrictDuration,
  startDate,
}) => {
  const [selectedAgreementLength, setSelectedAgreementLength] = useState(
    defaultLengthOfAgreement ?? '90'
  );

  useEffect(() => {
    onLengthOfAgreementChange(selectedAgreementLength);
  }, [selectedAgreementLength]);

  const [specificDate, setSpecificDate] = useState(
    defaultSpecificDate && moment(defaultSpecificDate).format('YYYY-MM-DD')
  );

  useEffect(() => {
    specificDateChange(specificDate);
  }, [specificDate]);

  const lengthOfAgreement = days.map(d => (
    <AgxRadio
      key={'agreementLength' + d}
      id={'agreementLength' + d}
      label={d + ' days'}
      onCheckChanged={() => setSelectedAgreementLength(d)}
      checked={selectedAgreementLength === d}
    />
  ));

  return (
    <div className="agxRadio">
      <AgxLabel medium>Length of Appointment</AgxLabel>
      <div className="agxRadio-group">
        {lengthOfAgreement}
        <AgxRadio
          key="agreementLength-specificDate"
          id="agreementLength-specificDate"
          label="Specific end date"
          onCheckChanged={() => setSelectedAgreementLength('specificDate')}
          checked={selectedAgreementLength === 'specificDate'}
          subContent={
            selectedAgreementLength === 'specificDate' && [
              <AgxDatePicker
                key="LengthOfAgreementSpecificDate"
                id="LengthOfAgreementSpecificDate"
                onValueChanged={({ value }) => setSpecificDate(value)}
                defaultValue={specificDate}
                required
                date
                noHeader
                stretch
                maxDaysFromDate={
                  restrictDuration
                    ? {
                        days: Math.max(...days.map(Number)),
                        date: startDate,
                      }
                    : undefined
                }
                error={
                  validate && (!specificDate || specificDate === 'Invalid date')
                    ? 'Enter specific end date'
                    : undefined
                }
              />,
            ]
          }
        />
      </div>
    </div>
  );
};
