export interface LatLong {
  latitude: number | null;
  longitude: number | null;
}

export const getLatLongForAddress = (
  address?: string
): Promise<LatLong | null> => {
  if (
    !address ||
    !window?.google?.maps?.Geocoder ||
    !window?.google?.maps?.GeocoderStatus
  ) {
    return Promise.resolve(null);
  }
  const geocoder = new window.google.maps.Geocoder();

  return new Promise<LatLong | null>((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (
        status === window.google.maps.GeocoderStatus.OK &&
        results != null &&
        results.length > 0
      ) {
        const result = results.find(r => r.partial_match !== true);
        if (result) {
          const location = result.geometry.location;
          resolve({
            latitude: location.lat(),
            longitude: location.lng(),
          });
        } else {
          reject(`Geocoding failed for ${address} due to no exact match`);
        }
      } else {
        reject(`Geocoding failed for ${address} with status: ${status}`);
      }
    });
  }).catch(error => {
    console.warn(error);
    return null;
  });
};
