import {
  AgxBodyText,
  AgxColumn,
  AgxDivider,
  AgxHeader,
} from '@urbanx/agx-ui-components';
import { ReactComponent as NoSettlementsImg } from '../../../assets/images/empty-inbox.svg';
import React, { useMemo } from 'react';
import './UpcomingSettlements.scss';
import UpcomingSettlementRowLoader from './UpcomingSettlementRowLoader';
import moment from 'moment';
import UpcomingSettlementRow from './UpcomingSettlementRow';
import { PartnershipStatistics } from '@urbanx/agx-ui-components/dist/cjs/types/components/portal/Reporting/types';

interface UpcomingSettlementProps {
  loading: boolean;
  partnershipStats: PartnershipStatistics | undefined;
}

const UpcomingSettlements = ({
  loading,
  partnershipStats,
}: UpcomingSettlementProps) => {
  const noData =
    !loading &&
    (!partnershipStats ||
      (partnershipStats?.upcomingSettlements?.length ?? 0) === 0);

  const nextAnniversary = useMemo(() => {
    let nextAnniversary = moment(
      partnershipStats?.partnershipDetails?.anniversaryDate
    );
    const now = moment();

    while (nextAnniversary.isBefore(now)) {
      nextAnniversary = nextAnniversary.add(1, 'year');
    }

    return nextAnniversary;
  }, [partnershipStats?.partnershipDetails?.anniversaryDate]);

  const upcomingSettlementsBeforeAnniversary = useMemo(() => {
    return (
      partnershipStats?.upcomingSettlements?.filter(settlement => {
        const { settlementDate } = settlement;
        return moment(settlementDate).isBefore(nextAnniversary);
      }) ?? []
    );
  }, [nextAnniversary, partnershipStats?.upcomingSettlements]);

  const upcomingSettlementsOnOrAfterAnniversary = useMemo(() => {
    return (
      partnershipStats?.upcomingSettlements?.filter(settlement => {
        const { settlementDate } = settlement;
        const date = moment(settlementDate);
        return date.isAfter(nextAnniversary) || date === nextAnniversary;
      }) ?? []
    );
  }, [nextAnniversary, partnershipStats?.upcomingSettlements]);

  return (
    <>
      <AgxColumn extraClasses="upcomingSettlementsBody">
        <AgxHeader size={5}>Upcoming Settlements</AgxHeader>
        <AgxColumn extraClasses="upcomingSettlements">
          <div className="upcomingSettlementRow header">
            <AgxColumn extraClasses="upcomingSettlement" centerJustified>
              <AgxBodyText small>Property</AgxBodyText>
            </AgxColumn>
            <AgxColumn
              extraClasses="upcomingSettlement settlement"
              centerJustified
            >
              <AgxBodyText small>Settlement</AgxBodyText>
            </AgxColumn>
            <AgxColumn
              extraClasses="upcomingSettlement commission"
              centerJustified
            >
              <AgxBodyText small>Comm</AgxBodyText>
            </AgxColumn>
          </div>
          {noData && (
            <AgxColumn fill centered extraLargeGap extraClasses="noDataDiv">
              <NoSettlementsImg width="200px" height="205px" />
              <AgxBodyText large centered>
                No upcoming settlements
              </AgxBodyText>
            </AgxColumn>
          )}
          {loading &&
            [...Array(10)].map((i, idx) => (
              <>
                <UpcomingSettlementRowLoader key={idx} />
                {idx < 10 && <AgxDivider />}
              </>
            ))}
          {!loading &&
            !noData &&
            upcomingSettlementsBeforeAnniversary?.map((settlement, idx) => (
              <UpcomingSettlementRow key={idx} settlement={settlement} />
            ))}
          {!loading &&
            !noData &&
            upcomingSettlementsOnOrAfterAnniversary.length > 0 && (
              <div className="upcomingSettlementRow anniversary">
                <AgxColumn
                  extraClasses="upcomingSettlement property"
                  centerJustified
                >
                  <AgxBodyText small>Anniversary</AgxBodyText>
                </AgxColumn>
                <AgxColumn
                  extraClasses="upcomingSettlement settlement"
                  centerJustified
                >
                  <AgxBodyText small>
                    {moment(nextAnniversary).format('DD MMM')}
                  </AgxBodyText>
                </AgxColumn>
              </div>
            )}
          {!loading &&
            !noData &&
            upcomingSettlementsOnOrAfterAnniversary.length > 0 &&
            upcomingSettlementsOnOrAfterAnniversary?.map((settlement, idx) => (
              <UpcomingSettlementRow key={idx} settlement={settlement} />
            ))}
        </AgxColumn>
      </AgxColumn>
    </>
  );
};

export default UpcomingSettlements;
