export const fileSizeInWords = (fileSizeInBytes: number): string => {
  const units: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex: number = 0;

  while (fileSizeInBytes >= 1024 && unitIndex < units.length - 1) {
    fileSizeInBytes /= 1024;
    unitIndex++;
  }

  const sizeInWords: string = fileSizeInBytes.toFixed(2).replace('.00', '');

  return `${sizeInWords} ${units[unitIndex]}`;
};
