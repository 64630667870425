import { rpDataApi } from 'Api/RpData/rpDataApi';

export const storeRPDataAccessCode = async (
  code: string,
  authToken: string
): Promise<boolean> => {
  try {
    const { status } = await rpDataApi(authToken).post('StoreAccessCode', {
      code: code,
    });

    return status === 200;
  } catch (err) {
    console.error(err);
    return false;
  }
};
