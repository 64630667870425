import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import LogRocket from 'logrocket';
import { useAzureAuth } from 'hooks/useAzureAuth';

export const Widget = () => {
  const [userAccount] = useAzureAuth();
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);

  const initWidget = () => {
    if (window.FreshworksWidget) {
      window.FreshworksWidget('prefill', 'ticketForm', {
        name: `${userAccount?.fullName}`,
        email: `${userAccount?.agent}`,
      });

      LogRocket.getSessionURL(sessionURL => {
        window.FreshworksWidget('prefill', 'ticketForm', {
          custom_fields: {
            cf_logrocket_session_id: sessionURL,
          },
        });

        window.FreshworksWidget(
          'hide',
          'ticketForm',
          ['custom_fields.cf_logrocket_session_id'],
          {}
        );
      });
    }
  };

  useEffect(() => {
    if (!userAccount || !isWidgetLoaded) return;

    initWidget();
  }, [userAccount, isWidgetLoaded]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://aus-widget.freshworks.com/widgets/51000002987.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setIsWidgetLoaded(true);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Helmet>
      <script>
        {`window.fwSettings={
                    'widget_id':51000002987
                };
                !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() `}
      </script>
    </Helmet>
  );
};
