import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  AgxRadio,
  AgxLabel,
  UploadedDocument,
} from '@urbanx/agx-ui-components';
import SearchNow from '../SearchNow/SearchNow';
import AddManually from '../AddManually/AddManually';
import AttachAsFile from '../AttachAsFile/AttachAsFile';
import { ComparativeAddress, ComparativeType, Comparatives } from './types';
import { useAppSelector } from 'hooks/useAppSelector';

interface ComparativeMarketAnalysisProps {
  id: string;
  onValueChanged: (value: { id: string; value: any }) => void;
  defaultValue: Comparatives;
}

const ComparativeMarketAnalysis = ({
  id,
  onValueChanged,
  defaultValue,
}: ComparativeMarketAnalysisProps) => {
  const { searchNowEnabled, manualSearchEnabled } = useAppSelector(
    state => state.cmaPropertySearch
  );

  const [cmaSelected, setCmaSelected] = useState(
    defaultValue?.comparativeType || ComparativeType.NotAvailable
  );

  useEffect(() => {
    if (cmaSelected === ComparativeType.NotAvailable) {
      onValueChanged({
        id,
        value: {
          comparativeType: ComparativeType.NotAvailable,
        },
      });
    }
  }, [cmaSelected]);

  const searchUpdated = useCallback(
    (addresses: ComparativeAddress[]) => {
      onValueChanged({
        id,
        value: {
          comparativeType: ComparativeType.Search,
          searchAddresses: [...addresses],
        },
      });
    },
    [onValueChanged, id]
  );

  const manualSearchUpdated = useCallback(
    (addresses: ComparativeAddress[]) => {
      onValueChanged({
        id,
        value: {
          comparativeType: ComparativeType.Manual,
          manualAddresses: [...addresses],
        },
      });
    },
    [onValueChanged, id]
  );

  const attachedFileUpdated = useCallback(
    (fileInfo: UploadedDocument) => {
      onValueChanged({
        id,
        value: {
          comparativeType: ComparativeType.AttachAsFile,
          attachedFileInfo: fileInfo,
        },
      });
    },
    [onValueChanged, id]
  );

  const comparativeMarketAnalysisOptions = useMemo(() => {
    let options = [];

    if (searchNowEnabled) {
      options.push({
        id: ComparativeType.Search,
        label: 'Search Now',
        component: [
          <SearchNow
            id={ComparativeType.Search}
            key={ComparativeType.Search}
            onValueChanged={({ value }: { value: any }) => searchUpdated(value)}
            defaultValue={defaultValue?.searchAddresses}
          />,
        ],
      });
    }

    if (manualSearchEnabled) {
      options.push({
        id: ComparativeType.Manual,
        label: 'Add Manually',
        component: [
          <AddManually
            id={ComparativeType.Manual}
            key={ComparativeType.Manual}
            onValueChanged={({ value }) => manualSearchUpdated(value)}
            defaultValue={defaultValue?.manualAddresses}
          />,
        ],
      });
    }

    options.push({
      id: ComparativeType.AttachAsFile,
      label: 'Attach as File',
      component: [
        <AttachAsFile
          id={ComparativeType.AttachAsFile}
          key={ComparativeType.AttachAsFile}
          onValueChanged={({ value }: { value: any }) =>
            attachedFileUpdated(value)
          }
          defaultValue={defaultValue?.attachedFileInfo}
        />,
      ],
    });
    options.push({
      id: ComparativeType.NotAvailable,
      label: 'N/A',
      component: [],
    });

    return options;
  }, [
    searchNowEnabled,
    defaultValue,
    attachedFileUpdated,
    manualSearchUpdated,
    searchUpdated,
  ]);

  return (
    <div className="agxRadio">
      <AgxLabel medium>Comparative Market Analysis</AgxLabel>
      <div className="agxRadio-group">
        {comparativeMarketAnalysisOptions.map(({ id, label, component }) => (
          <AgxRadio
            key={id}
            id={id}
            label={label}
            checked={cmaSelected === id}
            onCheckChanged={() => setCmaSelected(id)}
            subContent={cmaSelected === id ? component : undefined}
          />
        ))}
      </div>
    </div>
  );
};

export default ComparativeMarketAnalysis;
