import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  BasicCertificateInfo,
  CertificateInfo,
} from '@urbanx/agx-ui-components';
import { AppDispatch } from '../../store/store';
import { LoadingState } from '../../utils/loadingState';
import { poolCertificateApi } from '../../Api/Pools/poolCertificateApi';
import { PoolSafetyCertificateResult } from '../../Api/Pools/Types/types';

interface PoolCertificateState {
  loadingState: LoadingState;
  poolSafetyCertificate: CertificateInfo | null;
  poolBuildingCertificate: BasicCertificateInfo | null;
  lotNumber: string | null;
  planType: string | null;
  planNumber: string | null;
}

const initialState = {
  loadingState: LoadingState.NotLoaded,
  poolSafetyCertificate: null,
  poolBuildingCertificate: null,
  lotNumber: null,
  planType: null,
  planNumber: null,
} satisfies PoolCertificateState as PoolCertificateState;

const slice = createSlice({
  name: 'poolDocumentation',
  initialState,
  reducers: {
    startLoadingPoolCertificate: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Loading,
      };
    },
    poolCertificateLoaded: (
      state,
      action: PayloadAction<{
        poolSafetyCertificate: CertificateInfo | null;
        poolBuildingCertificate: BasicCertificateInfo | null;
        lot: string;
        planType: string;
        planNumber: string;
      }>
    ) => {
      const {
        poolSafetyCertificate,
        poolBuildingCertificate,
        lot,
        planType,
        planNumber,
      } = action.payload;

      return {
        ...state,
        poolSafetyCertificate,
        poolBuildingCertificate,
        lotNumber: lot,
        planType: planType,
        planNumber: planNumber,
        loadingState: LoadingState.Loaded,
      };
    },
    noCertificateFound: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Loaded,
      };
    },
    errorLoadingPoolCertificate: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Failed,
      };
    },
    clearPoolCertificateData: state => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export default slice.reducer;

const {
  startLoadingPoolCertificate,
  poolCertificateLoaded,
  errorLoadingPoolCertificate,
  clearPoolCertificateData,
} = slice.actions;

export { clearPoolCertificateData };

export const fetchPoolCertificate =
  (
    authToken: string,
    campaignId: string,
    lot: string | null,
    planType: string | null,
    planNumber: string | null
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      if (lot === null || planType === null || planNumber === null) return;

      dispatch(startLoadingPoolCertificate());
      const plan = `${planType}/${planNumber}`;
      const {
        data: { poolSafetyCertificate, poolBuildingCertificate },
      } = await poolCertificateApi(authToken).get<PoolSafetyCertificateResult>(
        'GetPoolSafetyCertificate',
        {
          campaignId: campaignId,
          state: 'Queensland',
          country: 'Australia',
          lotNumber: lot,
          planNumber: plan,
        }
      );

      dispatch(
        poolCertificateLoaded({
          poolSafetyCertificate,
          poolBuildingCertificate,
          lot,
          planType,
          planNumber,
        })
      );
    } catch (err) {
      console.error(err);
      dispatch(errorLoadingPoolCertificate());
    }
  };
