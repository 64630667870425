import { useCallback, useEffect } from 'react';
import { LegalDescription, ParcelDetails } from '@urbanx/agx-ui-components';
import { LoadingState } from 'utils/loadingState';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { updateFormValue } from 'features/form/formReducer';
import {
  fetchTitleReferences,
  resetTitleReference,
} from 'features/rpData/rpDataReducer';
import useFormPropertyDetailsSelector from 'selectors/useFormPropertyDetailsSelector';
import useFormAddressSelector from 'selectors/useFormAddressSelector';

const LegalDescriptionPreloader = () => {
  const [, getAuthToken] = useAzureAuth();
  const dispatch = useAppDispatch();

  const campaignId = useAppSelector(state => state.form.campaignId);
  const {
    loadingState: titleReferenceLoadingState,
    items: parcels,
    campaignId: cachedCampaignId,
  } = useAppSelector(state => state.rpData.titleReference);
  const legalDescription = useAppSelector<LegalDescription>(
    state => state.form.formValues.LegalDescription
  );
  const propertyDetails = useFormPropertyDetailsSelector();
  const propertyAddress = useFormAddressSelector();

  const parcelRequireTitleLookup = useCallback(
    (parcel: ParcelDetails) =>
      (parcel.titleReference == null || parcel.titleReference === '') &&
      parcel.lotNumber != null &&
      parcel.lotNumber !== '' &&
      parcel.planType != null &&
      parcel.planType !== '' &&
      parcel.planNumber != null &&
      parcel.planNumber !== '' &&
      (!cachedCampaignId || cachedCampaignId !== campaignId),
    [cachedCampaignId, campaignId]
  );

  useEffect(() => {
    if (
      titleReferenceLoadingState !== LoadingState.Loaded ||
      !parcels?.length ||
      !legalDescription?.parcels?.length ||
      parcels?.length !== legalDescription?.parcels?.length ||
      (!cachedCampaignId && cachedCampaignId !== campaignId)
    ) {
      return;
    }

    dispatch(
      updateFormValue({
        id: 'LegalDescription',
        value: {
          ...legalDescription,
          parcels: parcels,
        },
      })
    );
  }, [titleReferenceLoadingState, campaignId]);

  useEffect(() => {
    if (cachedCampaignId !== campaignId) {
      dispatch(resetTitleReference());
    }
  }, [cachedCampaignId, campaignId]);

  useEffect(() => {
    const { propertyId, parcels } = propertyDetails;

    if (
      !getAuthToken ||
      titleReferenceLoadingState !== LoadingState.NotLoaded ||
      (!propertyId && !propertyAddress?.state) ||
      !campaignId
    )
      return;

    if (parcels?.some(parcelRequireTitleLookup)) {
      getAuthToken().then(async authToken => {
        if (!authToken) return;

        dispatch(
          fetchTitleReferences(
            authToken,
            campaignId,
            parcels,
            propertyId?.toString(),
            propertyAddress?.state
          )
        );
      });
    }
  }, []);

  return null;
};

export default LegalDescriptionPreloader;
