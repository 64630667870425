import { useMemo } from 'react';
import {
  AgxColumn,
  AgxRow,
  AgxBodyText,
  BlankContractProgressModel,
  AgxIconCircularGreenTick,
  AgxProgressIndicator,
} from '@urbanx/agx-ui-components';
import './BlankContractProgress.scss';

interface BlankContractProgressProps {
  blankContractProgress: BlankContractProgressModel;
}

const renderProgress = (text: string, isComplete: boolean) => (
  <AgxRow fill extraClasses="blankContractProgress">
    <AgxBodyText small extraClasses="blankContractProgressText">
      {text}
    </AgxBodyText>
    <AgxBodyText
      small
      extraClasses={`blankContractProgressValue ${isComplete ? 'complete' : 'pending'}`}
    >
      {isComplete ? 'complete' : 'pending'}
    </AgxBodyText>
  </AgxRow>
);

const BlankContractProgress = ({
  blankContractProgress,
}: BlankContractProgressProps) => {
  const completedSteps = useMemo(
    () =>
      [
        blankContractProgress.titleSearchComplete,
        blankContractProgress.poolSafetyComplete &&
          blankContractProgress.showPoolSafetyProgress,
        blankContractProgress.tenancyInformationComplete &&
          blankContractProgress.showTenancyInformationProgress,
        blankContractProgress.bodyCorporateComplete &&
          blankContractProgress.showBodyCorporateProgress,
        blankContractProgress.ownerBuilderComplete &&
          blankContractProgress.showOwnerBuilderProgress,
        blankContractProgress.finalisingContract,
      ].filter(step => step).length,
    [blankContractProgress]
  );
  const totalSteps = useMemo(
    () =>
      [
        1, // Title Search
        blankContractProgress.showPoolSafetyProgress,
        blankContractProgress.showTenancyInformationProgress,
        blankContractProgress.showBodyCorporateProgress,
        blankContractProgress.showOwnerBuilderProgress,
        1, // Finalising Contract
      ].filter(step => step).length,
    [blankContractProgress]
  );

  return (
    <AgxColumn centered extraClasses="blankContractPreparation">
      {blankContractProgress.finalisingContract ? (
        <>
          <AgxIconCircularGreenTick width={44} height={45} />
          <AgxBodyText medium centered>
            Blank Contract is ready
          </AgxBodyText>
        </>
      ) : (
        <>
          <AgxProgressIndicator
            currentSteps={completedSteps}
            totalSteps={totalSteps}
            width={60}
            height={60}
          />
          <AgxBodyText medium centered>
            Blank Contract preparation in progress
          </AgxBodyText>
        </>
      )}
      {!blankContractProgress.finalisingContract && (
        <AgxColumn fill extraClasses="blankContractPreparationBody">
          {renderProgress(
            'Title Search',
            blankContractProgress.titleSearchComplete
          )}
          {blankContractProgress.showPoolSafetyProgress &&
            renderProgress(
              'Pool Safety',
              blankContractProgress.poolSafetyComplete
            )}
          {blankContractProgress.showTenancyInformationProgress &&
            renderProgress(
              'Tenancy Information',
              blankContractProgress.tenancyInformationComplete
            )}
          {blankContractProgress.showBodyCorporateProgress &&
            renderProgress(
              'Body Corporate Disclosure Statement',
              blankContractProgress.bodyCorporateComplete
            )}
          {blankContractProgress.showOwnerBuilderProgress &&
            renderProgress(
              'Owner Builder Declaration',
              blankContractProgress.ownerBuilderComplete
            )}
          {renderProgress(
            'Finalising Contract',
            blankContractProgress.finalisingContract
          )}
        </AgxColumn>
      )}
    </AgxColumn>
  );
};

export default BlankContractProgress;
