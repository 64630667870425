import React from 'react';
import ContentLoader from 'react-content-loader';

export const CampaignsListLoader = () => {
  const sectionHeight = 92;

  let sections = [];

  for (var i = 0; i < 7; i++) {
    const currentSectionHeightStart = i * sectionHeight;

    sections.push(
      <rect
        key={`${i}-0`}
        x="12"
        y={currentSectionHeightStart + 12}
        rx="20"
        ry="20"
        width="90"
        height="68"
      />
    );
    sections.push(
      <rect
        key={`${i}-1`}
        x="114"
        y={currentSectionHeightStart + 12 + 9}
        rx="5"
        ry="5"
        width="120"
        height="10"
      />
    );
    sections.push(
      <rect
        key={`${i}-2`}
        x="359"
        y={currentSectionHeightStart + 12 + 9}
        rx="5"
        ry="5"
        width="36"
        height="10"
      />
    );
    sections.push(
      <rect
        key={`${i}-3`}
        x="114"
        y={currentSectionHeightStart + 12 + 9 + 20}
        rx="5"
        ry="5"
        width="180"
        height="10"
      />
    );
    sections.push(
      <rect
        key={`${i}-4`}
        x="114"
        y={currentSectionHeightStart + 12 + 9 + 40}
        rx="5"
        ry="5"
        width="180"
        height="10"
      />
    );
  }

  return <ContentLoader viewBox="0 0 407 644">{sections}</ContentLoader>;
};
