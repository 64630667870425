import { useAppSelector } from 'hooks/useAppSelector';
import { MarketingPackage, MarketingRow } from '../MarketingPackages/types';

const calculateItemsTotal = (items: MarketingRow[]) =>
  items
    ?.map(item => Number(item?.pricePerUnit) || 0)
    .reduce((total, price) => total + (isNaN(price) ? 0 : price), 0) || 0;

export const useMarketingPackageTotal = () => {
  const marketingPackage = useAppSelector<MarketingPackage>(
    state => state.form?.formValues?.MarketingPackage
  );

  if (
    (marketingPackage?.packageInclusions == null ||
      marketingPackage.packageInclusions.length === 0) &&
    (marketingPackage?.packageAddons == null ||
      marketingPackage.packageAddons.length === 0)
  )
    return null;

  const addons = calculateItemsTotal(marketingPackage.packageAddons);
  const inclusions = calculateItemsTotal(marketingPackage.packageInclusions);

  return inclusions + addons;
};
