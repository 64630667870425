import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { AgxColumn, AgxDivider, AgxSelect } from '@urbanx/agx-ui-components';
import { LoadingState } from '../../../utils/loadingState';
import { formatCurrencyAsNumber } from '../../../utils/formatNumber';
import { ExternalIntegrations } from 'Api/Agencies/Types/Agencies';
import { MarketingItemType } from 'Api/Agencies/Types/Marketing';
import MarketingPackageList from './MarketingPackageList';
import './MarketingPackages.scss';

const realEstateProductName = 'realestate.com.au';
const domainProductName = 'domain.com.au';

const MarketingPackages = ({ id, onValueChanged, defaultValue, validate }) => {
  const { loadingState: realEstateLoadingState, contract: realEstateContract } =
    useSelector(state => state.realEstate);
  const { loadingState: domainLoadingState, contract: domainContract } =
    useSelector(state => state.domain);
  const {
    loadingState: marketingPackagesLoadingState,
    packages: marketingPackages,
  } = useSelector(state => state.agencies.marketingPackages);
  const { loadingState: marketingItemsLoadingState, items: marketingItems } =
    useSelector(state => state.agencies.marketingItems);

  const externalIntegrations = useSelector(
    state => state.agencies.agencyInfo.info.externalIntegrations
  );

  const realEstateIntegrationEnabled = useMemo(() => {
    return externalIntegrations.some(
      i => i === ExternalIntegrations.RealEstateDotCom
    );
  }, [externalIntegrations]);

  const domainIntegrationEnabled = useMemo(() => {
    return externalIntegrations.some(
      i => i === ExternalIntegrations.DomainDotCom
    );
  }, [externalIntegrations]);

  const [selectedPackage, setSelectedPackage] = useState(
    defaultValue ?? {
      packageId: 'NoMarketing',
      packageName: 'No Marketing',
      packageInclusions: [],
      packageAddons: [],
    }
  );

  const isDropDownEnabled = useMemo(() => {
    return (
      marketingPackagesLoadingState === LoadingState.Loaded &&
      marketingItemsLoadingState === LoadingState.Loaded &&
      (!realEstateIntegrationEnabled ||
        (realEstateLoadingState !== LoadingState.Loading &&
          realEstateLoadingState !== LoadingState.NotLoaded)) &&
      (!domainIntegrationEnabled ||
        (domainLoadingState !== LoadingState.Loading &&
          domainLoadingState !== LoadingState.NotLoaded)) &&
      selectedPackage != null
    );
  }, [
    marketingPackagesLoadingState,
    marketingItemsLoadingState,
    realEstateIntegrationEnabled,
    realEstateLoadingState,
    domainIntegrationEnabled,
    domainLoadingState,
    selectedPackage,
  ]);

  const [availablePackageInclusions, setAvailablePackageInclusions] = useState(
    []
  );
  const [availablePackageAddons, setAvailablePackageAddons] = useState([]);
  const [customPackageItems, setCustomPackageItems] = useState([]);
  const [showSelectDomain, setShowSelectDomain] = useState(false);
  const [showSelectRealEstate, setShowSelectRealEstate] = useState(false);

  useEffect(() => {
    if (customPackageItems?.length === 0 && marketingItems?.length > 0) {
      const customPackageAddOns = selectedPackage?.packageAddons
        ?.map(packageAddon => {
          const marketingItem = marketingItems.find(
            item => packageAddon.id === item.id
          );
          if (!marketingItem) return packageAddon;
          return null;
        })
        .filter(item => item);
      const customPackageInclusions = selectedPackage?.packageInclusions
        ?.map(packageInclusion => {
          const marketingItem = marketingItems.find(
            item => packageInclusion.id === item.id
          );
          if (!marketingItem) return packageInclusion;
          return null;
        })
        .filter(item => item);

      const allCustomPackageInclusions =
        customPackageInclusions?.map(item => ({
          ...item,
          itemType: MarketingItemType.Custom,
        })) || [];
      const allCustomPackageAddons =
        customPackageAddOns?.map(item => ({
          ...item,
          itemType: MarketingItemType.Custom,
        })) || [];

      setCustomPackageItems([
        ...allCustomPackageInclusions,
        ...allCustomPackageAddons,
      ]);
    }
  }, [marketingItems]);

  useEffect(() => {
    onValueChanged({
      id,
      value: selectedPackage,
    });
  }, [selectedPackage]);

  const addCustomMarketingItemRow = isInclusion => {
    const newCustomItem = {
      id: `${isInclusion ? 'inclusion-' : 'addon-'}${uuidv4()}`,
      productName: '',
      pricePerUnit: '',
      itemType: MarketingItemType.Custom,
      value: true,
    };

    const updatedPackage = {
      ...selectedPackage,
      packageInclusions: isInclusion
        ? [...selectedPackage.packageInclusions, newCustomItem]
        : selectedPackage.packageInclusions,
      packageAddons: isInclusion
        ? selectedPackage.packageAddons
        : [...selectedPackage.packageAddons, newCustomItem],
    };

    setSelectedPackage(updatedPackage);
    setCustomPackageItems([...customPackageItems, { ...newCustomItem }]);
  };

  const deleteCustomMarketingItemRow = item => {
    const isInclusion = item.id.includes('inclusion-') ? true : false;

    let allCustomPackageItems = [...customPackageItems];
    const index = allCustomPackageItems.findIndex(
      packageItm => packageItm.id === item.id
    );
    allCustomPackageItems.splice(index, 1);

    const updatedPackage = {
      ...selectedPackage,
      packageAddons: isInclusion
        ? selectedPackage.packageAddons
        : selectedPackage.packageAddons.filter(addOn => addOn.id !== item.id),
      packageInclusions: isInclusion
        ? selectedPackage.packageInclusions.filter(inc => inc.id !== item.id)
        : selectedPackage.packageInclusions,
    };

    setSelectedPackage(updatedPackage);
    setCustomPackageItems(allCustomPackageItems);
  };

  useEffect(() => {
    const customPackageItemsInclusions = customPackageItems.filter(
      customPackageItem => customPackageItem?.id?.includes('inclusion-')
    );
    const customPackageItemsAddons = customPackageItems.filter(
      customPackageItem => customPackageItem?.id?.includes('addon-')
    );

    if (selectedPackage?.packageId === 'CustomMarketing') {
      // Custom marketing we want to display all items as inclusion with no items checked
      setAvailablePackageInclusions([
        ...marketingItems,
        ...customPackageItemsInclusions,
      ]);
      setAvailablePackageAddons([...customPackageItemsAddons]);
      setShowSelectRealEstate(
        realEstateIntegrationEnabled &&
          realEstateLoadingState !== LoadingState.NotLoaded
      );
      setShowSelectDomain(
        domainIntegrationEnabled &&
          domainLoadingState !== LoadingState.NotLoaded
      );
    } else if (selectedPackage?.packageId === 'NoMarketing') {
      // No marketing we want to display no items and clear anything checked
      setAvailablePackageInclusions([]);
      setAvailablePackageAddons([]);
      setShowSelectRealEstate(false);
      setShowSelectDomain(false);
    } else {
      // Marketing packages we only want to display the inclusions and addons assigned to the marketing package
      const marketingPackage = marketingPackages.find(
        mp => mp.id === selectedPackage?.packageId
      );

      if (marketingPackage == null) return;

      const hasRealEstateListing =
        realEstateIntegrationEnabled &&
        marketingPackage?.includeRealEstateListing &&
        realEstateLoadingState !== LoadingState.NotLoaded;
      const hasDomainListing =
        domainIntegrationEnabled &&
        marketingPackage?.includeDomainListing &&
        domainLoadingState !== LoadingState.NotLoaded;

      const marketingPackageItems = marketingPackage?.items
        .map(id => marketingItems.find(item => item.id === id))
        .filter(item => item !== undefined);
      const marketingPackageAddons = marketingPackage?.addOns
        .map(id => marketingItems.find(item => item.id === id))
        .filter(item => item !== undefined);

      setShowSelectRealEstate(hasRealEstateListing);
      setShowSelectDomain(hasDomainListing);
      setAvailablePackageInclusions([
        ...marketingPackageItems,
        ...customPackageItemsInclusions,
      ]);
      setAvailablePackageAddons([
        ...marketingPackageAddons,
        ...customPackageItemsAddons,
      ]);
    }
  }, [selectedPackage, customPackageItems]);

  const getDropDownOptions = () => {
    const result = [
      ...marketingPackages?.map(
        ({
          items,
          name,
          id,
          includeDomainListing,
          includeRealEstateListing,
        }) => {
          const labelSubtotal =
            items?.reduce(
              (acc, itemName) =>
                acc +
                (marketingItems?.find(i => i.id === itemName)?.pricePerUnit ??
                  0),
              0
            ) +
            (includeRealEstateListing && realEstateIntegrationEnabled
              ? (realEstateContract?.price ?? 0)
              : 0) +
            (includeDomainListing && domainIntegrationEnabled
              ? (domainContract?.price ?? 0)
              : 0);

          return {
            id: id,
            value: id,
            label: `${name} - $${formatCurrencyAsNumber(labelSubtotal)}`,
          };
        }
      ),
      {
        id: 'CustomMarketing',
        value: 'CustomMarketing',
        label: 'Custom Marketing',
      },
      {
        id: 'NoMarketing',
        value: 'NoMarketing',
        label: 'No Marketing',
      },
    ];

    return result;
  };

  const onMarketingPackageChanged = ({ value: packageId }) => {
    if (
      packageId == null ||
      selectedPackage?.packageId === packageId ||
      !isDropDownEnabled
    )
      return;

    if (packageId === 'CustomMarketing') {
      // Custom marketing we want to display all items as inclusion with no items checked
      const updatedSelectedPackage = {
        packageId,
        packageName: 'Custom Marketing',
        packageInclusions: [],
        packageAddons: [],
      };

      setSelectedPackage(updatedSelectedPackage);
    } else if (packageId === 'NoMarketing') {
      // No marketing we want to display no items and clear anything checked
      const updatedSelectedPackage = {
        packageId,
        packageName: 'No Marketing',
        packageInclusions: [],
        packageAddons: [],
      };

      setSelectedPackage(updatedSelectedPackage);
    } else {
      // Marketing packages we only want to display the inclusions and addons assigned to the marketing package
      const marketingPackage = marketingPackages.find(
        mp => mp.id === packageId
      );

      if (marketingPackage == null) return;

      const hasRealEstateListing =
        marketingPackage?.includeRealEstateListing &&
        realEstateIntegrationEnabled;
      const hasDomainListing =
        marketingPackage?.includeDomainListing && domainIntegrationEnabled;
      const marketingPackageItems = marketingItems.filter(mi =>
        marketingPackage?.items.find(i => i === mi.id)
      );

      const updatedSelectedPackage = {
        packageId,
        packageName: marketingPackage.name,
        packageInclusions: [
          ...(hasRealEstateListing
            ? [
                {
                  id: realEstateProductName,
                  productName: realEstateProductName,
                  pricePerUnit: realEstateContract?.price,
                },
              ]
            : []),
          ...(hasDomainListing
            ? [
                {
                  id: domainProductName,
                  productName: domainProductName,
                  pricePerUnit: domainContract?.price,
                },
              ]
            : []),
          ...marketingPackageItems.map(item => ({
            id: item.id,
            productName: item.productName,
            pricePerUnit: item.pricePerUnit,
          })),
        ],
        packageAddons: [],
      };

      setSelectedPackage(updatedSelectedPackage);
    }
  };

  useEffect(() => {
    // If the domain or realestate price has changed, then update the price in the selected package
    if (
      selectedPackage?.packageInclusions?.some(
        inc =>
          (inc.productName === realEstateProductName &&
            inc.pricePerUnit !== realEstateContract?.price) ||
          (inc.productName === domainProductName &&
            inc.pricePerUnit !== domainContract?.price)
      )
    ) {
      setSelectedPackage({
        ...selectedPackage,
        packageInclusions: selectedPackage?.packageInclusions?.map(inc => {
          if (inc.productName === realEstateProductName) {
            return {
              ...inc,
              pricePerUnit: realEstateContract?.price,
            };
          } else if (inc.productName === domainProductName) {
            return {
              ...inc,
              pricePerUnit: domainContract?.price,
            };
          } else {
            return inc;
          }
        }),
      });
    }
  }, [realEstateContract?.price, domainContract?.price]);

  const onSelectProductsChange = updatedSelectedPackage => {
    setSelectedPackage(updatedSelectedPackage);
    onValueChanged({
      id,
      value: updatedSelectedPackage,
    });
  };

  const getDefaultValue = () => {
    if (selectedPackage == null) {
      const options = getDropDownOptions();
      return options ? options[options.length - 1] : null;
    }

    return {
      id: selectedPackage?.packageId,
      value: selectedPackage?.packageId,
      label: selectedPackage?.packageName,
    };
  };

  const loadingMarketingPackagesOptions = [
    {
      id: 'Loading Marketing Packages...',
      value: 'Loading Marketing Packages...',
      label: 'Loading Marketing Packages...',
    },
  ];

  return (
    <AgxColumn fill>
      <AgxColumn extraClasses="marketingPackageSelector">
        <AgxSelect
          key={`${id}-${isDropDownEnabled}`}
          id={id}
          label="Marketing Package"
          required
          options={
            isDropDownEnabled
              ? getDropDownOptions()
              : loadingMarketingPackagesOptions
          }
          defaultValue={
            isDropDownEnabled
              ? getDefaultValue()
              : 'Loading Marketing Packages...'
          }
          hideOptionalLabel={true}
          onValueChanged={onMarketingPackageChanged}
          dataTestId={`agx-${id}`}
        />
      </AgxColumn>
      {isDropDownEnabled &&
        selectedPackage?.packageId &&
        selectedPackage?.packageId !== 'NoMarketing' && <AgxDivider expanded />}
      {isDropDownEnabled &&
        selectedPackage?.packageId &&
        selectedPackage?.packageId !== 'NoMarketing' && (
          <MarketingPackageList
            key={`marketingPackageList-${selectedPackage?.packageId}-${isDropDownEnabled}`}
            selectedPackage={selectedPackage}
            showSelectRealEstate={showSelectRealEstate}
            showSelectDomain={showSelectDomain}
            availablePackageInclusions={availablePackageInclusions}
            availablePackageAddons={availablePackageAddons}
            realEstateProductName={realEstateProductName}
            domainProductName={domainProductName}
            onSelectProductsChange={onSelectProductsChange}
            addCustomMarketingItemRow={addCustomMarketingItemRow}
            deleteCustomMarketingItemRow={deleteCustomMarketingItemRow}
            validate={validate}
          />
        )}
    </AgxColumn>
  );
};

export default MarketingPackages;
