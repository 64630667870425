import { NoticeTimeUnit, Option } from '@urbanx/agx-ui-components';

export enum SettlementConditionType {
  AsPerContract = 'AsPerContract',
  NdaysFromDate = 'NdaysFromDate',
  OnSpecificDate = 'OnSpecificDate',
  UponSatisfactionOfSpecialCondition = 'UponSatisfactionOfSpecialCondition',
  Other = 'Other',
}

export enum SettlementInLineWithType {
  BuyersProperty = "settlement of the Buyer's property",
  VendorsPurchase = "settlement of the Vendor's purchase",
  TenancyAgreement = 'end date of existing Tenancy Agreement',
}

export const SettlementInLineWithOptions = [
  {
    label: SettlementInLineWithType.BuyersProperty,
    value: SettlementInLineWithType.BuyersProperty,
  },
  {
    label: SettlementInLineWithType.VendorsPurchase,
    value: SettlementInLineWithType.VendorsPurchase,
  },
  {
    label: SettlementInLineWithType.TenancyAgreement,
    value: SettlementInLineWithType.TenancyAgreement,
  },
];

export const TimeOptions: Option[] = [
  {
    value: NoticeTimeUnit.Days.toString(),
    label: 'days',
  },
  {
    value: NoticeTimeUnit.Weeks.toString(),
    label: 'weeks',
  },
];
