export interface HasAccessResponse {
  hasAccess: boolean;
  authorizeUrl: URL;
}

export interface StoreAccessCodeResponse {
  result: StoreAccessCodeResult;
}

export enum StoreAccessCodeResult {
  Success = 0,
  InvalidAccount = 1,
  Error = 2,
}
