const toRadians = (degrees: number): number => {
  return degrees * (Math.PI / 180);
};

export const haversineDistance = (
  lat1: number | null,
  lng1: number | null,
  lat2: number | null,
  lng2: number | null
): number | null => {
  if (
    !lat1 ||
    Number.isNaN(Number(lat1)) ||
    !lng1 ||
    Number.isNaN(Number(lng1)) ||
    !lat2 ||
    Number.isNaN(Number(lat2)) ||
    !lng2 ||
    Number.isNaN(Number(lng2))
  )
    return null;

  const R = 6371; // Earth's radius in km
  const dLat = toRadians(lat2 - lat1);
  const dLng = toRadians(lng2 - lng1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
};
