import { useCallback, useEffect, useMemo } from 'react';
import { Option } from '@urbanx/agx-ui-components';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAzureAuth } from '../../hooks/useAzureAuth';
import { rexApi } from 'Api/Rex/rexApi';
import { EntityGoalsModel } from 'Api/Agencies/Types/Agencies';
import { setAndShowErrorToast } from '../../store/config';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { LoadingState } from '../../utils/loadingState';
import {
  GetAgentStatisticsResponse,
  TimePeriodType,
} from '../../types/Reporting';

export interface ReportingDashboardState {
  stats: GetAgentStatisticsResponse | null;
  loadingState: LoadingState;
  goals: EntityGoalsModel | null;
  goalsLoadingState: LoadingState;
}

const initialState: ReportingDashboardState = {
  stats: null,
  loadingState: LoadingState.NotLoaded,
  goals: null,
  goalsLoadingState: LoadingState.NotLoaded,
};

const slice = createSlice({
  name: 'reportingDashboard',
  initialState,
  reducers: {
    agentStatisticsStartedLoading: (state: ReportingDashboardState) => {
      return {
        ...state,
        stats: initialState.stats,
        loadingState: LoadingState.Loading,
      };
    },
    agentStatisticsLoaded: (
      state: ReportingDashboardState,
      action: PayloadAction<GetAgentStatisticsResponse | null>
    ) => {
      const stats = action.payload;
      return {
        ...state,
        stats,
        loadingState: LoadingState.Loaded,
      };
    },
    goalsStartedLoading: (state: ReportingDashboardState) => {
      return {
        ...state,
        goals: initialState.goals,
        goalsLoadingState: LoadingState.Loading,
      };
    },
    goalsLoaded: (
      state: ReportingDashboardState,
      action: PayloadAction<EntityGoalsModel | null>
    ) => {
      const goals = action.payload;
      return {
        ...state,
        goals,
        goalsLoadingState: LoadingState.Loaded,
      };
    },
  },
});

export default slice.reducer;

export const {
  agentStatisticsStartedLoading,
  agentStatisticsLoaded,
  goalsLoaded,
  goalsStartedLoading,
} = slice.actions;

export const TimePeriods: Option[] = [
  { value: TimePeriodType.Month, label: TimePeriodType.Month },
  { value: TimePeriodType.Quarter, label: TimePeriodType.Quarter },
  { value: TimePeriodType.Annual, label: TimePeriodType.Annual },
];

export const IndividualOrTeamEntityType = 'Individual/Team';
export const AgencyEntityType = 'Agency';
export const PartnershipEntityType = 'Partnership';

export const EntityTypes: Option[] = [
  { value: '0', label: IndividualOrTeamEntityType },
  { value: '1', label: AgencyEntityType },
  { value: '2', label: PartnershipEntityType },
];

export const PartnershipYearOptions: Option[] = [
  {
    value: 'This Year',
    label: 'This Year',
  },
  {
    value: 'Last Year',
    label: 'Last Year',
  },
];

export const useLoadAgentStatistics = (
  entityType: string
): [boolean, boolean] => {
  const dispatch = useAppDispatch();
  const [userAccount, getAuthToken] = useAzureAuth();

  const reportingDashboardState: ReportingDashboardState = useAppSelector(
    state => state.reportingDashboard
  );
  const { loadingState, stats } = reportingDashboardState;

  const loadAgentStatistics = useCallback(async () => {
    if (!getAuthToken) return;

    try {
      dispatch(agentStatisticsStartedLoading());

      const token = await getAuthToken();

      const agentStatisticsResponse = await rexApi(
        token || ''
      ).get<GetAgentStatisticsResponse>('GetAgentStatistics');

      const statistics = agentStatisticsResponse.data;

      dispatch(
        agentStatisticsLoaded({
          ...statistics,
        })
      );
    } catch (err: any) {
      console.error(err);
      // @ts-ignore
      dispatch(setAndShowErrorToast(err.message));
      dispatch(agentStatisticsLoaded(null));
      return err.message;
    }
  }, [dispatch, getAuthToken]);

  useEffect(() => {
    if (!userAccount) return;

    if (loadingState === LoadingState.NotLoaded) {
      loadAgentStatistics();
    }
  }, [loadingState, userAccount]);

  const loading = loadingState === LoadingState.Loading;

  const errorRetrievingStatistics = useMemo(() => {
    if (loadingState === LoadingState.Failed) return true;

    if (loadingState === LoadingState.Loaded) {
      if (entityType !== EntityTypes[0].value) return false;

      return (
        stats?.agentStatistics?.error !== null &&
        stats?.agentStatistics?.error !== undefined
      );
    }

    return false;
  }, [loadingState, stats, entityType]);

  return [loading, errorRetrievingStatistics];
};
