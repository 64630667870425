import { useMemo } from 'react';
import './SidebarLeaderboard.scss';
import {
  AgxBodyText,
  AgxColumn,
  AgxDivider,
  AgxHeader,
} from '@urbanx/agx-ui-components';
import { ReactComponent as NoDataImg } from 'assets/images/Curiosity.svg';
import { TopPerformersStatsModel } from 'types/Reporting';
import SidebarLeaderboardRowLoader from './SidebarLeaderboardRowLoader';
import SidebarLeaderboardRow from './SidebarLeaderboardRow';

interface SidebarLeaderboardProps {
  loading: boolean;
  leaderboardStats?: TopPerformersStatsModel | null;
  boardName: string;
}

const SidebarLeaderboard = ({
  loading,
  leaderboardStats,
  boardName,
}: SidebarLeaderboardProps) => {
  const getMonthName = () =>
    new Date().toLocaleString('default', { month: 'long' });

  const panelHeaderText = useMemo(() => {
    return `Top ${boardName} - ${getMonthName()}`;
  }, [boardName]);

  const noData = !loading && !leaderboardStats;

  const leaderboardPositions = useMemo(() => {
    if (!leaderboardStats) return [];

    let positions = [...leaderboardStats.leaderBoardModels];
    const personalPosition = leaderboardStats.personalPosition;

    if (personalPosition && personalPosition.rank > 4) {
      positions = positions.slice(0, 4);
      positions.push(personalPosition);
    }

    return positions;
  }, [leaderboardStats]);
  return (
    <AgxColumn extraClasses="reportSidebarLeaderboard">
      <AgxHeader size={5} extraClasses="header-text">
        {!loading && panelHeaderText}
      </AgxHeader>
      <div className="sidebarLeaderboardGrid">
        {noData && (
          <>
            <AgxDivider />
            <AgxColumn fill centered extraLargeGap extraClasses="noDataDiv">
              <NoDataImg />
              <AgxColumn>
                <AgxHeader size={3} centered>
                  No sales have been recorded yet
                </AgxHeader>
                <AgxBodyText medium centered>
                  Check back in a bit!
                </AgxBodyText>
              </AgxColumn>
            </AgxColumn>
          </>
        )}
        {loading &&
          [...Array(5)].map((i, idx) => (
            <SidebarLeaderboardRowLoader key={idx} />
          ))}
        {!loading &&
          leaderboardPositions.map((leaderboardPosition, idx) => (
            <SidebarLeaderboardRow
              key={`row-${idx}`}
              leaderboardPosition={leaderboardPosition}
              addRingToImage={true}
            />
          ))}
      </div>
    </AgxColumn>
  );
};

export default SidebarLeaderboard;
