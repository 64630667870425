import { AgxSelect, PropertyTypeOptions } from '@urbanx/agx-ui-components';
import { useEffect, useState } from 'react';
const getInitialValue = (
  defaultValue?: string
): { label: string; value: string } => {
  return (
    PropertyTypeOptions.find(option => option.value === defaultValue) ??
    PropertyTypeOptions[0]
  );
};

interface PropertyTypeProps {
  id: string;
  onValueChanged: (value: { id: string; value: string }) => void;
  defaultValue?: string;
  label: string;
  caption?: string;
  required?: boolean;
}

const PropertyType = ({
  id,
  onValueChanged,
  defaultValue,
  label,
  caption,
  required,
}: PropertyTypeProps) => {
  const [propertyType, setPropertyType] = useState(
    getInitialValue(defaultValue)?.value
  );

  useEffect(() => {
    onValueChanged({ id, value: propertyType });
  }, [propertyType]);

  return (
    <AgxSelect
      id={id}
      dataTestId={id}
      options={PropertyTypeOptions}
      onValueChanged={({ value }) => setPropertyType(value)}
      defaultValue={getInitialValue(propertyType)}
      required={required}
      label={label}
      caption={caption}
    />
  );
};

export default PropertyType;
