import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AgxBodyText,
  AgxButton,
  AgxCaption,
  AgxControlledCheckbox,
  AgxRow,
} from '@urbanx/agx-ui-components';
import { LoadingState } from 'utils/loadingState';
import { reloadRealEstate } from '../../../realEstate/realEstateReducer';
import { formatCurrencyAsNumber } from 'utils/formatNumber';

const RealEstateItem = ({
  id,
  productName,
  selected,
  onSelectProductChange,
}) => {
  const dispatch = useDispatch();
  const { loadingState: realEstateLoadingState, contract } = useSelector(
    state => state.realEstate
  );

  const [productSelected, setProductSelected] = useState(selected);

  useEffect(() => {
    setProductSelected(selected === true);
  }, [selected]);

  useEffect(() => {
    onSelectProductChange({
      id,
      productName,
      pricePerUnit: contract?.price,
      value: productSelected,
    });
  }, [productSelected]);

  const onValueChanged = value => {
    setProductSelected(value === true);
  };

  let realEstateItemBody;

  if (realEstateLoadingState === LoadingState.Failed) {
    realEstateItemBody = (
      <AgxCaption>
        <AgxButton
          small
          naked
          onClick={() => {
            dispatch(reloadRealEstate());
          }}
          text={`Failed to retrieve ${productName} pricing. Click here to try again`}
        ></AgxButton>
      </AgxCaption>
    );
  } else if (realEstateLoadingState === LoadingState.Loading) {
    realEstateItemBody = (
      <AgxBodyText small>Loading {productName} pricing</AgxBodyText>
    );
  } else {
    realEstateItemBody = (
      <AgxBodyText small>
        {contract != null
          ? `$${formatCurrencyAsNumber(contract.price, 2)}`
          : 'Complimentary'}
      </AgxBodyText>
    );
  }

  return (
    <AgxRow
      fill
      spaceBetween
      centered
      mediumGap
      extraClasses="marketingPackageItem"
    >
      <AgxControlledCheckbox
        naked
        label={productName}
        checked={productSelected}
        disabled={realEstateLoadingState === LoadingState.Failed}
        onValueChanged={onValueChanged}
      />
      {realEstateItemBody}
    </AgxRow>
  );
};

export default RealEstateItem;
