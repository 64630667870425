import {
  UploadedDocument,
  DocumentTypesOrderMap,
} from '@urbanx/agx-ui-components';

export const sortDocumentsByType = (documents: UploadedDocument[] = []) => {
  return [...(documents ?? [])].sort((a, b) => {
    return (
      DocumentTypesOrderMap[a.documentType] -
      DocumentTypesOrderMap[b.documentType]
    );
  });
};
