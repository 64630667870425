import React, { useEffect, useState } from 'react';
import {
  AgxBodyText,
  AgxDatePicker,
  AgxLabel,
  AgxRadio,
  AgxRow,
  AgxTextInput,
  Images,
} from '@urbanx/agx-ui-components';

const FinanceDateType = {
  NotSubjectToFinance: 'NotSubjectToFinance',
  NdaysFromDate: 'NdaysFromDate',
  OnSpecificDate: 'OnSpecificDate',
};

const FinanceCondition = ({
  id,
  onValueChanged,
  defaultValue,
  validate,
  required,
}) => {
  const [financeConditionDetails, setFinanceConditionDetails] = useState({
    condition: defaultValue?.condition,
    daysFromDate: defaultValue?.daysFromDate,
    referenceDate: defaultValue?.referenceDate,
  });

  useEffect(() => {
    onValueChanged({
      id,
      value: financeConditionDetails,
    });
  }, [financeConditionDetails]);

  const { daysFromDate, referenceDate, condition } =
    financeConditionDetails || {};

  return (
    <div className="agxRadio">
      <AgxLabel medium>Finance</AgxLabel>
      <div className="agxRadio-group">
        <div className="agxRadio-group">
          <AgxRadio
            id="notSubjectToFinance"
            label="Not subject to finance"
            onCheckChanged={() =>
              setFinanceConditionDetails({
                ...financeConditionDetails,
                condition: FinanceDateType.NotSubjectToFinance,
              })
            }
            checked={condition === FinanceDateType.NotSubjectToFinance}
          />
          <AgxRadio
            id="financeDateDaysFromContractDate"
            label="__day(s) from Contract Date"
            onCheckChanged={() =>
              setFinanceConditionDetails({
                ...financeConditionDetails,
                condition: FinanceDateType.NdaysFromDate,
              })
            }
            checked={condition === FinanceDateType.NdaysFromDate}
            subContent={
              condition === FinanceDateType.NdaysFromDate && [
                <AgxRow centered mediumGap key="numberOfDays">
                  <AgxTextInput
                    id="numberOfDays"
                    noHeader
                    numberOnly
                    noOptionalLabel
                    maxLength="2"
                    defaultValue={daysFromDate}
                    onInputValueChange={({ value }) =>
                      setFinanceConditionDetails({
                        ...financeConditionDetails,
                        daysFromDate: value,
                      })
                    }
                    rightIcon={<AgxLabel small>day(s)</AgxLabel>}
                    small
                    error={
                      validate && financeConditionDetails.daysFromDate === ''
                        ? 'Enter days'
                        : ''
                    }
                  />
                  <AgxLabel small>from Contract Date</AgxLabel>
                </AgxRow>,
              ]
            }
          />
          <AgxRadio
            id="financeDateSpecificDateRadio"
            label="Specific Date"
            onCheckChanged={() =>
              setFinanceConditionDetails({
                ...financeConditionDetails,
                condition: FinanceDateType.OnSpecificDate,
              })
            }
            checked={condition === FinanceDateType.OnSpecificDate}
            subContent={
              condition === FinanceDateType.OnSpecificDate && [
                <AgxDatePicker
                  key="financeDateSpecificDateRadioOnSpecificDate"
                  id="financeDateSpecificDateRadioOnSpecificDate"
                  onValueChanged={({ value }) =>
                    setFinanceConditionDetails({
                      ...financeConditionDetails,
                      referenceDate: value,
                    })
                  }
                  required
                  noHeader
                  noOptionalLabel
                  defaultValue={referenceDate}
                  date
                  stretch
                  error={
                    validate && referenceDate === ''
                      ? 'Enter a date'
                      : undefined
                  }
                />,
              ]
            }
          />
        </div>
      </div>
      {validate && required && !financeConditionDetails.condition && (
        <div className={`errorMessage`}>
          <Images.AlertCircle />
          <AgxBodyText small extraClasses={'error'}>
            {'Select an option'}
          </AgxBodyText>
        </div>
      )}
    </div>
  );
};

export default FinanceCondition;
