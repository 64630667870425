import { AgxColumn, AgxRow } from '@urbanx/agx-ui-components';
import Card from '../Card/Card';
import ContentLoader from 'react-content-loader';
import './StatCard.scss';
import StatCardTitleSection from './StatCardTitleSection';
import GoalInformation from './GoalInformation';

export interface StatCardData {
  title: string;
  value: number | null | undefined;
  goal: number | null | undefined;
  isCurrency?: boolean;
  isPercentage?: boolean;
}

interface StatCardProps {
  data?: StatCardData[];
  bold?: boolean;
  noData?: boolean;
  loading?: boolean;
  centerJustified?: boolean;
  subText?: string | null;
}

const getGoalInformation = (
  item: StatCardData
): [boolean, number | null | undefined] => {
  const isAboveTarget = item.goal != null && item.goal >= 0;

  let goal = item.goal;

  if (item.goal !== null && item.goal !== undefined && item.goal < 0) {
    goal = goal! * -1;
  }

  return [isAboveTarget, goal];
};

const StatCard = ({
  data = [],
  bold = false,
  noData = false,
  loading = false,
  centerJustified = true,
  subText = null,
}: StatCardProps) => {
  if (loading) {
    return (
      <Card largeGap>
        {data.map((_data, index) => (
          <ContentLoader key={index} viewBox="0 0 50 10">
            <rect x="0" y="0" rx="2" ry="2" width="50" height="10" />
          </ContentLoader>
        ))}
      </Card>
    );
  }

  if (centerJustified) {
    return (
      <Card largeGap centerJustified>
        {data.map((item, index) => {
          const [isAboveTarget, goal] = getGoalInformation(item);

          return (
            <AgxColumn key={`card-data-${index}`} fill>
              <AgxColumn smallGap>
                <StatCardTitleSection
                  fill
                  bold={bold}
                  item={item}
                  loading={loading}
                  noData={noData}
                  subText={subText}
                />
                {!loading && goal != null && (
                  <GoalInformation
                    item={item}
                    isAboveTarget={isAboveTarget}
                    goal={goal}
                    centerJustified={centerJustified}
                  />
                )}
              </AgxColumn>
              {loading && (
                <ContentLoader viewBox="0 0 50 10" style={{ maxWidth: '50%' }}>
                  <rect x="0" y="0" rx="2" ry="2" width="50" height="10" />
                </ContentLoader>
              )}
            </AgxColumn>
          );
        })}
      </Card>
    );
  }

  return (
    <Card largeGap centerJustified={centerJustified}>
      {data.map((item, index) => {
        const [isAboveTarget, goal] = getGoalInformation(item);

        return (
          <AgxRow
            fill
            spaceBetween
            extraClasses="statRow"
            key={`statRow-${index}`}
          >
            <StatCardTitleSection
              key={`card-data-${index}`}
              fill
              bold={bold}
              item={item}
              loading={loading}
              noData={noData}
              subText={subText}
            />
            {!loading && goal != null && (
              <GoalInformation
                item={item}
                isAboveTarget={isAboveTarget}
                goal={goal}
                centerJustified={centerJustified}
              />
            )}
          </AgxRow>
        );
      })}
    </Card>
  );
};

export default StatCard;
