import React, { useEffect, useState } from 'react';
import { LengthOfAgreement } from './LengthOfAgreement';
import moment from 'moment';
import {
  AgxRadio,
  AgxDatePicker,
  AgxLabel,
  AustralianState,
} from '@urbanx/agx-ui-components';
import Checkbox from '../Checkbox/Checkbox';
import './AgreementDetails.scss';

const AgreementDetails = ({
  id,
  onValueChanged,
  defaultValue,
  validate,
  state,
  days,
  restrictDuration,
}) => {
  const [agreementType, setAgreementType] = useState();

  useEffect(() => {
    const defaultVal = defaultValue?.agreementType ?? exclusive;
    setAgreementType(defaultVal);
  }, []);

  const nswSpecific = state === AustralianState.NSW;

  const defaultStartDate =
    (defaultValue?.startDate && moment(defaultValue.startDate)) ?? moment();

  const getDefaultAgreementLength = (startDate, endDate) => {
    if (startDate == null) return null;

    if (endDate == null) return null;

    const endDateMoment = moment(endDate).format('YYYY-MM-DD');

    if (
      endDateMoment === startDate.clone().add(29, 'days').format('YYYY-MM-DD')
    )
      return '30';
    if (
      endDateMoment === startDate.clone().add(59, 'days').format('YYYY-MM-DD')
    )
      return '60';
    if (
      endDateMoment === startDate.clone().add(89, 'days').format('YYYY-MM-DD')
    )
      return '90';
    if (
      endDateMoment === startDate.clone().add(119, 'days').format('YYYY-MM-DD')
    )
      return '120';
    return 'specificDate';
  };

  const [startDate, setStartDate] = useState(
    defaultStartDate.format('YYYY-MM-DD')
  );

  const [agreementLength, setAgreementLength] = useState(
    getDefaultAgreementLength(defaultStartDate, defaultValue?.endDate)
  );

  const [specificEndDate, setSpecificEndDate] = useState(
    agreementLength === 'specificDate' && defaultValue?.endDate
      ? defaultValue.endDate
      : null
  );

  const [
    continueToOpenListingOnExpiration,
    setContinueToOpenListingOnExpiration,
  ] = useState(defaultValue?.continueToOpenListingOnExpiration ?? true);

  const calculateEndDate = (startDate, lengthOfAgreement, agreementType) => {
    if (startDate == null) return null;
    if (agreementType === 'Open') return null;
    if (lengthOfAgreement === 'specificDate') return specificEndDate;

    var startDateVal = moment(startDate, 'YYYY-MM-DD');

    //dates here are inclusive so for 30 days we add 29 as it includes the start date
    switch (lengthOfAgreement) {
      case '30':
        return startDateVal.add(29, 'days').format('YYYY-MM-DD');
      case '60':
        return startDateVal.add(59, 'days').format('YYYY-MM-DD');
      case '90':
        return startDateVal.add(89, 'days').format('YYYY-MM-DD');
      case '120':
        return startDateVal.add(119, 'days').format('YYYY-MM-DD');
      default:
        return null;
    }
  };

  useEffect(() => {
    var agreementDetails = {
      agreementType,
      startDate: moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      endDate: calculateEndDate(startDate, agreementLength),
      continueToOpenListingOnExpiration,
    };

    onValueChanged({ id, value: agreementDetails });
  }, [
    agreementType,
    startDate,
    agreementLength,
    specificEndDate,
    continueToOpenListingOnExpiration,
  ]);

  const exclusive = 'Exclusive';
  const open = 'Open';
  const sole = 'Sole';

  const startDatePicker = (
    <AgxDatePicker
      key="ListingDetailsAgreementExclusiveStartDate"
      id="ListingDetailsAgreementExclusiveStartDate"
      onValueChanged={({ value }) => setStartDate(value)}
      required
      preFill
      label="Start Date"
      defaultValue={startDate}
      date
      stretch
      error={
        validate && (!startDate || startDate === 'Invalid date')
          ? 'Start Date must be completed'
          : undefined
      }
    />
  );

  const lengthOfAgreement = (
    <LengthOfAgreement
      key="ListingDetailsAgreementLength"
      specificDateChange={setSpecificEndDate}
      onLengthOfAgreementChange={setAgreementLength}
      defaultLengthOfAgreement={agreementLength}
      defaultSpecificDate={specificEndDate}
      days={days}
      validate={validate}
      restrictDuration={restrictDuration}
      startDate={startDate}
    />
  );

  const continueToOpenListingElem = (
    <Checkbox
      key="continueToOpenListingOnExpiration"
      id="continueToOpenListingOnExpiration"
      label="Continue as an open listing upon expiration"
      onChange={({ value }) => setContinueToOpenListingOnExpiration(value)}
      required
      defaultValue={continueToOpenListingOnExpiration}
    />
  );

  return (
    <div className="agxRadio">
      <AgxLabel medium>Agreement Type</AgxLabel>
      <div className="agxRadio-group">
        <AgxRadio
          id={exclusive}
          label={exclusive}
          onCheckChanged={() => setAgreementType(exclusive)}
          checked={agreementType === exclusive}
          subContent={
            agreementType === exclusive && [
              startDatePicker,
              lengthOfAgreement,
              !nswSpecific && continueToOpenListingElem,
            ]
          }
        />
        {!nswSpecific && (
          <>
            <AgxRadio
              id={open}
              label={open}
              onCheckChanged={() => setAgreementType(open)}
              checked={agreementType === open}
              subContent={agreementType === open && [startDatePicker]}
            />
            <AgxRadio
              id={sole}
              label={sole}
              onCheckChanged={() => setAgreementType(sole)}
              checked={agreementType === sole}
              subContent={
                agreementType === sole && [
                  startDatePicker,
                  lengthOfAgreement,
                  continueToOpenListingElem,
                ]
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AgreementDetails;
