import {
  Name,
  StateAgreementFormType,
  UploadedDocument,
  AustralianStateNumericMap,
} from '@urbanx/agx-ui-components';

export interface SaleGoals {
  gciGoal: number;
  averageSalePrice: number;
  averageCommissionRate: number;
  averageConversionRate: number;
}

export interface EntityGoalsModel {
  agentOrTeamGoals: SaleGoals;
  agencyGoals: SaleGoals | null;
}

export interface AgencyAgentModel {
  id: string;
  name: Name | null;
  licenceType: LicenceType;
  softDeleted: boolean;
}

export enum LicenceType {
  None,
  RealEstateCertificate,
  RealEstateLicence,
  Class1,
  Class2,
}

export interface AgencyInfo {
  branding?: BrandingDetailsModel;
  agencyStates?: AustralianStateNumericMap[];
  externalIntegrations?: ExternalIntegrations[];
  agreements: StateAgreementFormType[] | null;
}

export interface BrandingDetailsModel {
  agencyName: string | null;
  licenseeName: string | null;
  tradingName: string | null;
  backgroundColor: string | null;
  backgroundTextColor: string | null;
  accentColor: string | null;
  buttonTextColor: string | null;
  inboxBackgroundColor: string | null;
  inboxBackgroundColorOpacity: string | null;
  sidebarBackgroundColor: string | null;
  sidebarBackgroundColorOpacity: string | null;
  logo: UploadedDocument | null;
}

export enum ExternalIntegrations {
  RPData,
  RealEstateDotCom,
  DomainDotCom,
  DocuSign,
}
