import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AgxButton,
  AgxColumn,
  AgxRadioGroup,
  Popup,
  Option,
  AustralianState,
  useWindowSize,
  Breakpoints,
} from '@urbanx/agx-ui-components';
import { setSelectedForm } from 'features/form/formReducer';
import { useFormClearer } from 'hooks/useFormCleaner';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';

export const NewFormButton = () => {
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clearFormData = useFormClearer();
  const { availableForms } = useAppSelector(state => state.forms);
  const screenSize = useWindowSize();
  const isMobile = screenSize === Breakpoints.Mobile;
  const [selectedAustralianState, setSelectedAustralianState] = useState<
    Option | undefined
  >();

  const {
    info: { agencyStates },
  } = useAppSelector(state => state.agencies.agencyInfo);

  const selectedAgencyStatesOptions = agencyStates
    ? agencyStates.map(
        index =>
          ({
            label: Object.values(AustralianState)[index],
            value: `${index}`,
          }) as Option
      )
    : [];

  const onFormButtonClicked = () => {
    if (selectedAgencyStatesOptions.length > 1) {
      setShowPopup(true);
    } else {
      setSelectedAustralianState(selectedAgencyStatesOptions[0]);
      onStateSelect(selectedAgencyStatesOptions[0]);
    }
  };

  const onStateSelect = (selectedState?: Option) => {
    const australianStateLabel = selectedState
      ? selectedState.label
      : selectedAustralianState?.label;

    const form = availableForms.find(
      af => af.beginsCampaign && af.state === australianStateLabel
    );

    if (form) {
      clearFormData();
      dispatch(setSelectedForm({ formType: form.type, state: form.state }));
      navigate('/form');
    }

    if (!selectedState) setShowPopup(false);
  };

  const actionButtons = [
    {
      title: 'Select',
      isPrimary: true,
      clickHandler: () => onStateSelect(),
    },
    {
      title: 'Cancel',
      clickHandler: () => setShowPopup(false),
    },
  ];

  return (
    <>
      <AgxButton
        text={`New Agreement`}
        onClick={() => onFormButtonClicked()}
        primary
        large
        dataTestId={'agx-btn-newform6'}
      />
      <Popup
        title={'Select the state the property is located in:'}
        isOpen={showPopup}
        onClose={() => setShowPopup(false)}
        actionButtons={actionButtons}
        size={{ widthInPX: isMobile ? 300 : 600 }}
        centered
        actionButtonsCentered={true}
      >
        <AgxColumn>
          <AgxRadioGroup
            id={'statesRadio'}
            label={''}
            options={selectedAgencyStatesOptions}
            onValueChanged={({ value }) =>
              setSelectedAustralianState(
                selectedAgencyStatesOptions.find(
                  option => option.value === value
                )
              )
            }
          />
        </AgxColumn>
      </Popup>
    </>
  );
};
