import { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setSearchOptionsEnabled } from 'store/cmaPropertySearch/cmaPropertySearchReducer';
import { getLatLongForAddress } from 'utils/getLatLong';
import useFormPropertyDetailsSelector from 'selectors/useFormPropertyDetailsSelector';
import useFormAddressSelector from 'selectors/useFormAddressSelector';
import { useAppSelector } from 'hooks/useAppSelector';
import { cleanFullAddress } from 'utils/formatAddress';

const ComparativeMarketAnalysisPreloader = () => {
  const searchNowEnabled = useAppSelector(
    state => state.cmaPropertySearch.searchNowEnabled
  );
  const manualSearchEnabled = useAppSelector(
    state => state.cmaPropertySearch.manualSearchEnabled
  );
  const dispatch = useAppDispatch();

  const propertyDetails = useFormPropertyDetailsSelector();
  const propertyAddress = useFormAddressSelector();

  const loadLatLong = useCallback(async () => {
    if (
      (propertyDetails?.latitude && propertyDetails?.longitude) ||
      propertyDetails?.propertyId
    ) {
      dispatch(
        setSearchOptionsEnabled({
          searchNowEnabled: true,
          manualSearchEnabled: true,
        })
      );
    }

    try {
      const latLong = await getLatLongForAddress(
        cleanFullAddress(propertyAddress)
      );

      if (latLong?.latitude && latLong?.longitude) {
        dispatch(
          setSearchOptionsEnabled({
            searchNowEnabled: true,
            manualSearchEnabled: true,
          })
        );
      } else {
        dispatch(
          setSearchOptionsEnabled({
            searchNowEnabled: false,
            manualSearchEnabled: false,
          })
        );
      }
    } catch (error: any) {
      console.error(error);
      dispatch(
        setSearchOptionsEnabled({
          searchNowEnabled: false,
          manualSearchEnabled: false,
        })
      );
    }
  }, [propertyDetails, propertyAddress]);

  useEffect(() => {
    if (searchNowEnabled !== null && manualSearchEnabled !== null) return;

    loadLatLong();
  }, [propertyDetails, propertyAddress]);

  return null;
};

export default ComparativeMarketAnalysisPreloader;
