import { useEffect, useMemo, useState } from 'react';
import {
  AgxButton,
  AgxColumn,
  AgxHeader,
  AgxRow,
  AgxSelect,
  PartnershipInfo,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import './reportingDashboard.scss';
import {
  EntityTypes,
  ReportingDashboardState,
  TimePeriods,
  useLoadAgentStatistics,
} from './reportingDashboardReducer';
import Leaderboard from './Leaderboard/Leaderboard';
import UpcomingSettlements from './UpcomingSettlements/UpcomingSettlements';
import EntityStatistics from './EntityStatistics/EntityStatistics';
import useGoals from './Hooks/useGoals';
import GoalsModal from './GoalsModal/GoalsModal';
import { useAvailableEntityTypes } from './Hooks/useAvailableEntityTypes';
import { usePersonalStatistics } from './Hooks/usePersonalStatistics';
import { useLeaderboardStatistics } from './Hooks/useLeaderboardStatistics';
import { ReactComponent as TargetOutline } from './Assets/target-outline.svg';
import {
  EntityTimePeriodStatistics,
  PartnershipStatistics,
} from '@urbanx/agx-ui-components/dist/cjs/types/components/portal/Reporting/types';

const PartnershipYearOptions = [
  {
    value: 'This Year',
    label: 'This Year',
  },
  {
    value: 'Last Year',
    label: 'Last Year',
  },
];

const ReportingDashboard = () => {
  const [entityType, setEntityType] = useState(EntityTypes[0]);
  const [timePeriod, setTimePeriod] = useState(TimePeriods[0]);
  const [partnershipYearPeriod, setPartnershipYearPeriod] = useState(
    PartnershipYearOptions[0]
  );

  const [loading, errorRetrievingStatistics] = useLoadAgentStatistics(
    entityType.value
  );

  const [personalStatistics, personalTitle] = usePersonalStatistics(
    entityType.value,
    timePeriod.value,
    partnershipYearPeriod.value
  );

  const leaderboardStatistics = useLeaderboardStatistics(
    entityType.value,
    timePeriod.value
  );

  const reportingDashboardState: ReportingDashboardState = useAppSelector(
    state => state.reportingDashboard
  );
  const personalStatsError =
    reportingDashboardState?.stats?.agentStatistics?.error !== null &&
    reportingDashboardState?.stats?.agentStatistics?.error !== undefined &&
    entityType.value === EntityTypes[0].value
      ? reportingDashboardState?.stats?.agentStatistics?.error
      : null;

  const [goals, saveGoals] = useGoals(entityType.value);

  const showPartnershipInfo = entityType.value === EntityTypes[2].value;

  const availableEntityTypes = useAvailableEntityTypes();

  useEffect(() => {
    if (!availableEntityTypes.some(aet => aet.value === entityType.value)) {
      setEntityType(availableEntityTypes[0]);
    }
  }, [availableEntityTypes, entityType]);

  const isThisYear =
    partnershipYearPeriod.value === PartnershipYearOptions[0].value;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const title = useMemo(() => {
    if (loading) return 'Loading sales data';

    const personalStats = personalStatistics as EntityTimePeriodStatistics;

    if (!personalStats) {
      return 'No sales data to show';
    }

    return personalTitle;
  }, [loading, personalStatistics, personalTitle]);

  const personalStats = useMemo(() => {
    if (loading) return null;

    switch (entityType.value) {
      case EntityTypes[0].value:
      case EntityTypes[1].value:
        return personalStatistics as EntityTimePeriodStatistics;
      case EntityTypes[2].value:
        const partnershipStats = personalStatistics as PartnershipStatistics;
        switch (partnershipYearPeriod.value) {
          case PartnershipYearOptions[0].value:
            return partnershipStats?.thisYear;
          case PartnershipYearOptions[1].value:
            return partnershipStats?.lastYear;
          default:
            return null;
        }
      default:
        return null;
    }
  }, [personalStatistics, entityType, partnershipYearPeriod, loading]);

  return (
    <AgxColumn fill extraClasses="reportingDashboard">
      <div className="reportTitle">
        <AgxRow extraClasses="reportTitleHeading" centered>
          <AgxHeader size={2} inverse>
            Dashboard
          </AgxHeader>
        </AgxRow>
        <AgxRow centered extraClasses="reportTitleOptions" end>
          {!showPartnershipInfo && (
            <AgxSelect
              id="timePeriodSelector"
              defaultValue={
                TimePeriods.find(x => x.value === timePeriod.value) ??
                TimePeriods[0]
              }
              options={TimePeriods}
              onValueChanged={({ value }) =>
                setTimePeriod(
                  TimePeriods.find(x => x.value === value) ?? TimePeriods[0]
                )
              }
              noHeader
              inline
            />
          )}
          {showPartnershipInfo && (
            <AgxSelect
              id="partnershipYearSelector"
              defaultValue={
                PartnershipYearOptions.find(
                  x => x.value === partnershipYearPeriod.value
                ) ?? PartnershipYearOptions[0]
              }
              options={PartnershipYearOptions}
              onValueChanged={({ value }) =>
                setPartnershipYearPeriod(
                  PartnershipYearOptions.find(x => x.value === value) ??
                    PartnershipYearOptions[0]
                )
              }
              noHeader
              inline
            />
          )}
          <AgxSelect
            id="entityTypeSelector"
            defaultValue={
              availableEntityTypes.find(x => x.value === entityType.value) ??
              availableEntityTypes[0]
            }
            options={availableEntityTypes}
            onValueChanged={({ value }) =>
              setEntityType(
                availableEntityTypes.find(x => x.value === value) ??
                  availableEntityTypes[0]
              )
            }
            noHeader
            inline
            extraClasses="entityTypeSelector"
          />
        </AgxRow>
      </div>
      <div className="reportMainBody">
        {isDialogOpen && (
          <GoalsModal
            saveGoals={saleGoals => saveGoals(entityType.value, saleGoals)}
            goals={goals}
            setIsDialogOpen={setIsDialogOpen}
          />
        )}
        {!showPartnershipInfo && (
          <Leaderboard
            loading={loading}
            leaderboardStats={leaderboardStatistics}
            timePeriod={timePeriod.value}
            entityType={entityType.value}
          />
        )}
        {showPartnershipInfo && (
          <UpcomingSettlements
            loading={loading}
            partnershipStats={personalStatistics as PartnershipStatistics}
          />
        )}
        {errorRetrievingStatistics && (
          <AgxColumn centered extraClasses="reportCardSection">
            <AgxRow spaceBetween extraClasses="reportCardTitle" fill>
              <AgxHeader size={5}>Error on data retrieval</AgxHeader>
            </AgxRow>
            <AgxRow fill>
              <AgxHeader size={3}>{personalStatsError}</AgxHeader>
            </AgxRow>
          </AgxColumn>
        )}
        <AgxColumn centered extraClasses="reportCardSection">
          {!errorRetrievingStatistics && (
            <AgxRow extraClasses="reportCardTitle" fill spaceBetween>
              <AgxHeader size={5}>{title}</AgxHeader>
              {!loading && entityType.value !== EntityTypes[2].value && (
                <AgxButton
                  rightIcon={<TargetOutline />}
                  small
                  primary
                  onClick={() => setIsDialogOpen(!isDialogOpen)}
                  text="Edit Goals"
                />
              )}
            </AgxRow>
          )}
          {!errorRetrievingStatistics && showPartnershipInfo && (
            <PartnershipInfo
              partnershipStats={personalStatistics as PartnershipStatistics}
              isCurrentYear={isThisYear}
            />
          )}
          {!errorRetrievingStatistics && (loading || personalStatistics) && (
            <EntityStatistics
              loading={loading}
              personalStatistics={personalStats}
            />
          )}
        </AgxColumn>
      </div>
    </AgxColumn>
  );
};

export default ReportingDashboard;
