import {
  AgxCheckbox,
  AgxTextInput,
  AgxSelect,
  AgxDatePicker,
  AgxTextArea,
  AgxCurrency,
  AgxVendorBuyerDetails,
  AgxYesNoQuestion,
  AgxSolicitorSearch,
  AgxRadioGroup,
  AgxTenantedPropertyDetails,
  AgxAlternateAddressSearch,
  AgxBuyersAgentSearch,
  AgxChattels,
  AgxRuralParticulars,
  AgxConditionalTextInput,
  AgxPropertyAddressForm,
  AgxCustomCondition,
  AgxSpecialCondition,
  AgxCoolingOffPeriod,
  FormType,
  ElementType,
  AgxSolicitorDetails,
  StakeholderType,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { updateFormValue } from 'features/form/formReducer';
import AgreementDetails from 'features/elements/AgreementDetails/AgreementDetails';
import AuctionDetails from 'features/elements/AuctionDetails/AuctionDetails';
import Commission from 'features/elements/Commission/Commission';
import ComparativeMarketAnalysis from 'features/elements/ComparativeMarketAnalysis/ComparativeMarketAnalysis';
import ListingAgentSelector from 'features/elements/ListingAgentSelector/ListingAgentSelector';
import MarketingPackages from 'features/elements/MarketingPackages/MarketingPackages';
import PersonName from 'features/elements/PersonName/PersonName';
import PoolSafetyCertificate from 'features/elements/PoolSafetyCertificate/PoolSafetyCertificate';
import ReviewAndSign from 'features/elements/ReviewAndSign/ReviewAndSign';
import PropertyIcons from 'features/elements/PropertyIcons/PropertyIcons';
import TypedDocumentUpload from 'features/elements/TypedDocumentUpload/TypedDocumentUpload';
import POADocumentUpload from 'features/elements/POADocumentUpload/POADocumentUpload';
import SaleDetails from 'features/elements/SaleDetails/SaleDetails';
import SaleAndLaunchDetails from 'features/elements/SaleAndLaunchDetails/SaleAndLaunchDetails';
import SignboardRemoval from 'features/elements/SignboardRemoval/SignboardRemoval';
import DepositPayable from 'features/elements/Deposits/DepositPayable';
import Deposit from 'features/elements/Deposits/Deposit';
import ContractDeposit from 'features/elements/Deposits/ContractDeposit';
import BuildingAndPest from 'features/elements/BuildingAndPest/BuildingAndPest';
import SettlementDate from 'features/elements/SettlementDate/SettlementDate';
import AdditionalDocumentations from 'features/elements/AdditionalDocumentations/AdditionalDocumentations';
import DateTime from 'features/elements/DateTime/DateTime';
import SendForDocuSigning from 'features/elements/SendForDocuSigning/SendForDocuSigning';
import MarketingContributions from 'features/elements/MarketingContributions/MarketingContributions';
import PriceOpinion from 'features/elements/PriceOpinion/PriceOpinion';
import PriceSpecification from 'features/elements/PriceSpecification/PriceSpecification';
import DisplayPrice from 'features/elements/DisplayPrice/DisplayPrice';
import FinanceCondition from 'features/elements/FinanceCondition/FinanceCondition';
import AdjustmentCommission from 'features/elements/AdjustmentCommission/AdjustmentCommission';
import PropertyType from 'features/elements/PropertyType/PropertyType';
import VendorDetailsDisplay from 'features/elements/VendorDetailsDisplay/VendorDetailsDisplay';
import CommissionSplit from 'features/elements/CommissionSplit/CommissionSplit';
import ExternalConjunction from 'features/elements/ExternalConjunction/ExternalConjunction';
import LegalDescription from 'features/elements/LegalDescription/LegalDescription';
import { deleteIds } from 'features/form/formUtils';
import {
  deleteFile,
  requestGetFileLink,
  useUploadFile,
} from 'components/ui-components/File/fileApi';
import { useAzureAuth } from './useAzureAuth';
import { useFormRegion } from './useFormRegion';
import { getExistingValue } from 'features/form/formReducer';
import useSuburbSuggestions from './useSuburbSuggestions';
import { useFormSaver } from './useFormSaver';
import useFormAddressSelector from 'selectors/useFormAddressSelector';
import ContractReviewAndSign from 'features/elements/ContractReviewAndSign/ContractReviewAndSign';
import Image from 'features/elements/Image/Image';
import isEqual from 'lodash/isEqual';

export const useElementRenderer = formFunctions => {
  const dispatch = useAppDispatch();
  const [, getAuthToken] = useAzureAuth();
  const { goToFirstPage, goToPreviousPage } = formFunctions;
  const { suburbSuggestions, getSuburbSuggestions } = useSuburbSuggestions();

  const renderElements = (elements, validate, parentPathIds = []) => {
    return elements.map(element =>
      renderElement(element, validate, parentPathIds)
    );
  };

  const { agents: listingAgents } = useAppSelector(state => state.listingAgent);
  const { formValues } = useAppSelector(state => state.form);
  const {
    agentId,
    featureToggles: { useConveyUForSolicitor },
  } = useAppSelector(state => state.agentInfo);
  const { selectedForm } = useAppSelector(state => state.form);
  const formRegion = useFormRegion();
  const uploadFile = useUploadFile();
  const saveForm = useFormSaver();
  const vendorNames = formValues?.Vendors ?? [];

  const leadAgentId = formValues?.ListingAgents?.leadAgentId ?? agentId ?? '';
  const preDefinedConditions = formValues?.PredefinedSpecialConditions;
  const propertyAddress = useFormAddressSelector();
  const australianState = propertyAddress?.state;

  const renderElement = (element, validate, parentPathIds) => {
    const {
      id,
      elementType,
      placeholder,
      subDescription,
      label,
      subLabel,
      required = false,
      isSearchByRegion,
      options,
      isReadonly,
      disabled,
      value: defaultValue,
    } = element;
    const onValueChanged = async (value, saveChanges = false) => {
      /**
       * Reset customSpecialCondition in ContractDeposit and SettlementInfo if CustomSpecialConditions or PredefinedSpecialConditions are modified
       */
      if (value.id === 'CustomSpecialConditions') {
        if (!isEqual(value.value, formValues?.CustomSpecialConditions)) {
          const contractDeposit = formValues?.ContractDeposit;
          const updatedContractDeposit = {
            ...contractDeposit,
            balanceDeposit: {
              ...contractDeposit?.balanceDeposit,
              customSpecialCondition: {},
            },
            initialDeposit: {
              ...contractDeposit?.initialDeposit,
              customSpecialCondition: {},
            },
          };
          dispatch(
            updateFormValue({
              id: 'ContractDeposit',
              value: { ...updatedContractDeposit },
              parentPathIds,
            })
          );

          const settlementInfo = formValues?.SettlementInfo;
          const updatedSettlementInfo = {
            ...settlementInfo,
            customSpecialCondition: {},
          };
          dispatch(
            updateFormValue({
              id: 'SettlementInfo',
              value: { ...updatedSettlementInfo },
              parentPathIds,
            })
          );
        }
      }

      if (value.id === 'PredefinedSpecialConditions') {
        if (
          !isEqual(
            deleteIds(value.value),
            deleteIds(formValues?.PredefinedSpecialConditions)
          )
        ) {
          const contractDeposit = formValues?.ContractDeposit;
          const updatedContractDeposit = {
            ...contractDeposit,
            balanceDeposit: {
              ...contractDeposit?.balanceDeposit,
              customSpecialCondition: {},
            },
            initialDeposit: {
              ...contractDeposit?.initialDeposit,
              customSpecialCondition: {},
            },
          };
          dispatch(
            updateFormValue({
              id: 'ContractDeposit',
              value: { ...updatedContractDeposit },
              parentPathIds,
            })
          );

          const settlementInfo = formValues?.SettlementInfo;
          const updatedSettlementInfo = {
            ...settlementInfo,
            customSpecialCondition: {},
          };
          dispatch(
            updateFormValue({
              id: 'SettlementInfo',
              value: { ...updatedSettlementInfo },
              parentPathIds,
            })
          );
        }
      }

      dispatch(updateFormValue({ ...value, parentPathIds }));

      if (saveChanges) {
        await saveForm();
      }
    };

    const pathIds = [...(parentPathIds ?? []), id];
    const fullId = pathIds.join('.');
    const existingValue = getExistingValue(pathIds, formValues);

    switch (elementType) {
      case ElementType.PersonName:
        return (
          <PersonName
            id={id}
            key={fullId}
            label={label}
            required={required}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
          />
        );

      case ElementType.TextInput:
        const { inputType } = element;

        return (
          <AgxTextInput
            id={id}
            key={fullId}
            label={label}
            placeholder={placeholder}
            required={required}
            onInputValueChange={onValueChanged}
            readonly={isReadonly}
            disabled={disabled}
            stretch
            currency={inputType === 'Currency'}
            email={inputType === 'Email'}
            defaultValue={existingValue ?? defaultValue}
            subDescription={subDescription}
          />
        );

      case ElementType.AlternateAddressSearch:
        const { existingAddressLabel, alternateAddressLabel } = element;

        return (
          <AgxAlternateAddressSearch
            id={id}
            key={fullId}
            label={label}
            comparisonAddressLabel={existingAddressLabel}
            alternateAddressLabel={alternateAddressLabel}
            onValueChanged={onValueChanged}
            comparisonAddress={formValues.PropertyAddress}
            defaultValue={existingValue}
            subDescription={subDescription}
            regionToSearch={isSearchByRegion ? formRegion : false}
            validate={validate}
            required={required}
          />
        );
      case ElementType.SolicitorSearch:
        const { solicitorLabel, showConveyUOption, showToBeAdvisedOption } =
          element;

        return (
          <AgxSolicitorDetails
            id={id}
            key={fullId}
            onValueUpdated={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            label={label}
            solicitorLabel={solicitorLabel}
            useConveyUForSolicitor={useConveyUForSolicitor}
            showConveyUOption={showConveyUOption}
            showToBeAdvisedOption={showToBeAdvisedOption}
            vendorBuyerSolicitorInfo={
              id === StakeholderType.VendorSolicitor
                ? formValues.BuyerSolicitor
                : formValues.VendorSolicitor
            }
            formType={selectedForm.formType}
            validate={validate}
            required={required}
          />
        );

      case ElementType.BuyersAgentSearch:
        return (
          <AgxBuyersAgentSearch
            id={id}
            key={fullId}
            onValueUpdated={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            label={label}
          />
        );
      case ElementType.ListingAgentSelector:
        return (
          <ListingAgentSelector
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
          />
        );
      case ElementType.Radio:
        return (
          <AgxRadioGroup
            id={id}
            key={fullId}
            label={label}
            options={options}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            validate={validate}
            required={required}
          />
        );

      case ElementType.Dropdown:
        return (
          <AgxSelect
            id={id}
            key={fullId}
            label={label}
            defaultValue={existingValue}
            options={options}
            onValueChanged={onValueChanged}
          />
        );

      case ElementType.DatePicker:
        return (
          <AgxDatePicker
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            label={label}
            date
            stretch
            defaultValue={existingValue ?? defaultValue}
          />
        );

      case ElementType.Date:
        return (
          <AgxDatePicker
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            label={label}
            date
            stretch
            required={required}
            defaultValue={existingValue ?? defaultValue}
            validate={validate}
          />
        );

      case ElementType.LegalDescription:
        const { hidePropertyType } = element;

        return (
          <LegalDescription
            id={id}
            key={fullId}
            hidePropertyType={hidePropertyType}
            readonly={isReadonly}
            legalDescription={existingValue ?? defaultValue}
            onChange={onValueChanged}
            required={required}
            validate={validate}
          />
        );

      case ElementType.PropertyIcons:
        return (
          <PropertyIcons
            id={id}
            key={fullId}
            defaultValue={existingValue ?? defaultValue}
            onChange={onValueChanged}
          />
        );

      case ElementType.AuctionDetails:
        return (
          <AuctionDetails
            id={id}
            key={fullId}
            required={required}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
          />
        );

      case ElementType.AgreementDetails:
        const { days, restrictDuration } = element;
        return (
          <AgreementDetails
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            validate={validate}
            state={australianState}
            days={days}
            restrictDuration={restrictDuration}
          />
        );

      case ElementType.Checkbox:
        const { errorMessage, subLabelIsSmall } = element;
        return (
          <AgxCheckbox
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            label={label}
            stretch
            defaultValue={existingValue}
            subLabel={subLabel}
            subLabelIsSmall={subLabelIsSmall}
            required={required}
            errorMessage={errorMessage}
            validate={validate}
          />
        );

      case ElementType.MarketingPackage:
        return (
          <MarketingPackages
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            validate={validate}
          />
        );

      case ElementType.Commission:
        const { commissionTypes, showCommissionRangeDisclaimer } = element;

        return (
          <Commission
            id={id}
            key={fullId}
            commissionTypes={commissionTypes}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
            showCommissionRangeDisclaimer={showCommissionRangeDisclaimer}
            validate={validate}
            required={required}
          />
        );

      case ElementType.ComparativeMarketAnalysis:
        return (
          <ComparativeMarketAnalysis
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
          />
        );

      case ElementType.PoolSafetyCertificate:
        return (
          <PoolSafetyCertificate
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            state={australianState}
            required={required}
            validate={validate}
          />
        );

      case ElementType.TenantedProperty:
        const {
          noDefaultValueCheck,
          showHolidayRental,
          showPreviousTenancyDetails,
        } = element;
        return (
          <AgxTenantedPropertyDetails
            id={id}
            key={fullId}
            onValueUpdated={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            label={label}
            noDefaultValueCheck={noDefaultValueCheck}
            required={required}
            validate={validate}
            showHolidayRentalOption={showHolidayRental}
            showPreviousTenancyDetails={showPreviousTenancyDetails}
          />
        );

      case ElementType.ReviewAndSign:
        const {
          agentSignsFirst,
          formType,
          emailStyle,
          vendors,
          editVendorDetails,
          editBuyerDetails,
          formName,
          sendViaDocuSign,
          disableFormSubmission,
          sendConfigurations,
        } = element;

        if (formType === FormType.ContractRequest) {
          return (
            <ContractReviewAndSign
              goToFirstPage={goToFirstPage}
              goToPreviousPage={goToPreviousPage}
              vendors={vendors}
              editVendorDetails={editVendorDetails}
              editBuyerDetails={editBuyerDetails}
              formName={formName}
              sendViaDocuSign={sendViaDocuSign}
              disableFormSubmission={disableFormSubmission}
              sendConfigurations={sendConfigurations}
            />
          );
        }

        return (
          <ReviewAndSign
            key={fullId}
            goToFirstPage={goToFirstPage}
            agentSignsFirst={agentSignsFirst}
            emailStyle={emailStyle}
          />
        );

      case ElementType.AddressSearch:
        return (
          <AgxPropertyAddressForm
            id={id}
            key={fullId}
            label={label}
            searchSuburb
            hideUnitLot={element.hideUnitLot}
            isReadonly={isReadonly}
            validate={validate}
            showSearchForAddress={false}
            suburbSuggestions={suburbSuggestions}
            stateToSearch={element.stateToSearch}
            onFetchSuburbSuggestions={suburbTerm =>
              getSuburbSuggestions(suburbTerm)
            }
            onClearSuburbSuggestions={() => getSuburbSuggestions('')}
            defaultValue={existingValue ?? defaultValue}
            onAddressSelected={value => onValueChanged({ id, value })}
          />
        );

      case ElementType.TypedDocumentUpload:
        const { documentConfig } = element;

        return (
          <TypedDocumentUpload
            id={id}
            key={fullId}
            title={label}
            defaultValue={existingValue ?? defaultValue}
            documentConfig={documentConfig}
            onValueChanged={value => {
              onValueChanged(value, true);
            }}
            validate={validate}
          />
        );

      case ElementType.POADocumentUpload:
        const { poaDocumentConfig } = element;

        return (
          <POADocumentUpload
            id={id}
            key={fullId}
            title={label}
            defaultValue={existingValue ?? defaultValue}
            pOADocumentConfig={poaDocumentConfig}
            onValueChanged={value => {
              onValueChanged(value, true);
            }}
            validate={validate}
          />
        );

      case ElementType.VendorBuyerDetails:
        const {
          editScope,
          stakeholderType,
          startCollapsed,
          requiresProofOfIdentity,
          vendorBuyerTypes,
          vendorBuyerConfig,
          powerOfAttorneyConfig,
          companyConfig,
        } = element;
        const agents = listingAgents.map(agent => ({
          label: `${agent.name.firstName} ${agent.name.lastName}`,
          value: agent.id,
        }));

        return (
          <AgxVendorBuyerDetails
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            propertyAddress={propertyAddress}
            defaultValue={existingValue ?? defaultValue}
            editScope={editScope}
            stakeholderType={stakeholderType}
            startCollapsed={startCollapsed}
            requiresProofOfIdentity={requiresProofOfIdentity}
            vendorBuyerTypes={vendorBuyerTypes}
            vendorBuyerConfig={vendorBuyerConfig}
            companyConfig={companyConfig}
            powerOfAttorneyConfig={powerOfAttorneyConfig}
            selectedFormType={selectedForm.formType}
            validate={validate}
            required={required}
            getAuthToken={getAuthToken}
            uploadFile={uploadFile}
            deleteFile={deleteFile}
            getFileLink={requestGetFileLink}
            agents={agents}
            leadAgentId={leadAgentId}
          />
        );

      case ElementType.SaleDetails:
        return (
          <SaleDetails
            id={id}
            key={fullId}
            validate={validate}
            propertyAddress={propertyAddress}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
          />
        );

      case ElementType.SaleAndLaunchDetails:
        return (
          <SaleAndLaunchDetails
            id={id}
            key={fullId}
            propertyAddress={propertyAddress}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            validate={validate}
          />
        );

      case ElementType.TextArea:
        const { flexibleHeight } = element;

        return (
          <AgxTextArea
            id={id}
            key={fullId}
            defaultValue={existingValue ?? defaultValue}
            label={label}
            placeholder={placeholder}
            onInputValueChange={onValueChanged}
            stretch
            rows={3}
            flexibleHeight={flexibleHeight}
          />
        );

      case ElementType.YesNoQuestion:
        const { yesLabel, noLabel, reverseLabelOrder, showOptionalLabel } =
          element;

        return (
          <AgxYesNoQuestion
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            label={label}
            subDescription={subDescription}
            noLabel={noLabel}
            showOptionalLabel={showOptionalLabel}
            yesLabel={yesLabel}
            required={required}
            validate={validate}
            reverseLabelOrder={reverseLabelOrder}
          />
        );

      case ElementType.SecondaryAgentCommissionSplit:
        return (
          <CommissionSplit
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
            label={label}
            validate={validate}
          />
        );

      case ElementType.PredefinedSpecialConditions:
        return (
          <AgxSpecialCondition
            id={id}
            key={fullId}
            defaultValue={existingValue ?? defaultValue}
            onValueChange={onValueChanged}
            regionToSearch={null}
            vendors={vendorNames}
            validate={validate}
          />
        );

      case ElementType.SpecialConditions:
        return (
          <AgxCustomCondition
            id={id}
            key={fullId}
            predefinedConditions={preDefinedConditions}
            onValueChange={onValueChanged}
            validate={validate}
            defaultValue={existingValue ?? defaultValue}
            disableSubPoints={element.disableSubPoints ?? false}
            backgroundColor={element.backgroundColor ?? false}
            label={label}
            subLabel={subLabel}
          />
        );

      case ElementType.DepositPayable:
        return (
          <DepositPayable
            key={fullId}
            id={id}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
            required={required}
            validate={validate}
          />
        );

      case ElementType.Deposit:
        return (
          <Deposit
            key={fullId}
            id={id}
            label={label}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
            showDepositDropdownOption={element.showDepositDropdownOption}
            required={required}
            validate={validate}
            decimalPoints={0}
          />
        );

      case ElementType.ContractDeposit:
        return (
          <ContractDeposit
            key={fullId}
            id={id}
            label={label}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
            required={required}
            validate={validate}
          />
        );

      case ElementType.BuildingAndPest:
        return (
          <BuildingAndPest
            key={fullId}
            id={id}
            label={label}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
            required={required}
            validate={validate}
          />
        );

      case ElementType.SettlementDate:
        const {
          showSpecialConditionOption,
          showAsPerContractOption,
          showInLineWithOption,
          showFromContractDateDropdownOption,
        } = element;
        return (
          <SettlementDate
            key={fullId}
            id={id}
            label={label}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
            required={required}
            validate={validate}
            showSpecialConditionOption={showSpecialConditionOption}
            showAsPerContractOption={showAsPerContractOption}
            showInLineWithOption={showInLineWithOption}
            showFromContractDateDropdownOption={
              showFromContractDateDropdownOption
            }
          />
        );

      case ElementType.SignboardRemoval:
        return (
          <SignboardRemoval
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            validate={validate}
          />
        );
      case ElementType.AdditionalDocumentations:
        return (
          <AdditionalDocumentations
            key={fullId}
            id={id}
            label={label}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            validate={validate}
          />
        );
      case ElementType.DateTime:
        return (
          <DateTime
            key={fullId}
            id={id}
            label={label}
            defaultValue={existingValue ?? defaultValue}
            onValueChanged={onValueChanged}
          />
        );

      case ElementType.SendForDocuSigning:
        return (
          <SendForDocuSigning
            key={fullId}
            id={id}
            label={label}
            defaultValue={existingValue}
            onValueChanged={onValueChanged}
            validate={validate}
          />
        );

      case ElementType.MarketingContributions:
        const { displayContributionsInfo } = element;

        return (
          <MarketingContributions
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            displayContributionsInfo={displayContributionsInfo}
            label={label}
          />
        );

      case ElementType.PriceSpecification:
        return (
          <PriceSpecification
            id={id}
            key={fullId}
            subDescription={subDescription}
            onValueChanged={onValueChanged}
            label={label}
            required={required}
            defaultValue={existingValue}
            showToBeConfirmed={element.showToBeConfirmed}
            maxPriceRangePercent={element.maxPriceRangePercent}
            defaultUpperRangeSelected={element.defaultUpperRangeSelected}
            validate={validate}
          />
        );

      case ElementType.PriceOpinion:
        return (
          <PriceOpinion
            id={id}
            key={fullId}
            subDescription={subDescription}
            onValueChanged={onValueChanged}
            label={label}
            required={required}
            defaultValue={existingValue}
            maxPriceRangePercent={element.maxPriceRangePercent}
          />
        );

      case ElementType.DisplayPrice:
        const {
          showToBeConfirmed,
          showReservePrice,
          nonRuralAgreementSubLabel,
        } = element;

        return (
          <DisplayPrice
            id={id}
            key={fullId}
            subDescription={subDescription}
            onValueChanged={onValueChanged}
            label={label}
            subLabel={subLabel}
            nonRuralAgreementSubLabel={nonRuralAgreementSubLabel}
            required={required}
            defaultValue={existingValue}
            showToBeConfirmed={showToBeConfirmed}
            showReservePrice={showReservePrice}
            validate={validate}
          />
        );

      case ElementType.Currency:
        const { decimalPoints, displayErrors } = element;

        return (
          <AgxCurrency
            id={id}
            key={fullId}
            label={label}
            placeholder={placeholder}
            required={required}
            onInputValueChange={onValueChanged}
            readonly={isReadonly}
            disabled={disabled}
            stretch
            defaultValue={existingValue ?? defaultValue}
            subDescription={subDescription}
            validate={validate}
            decimalPoints={decimalPoints ?? 0}
            displayErrors={displayErrors}
          />
        );

      case ElementType.SubjectToFinance:
        return (
          <FinanceCondition
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            validate={validate}
            required={required}
          />
        );

      case ElementType.AdjustmentToCommission:
        return (
          <AdjustmentCommission
            key={fullId}
            id={id}
            label={label}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
            validate={validate}
            required={required}
          />
        );

      case ElementType.PropertyType:
        const { caption } = element;
        return (
          <PropertyType
            key={fullId}
            id={id}
            label={label}
            onValueChanged={onValueChanged}
            defaultValue={defaultValue ?? existingValue}
            caption={caption}
            required={required}
          />
        );

      case ElementType.VendorDetailsDisplay:
        return (
          <VendorDetailsDisplay key={fullId} defaultValue={defaultValue} />
        );

      case ElementType.ExternalConjunction:
        return (
          <ExternalConjunction
            key={fullId}
            id={id}
            label={label}
            onValueChanged={onValueChanged}
            defaultValue={existingValue}
            validate={validate}
          />
        );

      case ElementType.ConditionalTextInput:
        return (
          <AgxConditionalTextInput
            id={id}
            key={fullId}
            onValueChanged={onValueChanged}
            defaultValue={existingValue ?? defaultValue}
            label={label}
            subDescription={subDescription}
            placeholder={placeholder}
            disabled={disabled}
            readonly={isReadonly}
            required={required}
            validate={validate}
            inputType={element.inputType}
            noLabel={element.noLabel}
            yesLabel={element.yesLabel}
            reverseLabelOrder={element.reverseLabelOrder}
            maxLength={element.maxLength}
            minLength={element.minLength}
            showTextInputOnYesSelection={element.showTextInputOnYesSelection}
            noTextInputValue={element.noTextInputValue}
            inputHeader={element.inputHeader}
            flexibleHeight={element.flexibleHeight}
          />
        );

      case ElementType.ChattelsIncluded:
        return (
          <AgxChattels
            id={id}
            key={fullId}
            onValueChange={onValueChanged}
            label={label}
            state={australianState}
            defaultValue={existingValue ?? defaultValue}
            flexibleHeight={element.flexibleHeight}
          />
        );
      case ElementType.RuralParticulars:
        return (
          <AgxRuralParticulars
            id={id}
            key={id}
            onValueChange={onValueChanged}
            label={label}
            defaultValue={existingValue ?? defaultValue}
          />
        );
      case ElementType.CoolingOffPeriod:
        return (
          <AgxCoolingOffPeriod
            id={id}
            key={id}
            onValueChanged={onValueChanged}
            label={label}
            defaultValue={existingValue ?? defaultValue}
            options={options}
            required={required}
            validate={validate}
          />
        );
      case ElementType.Image:
        return (
          <Image
            id={id}
            key={id}
            label={label}
            defaultValue={existingValue ?? defaultValue}
          />
        );
      default:
        console.warn('unknown elementType: ' + elementType);
        return;
    }
  };

  return renderElements;
};
