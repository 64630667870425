import React, { useState } from 'react';
import {
  AgxBodyText,
  AgxButton,
  AgxPropertyAddressForm,
  Images,
  PropertyAddress,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import useSuburbSuggestions from 'hooks/useSuburbSuggestions';
import { PageTitle } from 'features/form/FormHeader/components/PageTitle';
import './ManualAddress.scss';

interface ManualAddressProps {
  disabled: boolean;
  onAddressSearch: () => void;
  onBeginCampaign: (address: PropertyAddress) => void;
}

const ManualAddress: React.FC<ManualAddressProps> = ({
  disabled,
  onAddressSearch,
  onBeginCampaign,
}) => {
  const [validatePropertyAddressFields, setValidatePropertyAddressFields] =
    useState(false);
  const selectedForm = useAppSelector(state => state.form.selectedForm);
  const { suburbSuggestions, getSuburbSuggestions } = useSuburbSuggestions();
  const getEmptyAddress = () =>
    ({
      unitNumber: '',
      streetAddress: '',
      suburb: '',
      state: selectedForm?.state ?? '',
      postalCode: '',
      country: '',
      formattedAddress: '',
    }) as PropertyAddress;

  const [address, setAddress] = useState<PropertyAddress>(getEmptyAddress());

  if (!selectedForm) return;

  const validatePropertyAddressForm = () => {
    setValidatePropertyAddressFields(true);
    if (
      !address.streetAddress ||
      !address.suburb ||
      !address.state ||
      !address.postalCode
    ) {
      return true;
    }
  };

  return (
    <div className="manualAddressContainer">
      <PageTitle
        onClick={() => void 0}
        title="Enter address"
        breadcrumbsAvailable={false}
      />
      <AgxPropertyAddressForm
        searchSuburb
        hideUnitLot
        showSearchForAddress={false}
        propertyAddressField={false}
        suburbSuggestions={suburbSuggestions}
        stateToSearch={selectedForm.state}
        onFetchSuburbSuggestions={suburbTerm =>
          getSuburbSuggestions(suburbTerm || '')
        }
        inverseLabel
        disabled={disabled}
        validate={validatePropertyAddressFields}
        onClearSuburbSuggestions={() => getSuburbSuggestions('')}
        defaultValue={address}
        onAddressSelected={value => {
          if (value) {
            setAddress(value);
          }
        }}
      />
      <AgxButton
        large
        primary
        shrinkOnLargerDevices
        text="Next"
        disabled={disabled}
        rightIcon={<Images.ArrowForwardOutline />}
        onClick={() => {
          const hasErrors = validatePropertyAddressForm();
          if (!hasErrors) {
            onBeginCampaign(address);
          }
        }}
        extraClasses="manualAddressFormNextBtn"
      />
      <AgxBodyText medium inverse extraClasses="enterAddressManuallyText">
        Is the property in RP Data?{' '}
        <button
          className="enterAddressManuallyLink"
          onClick={() => {
            if (!disabled) {
              onAddressSearch();
              setAddress(getEmptyAddress());
              setValidatePropertyAddressFields(false);
            }
          }}
        >
          Search for property
        </button>
      </AgxBodyText>
    </div>
  );
};

export default ManualAddress;
