import { GoogleMap, MarkerF } from '@react-google-maps/api';
import React, { useCallback, useEffect, useState } from 'react';
import useFormPropertyDetailsSelector from 'selectors/useFormPropertyDetailsSelector';
import useFormAddressSelector from 'selectors/useFormAddressSelector';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { getLatLongForAddress } from 'utils/getLatLong';
import DefaultMarker from './assets/icons/marker-default.svg';
import SelectedtMarker from './assets/icons/marker-selected.svg';
import TargettMarker from './assets/icons/targetMarker.svg';

export const SearchMap = ({ locationsToMark }) => {
  const propertyAddress = useFormAddressSelector();
  const propertyDetails = useFormPropertyDetailsSelector();
  const [center, setCenter] = useState({
    lat: propertyDetails?.latitude,
    lng: propertyDetails?.longitude,
  });
  const [renderMarkers, setRenderMarkers] = useState(false);

  const loadLatLong = useCallback(async () => {
    const latLong = await getLatLongForAddress(
      propertyAddress?.formattedAddress
    );

    if (latLong?.latitude && latLong?.longitude) {
      setCenter({
        lat: latLong.latitude,
        lng: latLong.longitude,
      });
    }
  }, [propertyAddress, propertyDetails]);

  useEffect(() => {
    if (!!center.lat && !!center.lng) return;

    loadLatLong();
  }, [propertyAddress, propertyDetails]);

  useEffect(() => {
    if (
      center.lat == null ||
      (center.lng == null &&
        propertyDetails.latitude != null &&
        propertyDetails.longitude != null)
    ) {
      setCenter({
        lat: propertyDetails.latitude,
        lng: propertyDetails.longitude,
      });
    }
  }, [propertyDetails]);

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      setTimeout(() => setRenderMarkers(true), 500);
    }
  }, [map]);

  useEffect(() => {
    if (map && renderMarkers && !!window?.google?.maps?.LatLngBounds) {
      // Create a new instance of LatLngBounds
      const bounds = new window.google.maps.LatLngBounds();

      // Extend the bounds with the property's location
      bounds.extend(center);

      // Extend the bounds with each location in locationsToMark
      locationsToMark?.forEach(({ latitude, longitude }) => {
        bounds.extend({ lat: latitude, lng: longitude });
      });

      // Adjust the map's viewport
      map.fitBounds(bounds);
    }
  }, [map, renderMarkers, locationsToMark, center]);

  const isMobile = ScreenSize() === Breakpoints.Mobile;

  const containerStyle = {
    width: '100%',
    height: isMobile ? '240px' : '100%',
  };

  if (!center || !center.lat || !center.lng) return null;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onLoad={onLoad}
      onTilesLoaded={() => setRenderMarkers(true)}
      onUnmount={onUnmount}
    >
      {renderMarkers &&
        locationsToMark?.map(({ latitude, longitude, selected }, index) => (
          <MarkerF
            key={`markerlocation-${index}-${selected}`}
            position={{ lat: latitude, lng: longitude }}
            icon={selected ? SelectedtMarker : DefaultMarker}
          />
        ))}
      {renderMarkers && (
        <MarkerF
          position={{ lat: center.lat, lng: center.lng }}
          key="propertyMarker"
          icon={TargettMarker}
        />
      )}
    </GoogleMap>
  );
};
