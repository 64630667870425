import { AgxColumn, CampaignStage } from '@urbanx/agx-ui-components';
import AgencyAgreementInfoPanel from './AgencyAgreement/AgencyAgreementInfoPanel';
import AgencyAgreementSubmissionInfoPanel from './AgencyAgreementSubmission/AgencyAgreementSubmissionInfoPanel';
import ListingsPanel from './Listing/ListingsPanel';
import './CampaignInfoPanel.scss';
import { CampaignInfoProps } from 'Api/Campaigns/Types/campaignTypes';
import { getEnumValue } from 'helpers/enumHelper';

const CampaignInfoPanel = ({
  campaign,
  currentTab,
  mobile = false,
}: CampaignInfoProps) => {
  if (!campaign) return null;

  const { stage } = campaign;

  const getInfoPanel = () => {
    switch (getEnumValue(CampaignStage, stage)) {
      case CampaignStage.AgreementDraft:
      case CampaignStage.AgreementAgentSigning:
      case CampaignStage.AgreementVendorSigning:
      case CampaignStage.AgreementSignedByAllParties:
        return (
          <AgencyAgreementInfoPanel
            currentTab={currentTab}
            campaign={campaign}
            mobile={mobile}
          />
        );
      case CampaignStage.AgreementSubmissionIncomplete:
        return (
          <AgencyAgreementSubmissionInfoPanel
            campaign={campaign}
            mobile={mobile}
            currentTab={currentTab}
          />
        );
      case CampaignStage.ListingCurrent:
      case CampaignStage.ListingUnderContract:
      case CampaignStage.ListingWithdrawn:
      case CampaignStage.ListingSold:
        return (
          <ListingsPanel
            campaign={campaign}
            mobile={mobile}
            currentTab={currentTab}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <AgxColumn extraClasses="campaignInfoPanel">{getInfoPanel()}</AgxColumn>
  );
};

export default CampaignInfoPanel;
