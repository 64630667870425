import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  AgxBodyText,
  AgxColumn,
  AgxCurrency,
  AgxDivider,
  AgxHeader,
  AgxLabel,
  AgxRow,
  Images,
  MarketingContributions as MarketingContributionsModel,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import { formatCurrencyAsNumber } from 'utils/formatNumber';
import { ReactComponent as InfoOutline } from './info-outline.svg';
import { useMarketingPackageTotal } from './useMarketingPackageTotal';
import './MarketingContributions.scss';

const initialState: MarketingContributionsModel = {
  vendorUpfrontPayment: 0,
  agentContributionNoReimbursement: 0,
  agentContributionToBeReimbursed: 0,
};

const calculateVendorUpfrontPayment = (
  contributions: MarketingContributionsModel,
  marketPackageTotal: number | null
) => {
  const { agentContributionNoReimbursement, agentContributionToBeReimbursed } =
    contributions;

  const totalContribution =
    Number(agentContributionNoReimbursement) +
    Number(agentContributionToBeReimbursed);

  return (marketPackageTotal ?? 0) - totalContribution;
};

const getInitialState = (
  defaultValue: MarketingContributionsModel,
  marketPackageTotal: number | null
) => {
  const initialValues = {
    agentContributionNoReimbursement:
      defaultValue?.agentContributionNoReimbursement != null
        ? Number(defaultValue.agentContributionNoReimbursement)
        : initialState.agentContributionNoReimbursement,
    agentContributionToBeReimbursed:
      defaultValue?.agentContributionToBeReimbursed != null
        ? Number(defaultValue.agentContributionToBeReimbursed)
        : initialState.agentContributionToBeReimbursed,
    vendorUpfrontPayment:
      defaultValue?.vendorUpfrontPayment != null
        ? Number(defaultValue.vendorUpfrontPayment)
        : initialState.vendorUpfrontPayment,
  };

  const {
    vendorUpfrontPayment,
    agentContributionNoReimbursement,
    agentContributionToBeReimbursed,
  } = initialValues;

  const total =
    Number(vendorUpfrontPayment) +
    Number(agentContributionNoReimbursement) +
    Number(agentContributionToBeReimbursed);

  if (total === marketPackageTotal) {
    return initialValues;
  } else {
    return {
      ...initialState,
      vendorUpfrontPayment: marketPackageTotal ?? 0,
    };
  }
};

interface MarketingContributionsProps {
  id: string;
  onValueChanged: (args: { id: string; value: any }) => void;
  defaultValue: MarketingContributionsModel;
  displayContributionsInfo?: boolean;
  label: string;
}

const MarketingContributions = ({
  id,
  onValueChanged,
  defaultValue,
  displayContributionsInfo,
  label,
}: MarketingContributionsProps) => {
  const marketPackageTotal = useMarketingPackageTotal();
  const agencyName = useAppSelector(
    state => state.agencies?.agencyInfo?.info?.branding?.agencyName
  );

  const [marketingContributions, setMarketingContributions] = useState(
    getInitialState(defaultValue, marketPackageTotal)
  );

  const [error, setError] = useState<string | null>(null);

  const {
    vendorUpfrontPayment,
    agentContributionNoReimbursement,
    agentContributionToBeReimbursed,
  } = marketingContributions;

  useEffect(() => {
    const formValid = isFormValid();

    if (formValid) {
      onValueChanged({ id, value: marketingContributions });
      setError(null);
    } else {
      setError('Agent contribution must not exceed total price');
      onValueChanged({ id, value: null });
    }
  }, [marketingContributions]);

  const isFormValid = useCallback(() => {
    const totalContribution =
      Number(agentContributionNoReimbursement) +
      Number(agentContributionToBeReimbursed);

    return totalContribution <= (marketPackageTotal ?? 0);
  }, [
    agentContributionNoReimbursement,
    agentContributionToBeReimbursed,
    marketPackageTotal,
  ]);

  const onContributionChanged = useCallback(
    (updatedMarketingContributions: MarketingContributionsModel) => {
      setMarketingContributions({
        ...updatedMarketingContributions,
        vendorUpfrontPayment: calculateVendorUpfrontPayment(
          updatedMarketingContributions,
          marketPackageTotal
        ),
      });
    },
    [setMarketingContributions, marketPackageTotal]
  );

  useEffect(() => {
    onContributionChanged({
      ...marketingContributions,
    });
  }, [marketPackageTotal]);

  if (marketPackageTotal == null) {
    return (
      <AgxColumn fill largeGap>
        <AgxHeader size={3}>No marketing package details found</AgxHeader>
        <AgxRow fill mediumGap>
          <InfoOutline />
          <AgxBodyText small neutralGrayColor>
            Marketing details must be filled in to allow for marketing
            contributions split
          </AgxBodyText>
        </AgxRow>
      </AgxColumn>
    );
  }

  const handleAgentContributionNoReimbursementChange = ({
    value,
  }: {
    value: string | null;
  }) => {
    onContributionChanged({
      ...marketingContributions,
      agentContributionNoReimbursement: parseFloat(value ?? '0'),
    });
  };

  const handleAgentContributionToBeReimbursedChange = ({
    value,
  }: {
    value: string | null;
  }) => {
    onContributionChanged({
      ...marketingContributions,
      agentContributionToBeReimbursed: parseFloat(value ?? '0'),
    });
  };

  return (
    <>
      <AgxLabel large>{label}</AgxLabel>
      <AgxDivider />
      <AgxRow fill spaceBetween>
        <AgxBodyText small>Vendor pay upfront</AgxBodyText>
        <AgxBodyText
          small
          extraClasses={clsx('upfrontPayment', error && 'error')}
        >
          ${formatCurrencyAsNumber(vendorUpfrontPayment?.toString(), 2)}
        </AgxBodyText>
      </AgxRow>
      <AgxDivider />
      <AgxRow fill spaceBetween centered>
        <AgxColumn>
          <AgxBodyText small>Agent Contribution</AgxBodyText>
          <AgxBodyText small>(no reimbursement)</AgxBodyText>
        </AgxColumn>
        <AgxCurrency
          id="agentContribution"
          defaultValue={
            !agentContributionNoReimbursement
              ? ''
              : agentContributionNoReimbursement.toString()
          }
          positive
          decimalPoints={2}
          onInputValueChange={handleAgentContributionNoReimbursementChange}
          noHeader
          displayErrors={false}
          errorMessage={
            (agentContributionNoReimbursement ?? 0) > marketPackageTotal
              ? 'error'
              : null
          }
        />
      </AgxRow>
      <AgxDivider />
      <AgxRow fill spaceBetween centered>
        <AgxColumn>
          <AgxBodyText small>Agent Contribution</AgxBodyText>
          <AgxBodyText small>(to be reimbursed)</AgxBodyText>
        </AgxColumn>
        <AgxCurrency
          id="agentContributionReimburse"
          defaultValue={
            !agentContributionToBeReimbursed
              ? ''
              : agentContributionToBeReimbursed.toString()
          }
          positive
          decimalPoints={2}
          onInputValueChange={handleAgentContributionToBeReimbursedChange}
          noHeader
          displayErrors={false}
          errorMessage={
            (agentContributionToBeReimbursed ?? 0) > marketPackageTotal
              ? 'error'
              : null
          }
        />
      </AgxRow>
      {error && (
        <>
          <AgxRow fill centered mediumGap end>
            <Images.AlertCircle />
            <AgxLabel small>{error}</AgxLabel>
          </AgxRow>
        </>
      )}
      <AgxDivider thick />
      <AgxRow spaceBetween fill>
        <AgxBodyText medium>Total</AgxBodyText>
        <AgxBodyText medium>
          ${formatCurrencyAsNumber(marketPackageTotal?.toString(), 2)}
        </AgxBodyText>
      </AgxRow>
      {displayContributionsInfo && !!agentContributionNoReimbursement && (
        <>
          <AgxDivider expanded />
          <AgxRow fill mediumGap extraClasses="contributionsInfo">
            <InfoOutline />
            <AgxColumn largeGap>
              <AgxBodyText small neutralGrayColor>
                Agent Contribution (no reimbursement) will appear on the Form 6
                as:
              </AgxBodyText>
              <AgxBodyText small neutralGrayColor>
                "The amount of $
                {formatCurrencyAsNumber(
                  agentContributionNoReimbursement?.toString(),
                  2
                )}{' '}
                to be paid upfront by {agencyName}, with no reimbursement
                required."
              </AgxBodyText>
            </AgxColumn>
          </AgxRow>
        </>
      )}
      {displayContributionsInfo && !!agentContributionToBeReimbursed && (
        <>
          <AgxDivider expanded />
          <AgxRow fill mediumGap extraClasses="contributionsInfo">
            <InfoOutline />
            <AgxColumn largeGap>
              <AgxBodyText small neutralGrayColor>
                Agent Contribution (to be reimbursed) will appear on the Form 6
                as:
              </AgxBodyText>
              <AgxBodyText small neutralGrayColor>
                "The amount of $
                {formatCurrencyAsNumber(
                  agentContributionToBeReimbursed?.toString(),
                  2
                )}{' '}
                to be paid upfront by {agencyName}, to be reimbursed by the
                vendor on settlement or withdrawal of the property from the
                market."
              </AgxBodyText>
            </AgxColumn>
          </AgxRow>
        </>
      )}
    </>
  );
};

export default MarketingContributions;
