import clsx from 'clsx';
import { useRef } from 'react';
import {
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxLabel,
  PageStatusType,
  FormType,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { findLastIndex } from '../formUtils';
import './DesktopSidebar.scss';

export const DesktopSidebar = ({
  address,
  formTypeValue,
  pages,
  currentPage,
  setPage,
  pageValidation,
  updatePageValidationFlag,
}) => {
  const pageStatuses = useAppSelector(state => state.form.pageStatuses);

  const elementRef = useRef(null);

  const twoLineAddress = cleanTwoLineAddress(address);
  const [addressLineOne, addressLineTwo] = twoLineAddress;
  const formName = formTypeValue => {
    switch (formTypeValue) {
      case FormType.ListingGenerator:
        return 'Agreement';
      case FormType.ListingSubmission:
        return 'Listing Submission';
      case FormType.ContractRequest:
        return 'Contract';
      case FormType.ContractSubmission:
        return 'Contract Submission';
      default:
        return '';
    }
  };

  const isTablet =
    ScreenSize() === Breakpoints.Tablet_Landscape ||
    ScreenSize() === Breakpoints.Tablet_Portrait;
  const filteredPages = pages
    ?.map((page, pageNumber) => {
      return {
        name: page.name,
        isActive: page.name === currentPage?.name,
        pageNumber: pageNumber,
        hasBreadcrumbs: page.hasBreadcrumbs,
      };
    })
    ?.filter(pageDetails => pageDetails.hasBreadcrumbs);

  const navigate = async pageNumber => {
    const validationResult = await pageValidation(pageNumber);
    if (validationResult.status) {
      if (validationResult.isNextPage || validationResult.isPreviousPage) {
        setPage(pageNumber);
        updatePageValidationFlag(false);
      }
      return;
    }
  };

  const pagesListContent = (
    <AgxColumn extraClasses="pageListColumnStyle">
      {filteredPages?.map(
        ({ isActive, name, pageNumber }, orderedPageNumber) => {
          const lastCompletedStep =
            findLastIndex(
              pageStatuses,
              p => p.status === PageStatusType.Complete
            ) ?? 0;
          const pageStatus =
            pageStatuses?.find(p => p.pageName === name)?.status ??
            PageStatusType.FutureDisabled;
          const classes = clsx(
            'agx-progress-item-circle',
            'large',
            isActive ? 'active' : pageStatus
          );
          return (
            <div
              className={`pageRow ${
                !isActive &&
                (orderedPageNumber === lastCompletedStep + 1 &&
                pageStatus === PageStatusType.FutureDisabled
                  ? PageStatusType.Future
                  : pageStatus)
              }`}
              onClick={() => navigate(pageNumber)}
              key={`page-${pageNumber}`}
            >
              <AgxRow centered largeGap>
                <div className={classes}>
                  <div className="agx-progress-item-text">
                    {!isActive && pageStatus === PageStatusType.Invalid
                      ? ''
                      : orderedPageNumber + 1}
                  </div>
                </div>
                <AgxLabel
                  inverse
                  extraClasses={`pageListTextStyle ${
                    isActive ? 'active' : pageStatus
                  }`}
                >
                  {name}
                </AgxLabel>
              </AgxRow>
            </div>
          );
        }
      ) ?? <></>}
    </AgxColumn>
  );

  return (
    <div className="desktopSidebar" ref={elementRef}>
      <div className="sidebarHeader">
        <AgxLabel secondary medium={!isTablet} small={isTablet}>
          {formName(formTypeValue)}
        </AgxLabel>
        {twoLineAddress !== 'Address undefined' && (
          <AgxBodyText
            secondary
            extraLarge
            extraClasses={isTablet ? 'tabletHeadingStyle' : ''}
          >
            {addressLineOne},<br />
            {addressLineTwo}
          </AgxBodyText>
        )}
      </div>
      <div className="sidebarContent">{pagesListContent}</div>
    </div>
  );
};
