import React, { useState, useEffect } from 'react';
import {
  AgxControlledCheckbox,
  AgxCurrency,
  AgxTextInput,
  AgxRow,
  AgxButton,
  Images,
} from '@urbanx/agx-ui-components';
import '../MarketingPackages.scss';

const CustomMarketingItem = ({
  id,
  productName,
  pricePerUnit,
  selected,
  onSelectProductChange,
  deleteRow,
  validate,
}) => {
  const [customMarketingItem, setCustomMarketingItem] = useState(productName);
  const [price, setPrice] = useState(pricePerUnit);
  const [productSelected, setProductSelected] = useState(selected);

  useEffect(() => {
    setProductSelected(selected);
  }, [selected]);

  useEffect(() => {
    const number = Number(price);

    onSelectProductChange({
      id,
      productName: customMarketingItem,
      pricePerUnit: !Number.isNaN(price)
        ? number <= 0 || !number
          ? null
          : number
        : null,
      value: productSelected,
    });
  }, [productSelected, customMarketingItem, price]);

  const onValueChanged = value => {
    setProductSelected(value === true);
  };

  const onInputValueChange = ({ value }) => {
    const newValue = value?.toString()?.replace(/,/g, '');
    setPrice(
      !Number.isNaN(newValue) ? (newValue <= 0 ? null : newValue) : null
    );
  };

  return (
    <AgxRow
      fill
      spaceBetween
      centered
      mediumGap
      extraClasses="marketingPackageItem custom"
    >
      <AgxControlledCheckbox
        naked
        checked={productSelected ?? true}
        onValueChanged={onValueChanged}
      />
      <AgxTextInput
        id="customMarketingItem"
        onInputValueChange={({ value }) => {
          setCustomMarketingItem(value);
        }}
        noHeader
        stretch
        defaultValue={customMarketingItem}
        noOptionalLabel
        error={validate && customMarketingItem === ''}
        extraClasses="customItemLabel"
        hideErrorDescription
      />
      <AgxCurrency
        id={`marketingpackage-item-text-${productName}-${id}`}
        defaultValue={!price ? 0 : price}
        onInputValueChange={onInputValueChange}
        decimalPoints={2}
        noHeader
        small
        alignRight={true}
        placeholder="0.00"
        extraClasses="customItemPrice"
      />
      <AgxButton
        minimal
        rightIcon={<Images.CrossFill />}
        onClick={() =>
          deleteRow({
            id: id,
            productName: customMarketingItem,
            pricePerUnit: !Number.isNaN(price) ? Number(price) : null,
            value: productSelected,
          })
        }
      />
    </AgxRow>
  );
};

export default CustomMarketingItem;
