import { AustralianState, ParcelDetails } from '@urbanx/agx-ui-components';
import { AgxApi } from '../../helpers/http';
import {
  PropertySuggestionResponse,
  SuburbSuggestionResponse,
  TitleReferenceResponse,
} from './Types/types';

export const rpDataApi = (authToken: string) => new AgxApi('rpdata', authToken);

export const fetchTitleReferences = async (
  authToken: string,
  existingParcels: ParcelDetails[],
  propertyId?: string | null,
  state?: string | null
): Promise<ParcelDetails[] | null> => {
  try {
    const {
      data: { parcels },
    } = await rpDataApi(authToken).post<TitleReferenceResponse>(
      'GetTitleReference',
      {
        propertyId,
        state,
        parcels: existingParcels,
      }
    );

    return parcels;
  } catch (err: any) {
    console.error(err);
  }

  return null;
};

export const fetchAddresses = async (
  authToken: string,
  query: string,
  state: AustralianState,
  signal: AbortSignal
): Promise<PropertySuggestionResponse | null> => {
  const { data } = await rpDataApi(authToken).get<PropertySuggestionResponse>(
    'AddressSuggestion',
    {
      query,
      state,
    },
    signal
  );
  return data;
};

export const fetchPlanSuggestions = async (
  authToken: string,
  query: string,
  state: AustralianState,
  signal: AbortSignal
): Promise<PropertySuggestionResponse | null> => {
  const { data } = await rpDataApi(authToken).get<PropertySuggestionResponse>(
    'PlanSuggestion',
    {
      query,
      state,
    },
    signal
  );
  return data;
};

export const fetchSuburbSuggestions = async (
  authToken: string,
  query: string,
  state: AustralianState,
  signal: AbortSignal
): Promise<SuburbSuggestionResponse | null> => {
  const { data } = await rpDataApi(authToken).get<SuburbSuggestionResponse>(
    'SuburbSuggestion',
    {
      query,
      state,
    },
    signal
  );
  return data;
};
