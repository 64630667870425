import React, { useEffect, useState } from 'react';
const {
  AgxLabel,
  AgxRadio,
  AgxDatePicker,
} = require('@urbanx/agx-ui-components');

const RemovalTypes = {
  UponSettlement: 'UponSettlement',
  OnSpecificDate: 'OnSpecificDate',
  NoSignboardInstalled: 'NoSignboardInstalled',
};

const SignboardRemoval = ({ id, onValueChanged, defaultValue, validate }) => {
  const [removalState, setRemovalState] = useState({
    removalType: defaultValue?.removalType ?? RemovalTypes.UponSettlement,
    specificDate: defaultValue?.specificDate,
  });

  const { removalType, specificDate } = removalState;

  useEffect(() => {
    onValueChanged({ id, value: removalState });
  }, [removalState]);

  return (
    <div className="agxRadio">
      <AgxLabel medium>When would you like the signboard removed?</AgxLabel>
      <div className="agxRadio-group">
        <AgxRadio
          id={`${id}-uponSettlement`}
          label="Upon settlement"
          onCheckChanged={() =>
            setRemovalState({
              ...removalState,
              removalType: RemovalTypes.UponSettlement,
            })
          }
          checked={removalType === RemovalTypes.UponSettlement}
        />
        <AgxRadio
          id={`${id}-onSpecificDate`}
          label="On specific date"
          onCheckChanged={() =>
            setRemovalState({
              ...removalState,
              removalType: RemovalTypes.OnSpecificDate,
            })
          }
          checked={removalType === RemovalTypes.OnSpecificDate}
          subContent={
            removalType === RemovalTypes.OnSpecificDate && [
              <AgxDatePicker
                id={`${id}-specificDate`}
                key={`${id}-specificDate`}
                required
                validate={validate}
                onValueChanged={({ value }) => {
                  setRemovalState({
                    ...removalState,
                    specificDate: value,
                  });
                }}
                date
                defaultValue={specificDate}
                error={
                  validate && specificDate === '' ? 'Enter a date' : undefined
                }
              />,
            ]
          }
        />
        <AgxRadio
          id={`${id}-noSignboardInstalled`}
          label="No signboard installed"
          onCheckChanged={() =>
            setRemovalState({
              ...removalState,
              removalType: RemovalTypes.NoSignboardInstalled,
            })
          }
          checked={removalType === RemovalTypes.NoSignboardInstalled}
        />
      </div>
    </div>
  );
};

export default SignboardRemoval;
