import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { LoadingState } from 'utils/loadingState';
import { fetchAgents } from '../../agencies/agenciesReducer';

const ReviewAndSignPreloader = () => {
  const dispatch = useDispatch();
  const [, getAuthToken] = useAzureAuth();
  const loadingState = useSelector(state => state.agencies.agents.loadingState);

  useEffect(() => {
    if (loadingState !== LoadingState.NotLoaded) return;

    getAuthToken().then(async token => {
      dispatch(fetchAgents(token));
    });
  }, [loadingState]);

  return null;
};

export default ReviewAndSignPreloader;
