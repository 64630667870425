import { AgxSlideUpModal } from '@urbanx/agx-ui-components';
import CampaignInfoPanel from '../../CampaignInfoPanel/CampaignInfoPanel';

export const SelectedCampaignModal = ({
  selectedCampaign,
  currentTab,
  onClosed,
}) => {
  if (selectedCampaign == null) return null;

  return (
    <AgxSlideUpModal
      content={
        <CampaignInfoPanel
          campaign={selectedCampaign}
          currentTab={currentTab}
          mobile
        />
      }
      onClose={onClosed}
      closeButton
      useFullHeight
    />
  );
};
