import { useCallback } from 'react';
import { useAzureAuth } from './useAzureAuth';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { saveFormCampaign } from 'Api/Campaigns/campaignsApi';
import { markCampaignsAsStale } from '../features/campaigns/campaignsReducer';
import { setAndShowErrorToast } from '../store/config';

export const useFormSaver = () => {
  const [, getAuthToken] = useAzureAuth();
  const dispatch = useAppDispatch();
  const {
    campaignId,
    formId,
    selectedForm,
    formValues: formData,
    pageStatuses,
  } = useAppSelector(state => state.form);
  const { formType, state } = selectedForm || {};

  const saveFormData = useCallback(async () => {
    if (!getAuthToken) return;

    if (!campaignId || !formId || !formType || !state) {
      dispatch(setAndShowErrorToast('Failed to save form campaign'));
      return;
    }

    try {
      const authToken = await getAuthToken();

      if (!authToken) {
        dispatch(setAndShowErrorToast('Failed to save form campaign'));
        return;
      }

      const formSaved = await saveFormCampaign(
        authToken,
        campaignId,
        formId,
        formType,
        state,
        formData,
        pageStatuses
      );

      if (formSaved) {
        dispatch(markCampaignsAsStale());
      }
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
    }
  }, [campaignId, formId, formType, state, formData, pageStatuses]);

  return saveFormData;
};
