import React, { useState } from 'react';
import {
  AgxColumn,
  AgxCheckbox,
  AgxPriceRange,
  BackendPriceModel,
} from '@urbanx/agx-ui-components';

const toBeConfirmedLabel = 'To be confirmed';

interface PriceSpeficiationProps {
  id: string;
  label: string;
  subDescription?: string | null;
  onValueChanged: (value: {
    id: string;
    value: BackendPriceModel | null;
  }) => void;
  defaultValue: BackendPriceModel | null;
  showToBeConfirmed: boolean | null;
  maxPriceRangePercent: number | null;
  defaultUpperRangeSelected: boolean | null;
  required: boolean;
  validate: boolean;
}

const PriceSpecification = ({
  id,
  label,
  subDescription,
  onValueChanged,
  defaultValue = null,
  showToBeConfirmed = true,
  maxPriceRangePercent = null,
  defaultUpperRangeSelected = false,
  required,
  validate,
}: PriceSpeficiationProps) => {
  const [toBeConfirmed, setToBeConfirmed] = useState(
    defaultValue?.toBeConfirmed ?? false
  );

  return (
    <AgxColumn fill largeGap>
      <AgxColumn fill mediumGap>
        <AgxPriceRange
          id={id}
          label={label}
          subDescription={subDescription ?? undefined}
          disabled={toBeConfirmed}
          defaultValue={defaultValue?.priceRange ?? undefined}
          disabledText={toBeConfirmedLabel}
          onValueChanged={change => {
            onValueChanged({
              id,
              value: {
                ...defaultValue,
                toBeConfirmed: false,
                priceRange: change,
              },
            });
          }}
          maxPriceRangePercent={maxPriceRangePercent ?? undefined}
          defaultUpperRangeSelected={defaultUpperRangeSelected ?? undefined}
          required={required}
          validate={validate}
          noOptionalLabel={true}
        />
        {showToBeConfirmed && (
          <AgxCheckbox
            id={`${id}Chkbox`}
            onValueChanged={({ value }) => {
              setToBeConfirmed(value);
              onValueChanged({
                id,
                value: {
                  ...defaultValue,
                  toBeConfirmed: value,
                  priceRange: null,
                },
              });
            }}
            subLabel={`"${toBeConfirmedLabel}"`}
            stretch
            naked
            defaultValue={toBeConfirmed}
          />
        )}
      </AgxColumn>
    </AgxColumn>
  );
};

export default PriceSpecification;
