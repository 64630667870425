import { useEffect, MutableRefObject } from 'react';

const useOutsideAlerter = (
  ref: MutableRefObject<null | HTMLElement>,
  onTriggerOutside: (status: string) => void
): void => {
  useEffect(() => {
    /**
     * Trigger if clicked on outside of element
     */
    const handleClickOutside = (event: MouseEvent | KeyboardEvent) => {
      if (
        (ref.current && !ref.current.contains(event.target as Node)) ||
        (event instanceof KeyboardEvent && event.keyCode === 27)
      ) {
        onTriggerOutside('outside');
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleClickOutside); // Triggering when Escape key is pressed
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);
    };
  }, [ref, onTriggerOutside]);
};

export default useOutsideAlerter;
