import { useCallback, useEffect } from 'react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAzureAuth } from '../../hooks/useAzureAuth';
import { rexApi } from 'Api/Rex/rexApi';
import { setAndShowErrorToast } from '../../store/config';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { LoadingState } from '../../utils/loadingState';
import { TopPerformersModel } from '../../types/Reporting';

export interface TopPerformersState {
  stats: TopPerformersModel | null;
  loadingState: LoadingState;
}

const initialState: TopPerformersState = {
  stats: null,
  loadingState: LoadingState.NotLoaded,
};

const slice = createSlice({
  name: 'topPerformers',
  initialState,
  reducers: {
    topPerformersStartedLoading: (state: TopPerformersState) => {
      return {
        ...state,
        stats: initialState.stats,
        loadingState: LoadingState.Loading,
      };
    },
    topPerformersLoaded: (
      state: TopPerformersState,
      action: PayloadAction<TopPerformersModel | null>
    ) => {
      const stats = action.payload;
      return {
        ...state,
        stats,
        loadingState: LoadingState.Loaded,
      };
    },
  },
});

export default slice.reducer;

export const { topPerformersStartedLoading, topPerformersLoaded } =
  slice.actions;

export const useTopPerformers = () => {
  const dispatch = useAppDispatch();
  const [userAccount, getAuthToken] = useAzureAuth();

  const topPerformersState: TopPerformersState = useAppSelector(
    state => state.topPerformers
  );

  const loadTopPerformers = useCallback(async () => {
    if (!getAuthToken) return;

    try {
      dispatch(topPerformersStartedLoading());

      const token = await getAuthToken();

      const topPerformersResponse = await rexApi(
        token || ''
      ).get<TopPerformersModel>('GetTopPerformers');

      const topPerformers = topPerformersResponse.data;

      dispatch(
        topPerformersLoaded({
          ...topPerformers,
        })
      );
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(topPerformersLoaded(null));
      return err.message;
    }
  }, [dispatch, getAuthToken]);

  useEffect(() => {
    if (!userAccount) return;

    if (topPerformersState.loadingState === LoadingState.NotLoaded) {
      loadTopPerformers();
    }
  }, [topPerformersState.loadingState, userAccount, loadTopPerformers]);

  const loading = topPerformersState.loadingState === LoadingState.Loading;

  const errorRetrievingTopPerformers =
    topPerformersState.loadingState === LoadingState.Failed;

  return {
    ...topPerformersState,
    loading,
    errorRetrievingTopPerformers,
    loadTopPerformers,
  };
};
