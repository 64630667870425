import React from 'react';
import ContentLoader from 'react-content-loader';

export const CampaignInfoPanelLoader = () => {
  return (
    <ContentLoader viewBox="0 0 571 764">
      <rect x="28" y="28" rx="5" ry="5" width="515" height="250" />
      <rect x="28" y="306" rx="5" ry="5" width="120" height="12" />
      <rect x="423" y="306" rx="5" ry="5" width="120" height="12" />
      <rect x="28" y="346" rx="5" ry="5" width="320" height="32" />
    </ContentLoader>
  );
};
