import { useEffect } from 'react';
import LogRocket from 'logrocket';
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { Outlet } from 'react-router-dom';
import { useAzureAuth } from 'hooks/useAzureAuth';
import usePrevious from 'hooks/usePrevious';
import { useFetchAgencyInfo } from 'features/agencies/agenciesReducer';
import { SetupNotificationsLayout } from './SetupNotificationsLayout';

export const AuthenticatedLayout = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const previousInProgress = usePrevious(inProgress);

  const [userAccount, getAuthToken] = useAzureAuth();

  useFetchAgencyInfo();

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;
    if (previousInProgress === InteractionStatus.HandleRedirect) return;
    if (isAuthenticated) return;

    getAuthToken();
  }, [isAuthenticated, inProgress, previousInProgress, getAuthToken]);

  useEffect(() => {
    if (!userAccount) return;

    const { fullName, agent, agency } = userAccount;

    if (!agent || !fullName || !agency) return;

    LogRocket.identify(agent, {
      name: fullName,
      email: agent,
      agency,
    });
  }, [userAccount]);

  return (
    <AuthenticatedTemplate>
      <SetupNotificationsLayout>
        {isAuthenticated && <Outlet />}
      </SetupNotificationsLayout>
    </AuthenticatedTemplate>
  );
};
