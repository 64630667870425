import { AgxBodyText, AgxColumn } from '@urbanx/agx-ui-components';
import { UpcomingSettlement } from '@urbanx/agx-ui-components/dist/cjs/types/components/portal/Reporting/types';
import moment from 'moment';
import { formatCurrency } from 'utils/formatNumber';

interface UpcomingSettlementRowProps {
  settlement: UpcomingSettlement;
}

const UpcomingSettlementRow = ({ settlement }: UpcomingSettlementRowProps) => {
  const {
    commission,
    postCode,
    settlementDate,
    streetName,
    streetNumber,
    suburb,
    unitNumber,
  } = settlement;

  const unitNumberText = unitNumber ? `${unitNumber} / ` : '';

  const addressLineOne =
    [unitNumberText, streetNumber, streetName].filter(x => !!x).join(' ') + ',';
  const addressLineTwo = [suburb, postCode].filter(x => !!x).join(' ');

  return (
    <div className="upcomingSettlementRow">
      <AgxColumn extraClasses="upcomingSettlement property" centerJustified>
        <AgxBodyText small>
          {addressLineOne}
          <br />
          {addressLineTwo}
        </AgxBodyText>
      </AgxColumn>
      <AgxColumn extraClasses="upcomingSettlement settlement" centerJustified>
        <AgxBodyText small>
          {moment(settlementDate).format('DD MMM')}
        </AgxBodyText>
      </AgxColumn>
      <AgxColumn extraClasses="upcomingSettlement commission" centerJustified>
        <AgxBodyText small>+{formatCurrency(commission ?? 0)}</AgxBodyText>
      </AgxColumn>
    </div>
  );
};

export default UpcomingSettlementRow;
