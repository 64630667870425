import { formatCurrency } from 'utils/formatNumber';

export const getFilterText = ({
  fromPrice,
  toPrice,
  minBedrooms,
  maxBedrooms,
  minBathrooms,
  maxBathrooms,
  minCarSpaces,
  maxCarSpaces,
}) => {
  let priceText = '';
  let bedroomsText = '';
  let bathroomsText = '';
  let carSpacesText = '';
  let hasSubsequentText = false;

  if (minCarSpaces != null || maxCarSpaces != null) {
    carSpacesText = `${minCarSpaces != null ? minCarSpaces.toString() : ''}${minCarSpaces != null && maxCarSpaces != null ? '-' : ''}${maxCarSpaces != null ? maxCarSpaces.toString() : ''} vehicles`;
    hasSubsequentText = true;
  }

  if (minBathrooms != null || maxBathrooms != null) {
    bathroomsText = `${minBathrooms != null ? minBathrooms.toString() : ''}${minBathrooms != null && maxBathrooms != null ? '-' : ''}${maxBathrooms != null ? maxBathrooms.toString() : ''} baths${hasSubsequentText ? ', ' : ''}`;
    hasSubsequentText = true;
  }

  if (minBedrooms != null || maxBedrooms != null) {
    bedroomsText = `${minBedrooms != null ? minBedrooms.toString() : ''}${minBedrooms != null && maxBedrooms != null ? '-' : ''}${maxBedrooms != null ? maxBedrooms.toString() : ''} beds${hasSubsequentText ? ', ' : ''}`;
    hasSubsequentText = true;
  }

  if (fromPrice != null || toPrice != null) {
    priceText = `${formatCurrency(fromPrice, 0)}-${formatCurrency(toPrice, 0)}${hasSubsequentText ? ', ' : ''}`;
  }

  const filterText = `${priceText}${bedroomsText}${bathroomsText}${carSpacesText}`;

  return filterText != null && filterText !== ''
    ? filterText
    : 'Filter results';
};
