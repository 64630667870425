import { useCallback, useEffect } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { rpDataHasAccess } from 'features/rpData/services/rpDataHasAccess';
import { docuSignHasAccess } from 'Api/DocuSign/docusignApi';
import { ExternalIntegrations } from 'Api/Agencies/Types/Agencies';

interface ValidateAccessLayoutProps {
  children: React.ReactNode;
}

const ValidateAccessLayout = (props: ValidateAccessLayoutProps) => {
  const [userAccount, getAuthToken] = useAzureAuth();
  const externalIntegrations = useAppSelector(
    state => state.agencies.agencyInfo.info?.externalIntegrations
  );

  const fetchRpDataAuthorizeHasAccess = useCallback(
    async (authToken: string): Promise<URL | undefined> => {
      if (!externalIntegrations?.includes(ExternalIntegrations.RPData)) return;

      const rpDataHasAccessResponse = await rpDataHasAccess(authToken);

      if (
        rpDataHasAccessResponse &&
        !rpDataHasAccessResponse.hasAccess &&
        rpDataHasAccessResponse.authorizeUrl
      ) {
        return rpDataHasAccessResponse.authorizeUrl;
      }
    },
    [externalIntegrations]
  );

  const fetchDocuSignAuthorizeHasAccess = useCallback(
    async (authToken: string): Promise<URL | undefined> => {
      if (!externalIntegrations?.includes(ExternalIntegrations.DocuSign))
        return;

      const docuSignHasAccessResponse = await docuSignHasAccess(authToken);

      if (
        docuSignHasAccessResponse &&
        !docuSignHasAccessResponse.hasAccess &&
        docuSignHasAccessResponse.authorizeUrl
      ) {
        return docuSignHasAccessResponse.authorizeUrl;
      }
    },
    [externalIntegrations]
  );

  const validateApplicationAccess = useCallback(async () => {
    if (!externalIntegrations || !getAuthToken) return;

    const authToken = await getAuthToken();

    if (!authToken) return;

    const [rpDataAuthorizeUrl, docuSignAuthorizeUrl] = await Promise.all([
      fetchRpDataAuthorizeHasAccess(authToken),
      fetchDocuSignAuthorizeHasAccess(authToken),
    ]);

    if (rpDataAuthorizeUrl) window.location.replace(rpDataAuthorizeUrl);
    else if (docuSignAuthorizeUrl)
      window.location.replace(docuSignAuthorizeUrl);
  }, [externalIntegrations]);

  useEffect(() => {
    if (!userAccount) return;

    validateApplicationAccess();
  }, [userAccount, externalIntegrations]);

  return props.children;
};

export default ValidateAccessLayout;
