import React, { useEffect, useState } from 'react';
import SaleDetails from '../SaleDetails/SaleDetails';
import LaunchDetails from '../LaunchDetails/LaunchDetails';
import { SaleType } from '../SaleDetails/SaleDetails';
const SaleAndLaunchDetails = ({
  id,
  propertyAddress,
  onValueChanged,
  defaultValue,
  validate,
}) => {
  const [saleAndLaunchDetailsState, setSaleAndLaunchDetailsState] = useState({
    saleDetails: defaultValue?.saleDetails ?? null,
    launchDetails: defaultValue?.launchDetails ?? null,
  });

  useEffect(() => {
    onValueChanged({
      id,
      value: {
        ...saleAndLaunchDetailsState,
      },
    });
  }, [saleAndLaunchDetailsState]);

  const shouldRenderLaunchDetails =
    saleAndLaunchDetailsState.saleDetails?.saleType !== SaleType.OffMarket;

  return (
    <div className="agxRadio">
      <SaleDetails
        id={id}
        key="saleDetails"
        propertyAddress={propertyAddress}
        onValueChanged={({ value }) =>
          setSaleAndLaunchDetailsState({
            ...saleAndLaunchDetailsState,
            saleDetails: value,
            launchDetails:
              value?.saleType !== SaleType.OffMarket
                ? saleAndLaunchDetailsState.launchDetails
                : null,
          })
        }
        defaultValue={defaultValue.saleDetails}
      />
      {shouldRenderLaunchDetails && (
        <LaunchDetails
          id={id}
          key="launchDetails"
          onValueChanged={({ value }) =>
            setSaleAndLaunchDetailsState({
              ...saleAndLaunchDetailsState,
              launchDetails: value,
            })
          }
          defaultValue={defaultValue.launchDetails}
          validate={validate && shouldRenderLaunchDetails}
        />
      )}
    </div>
  );
};

export default SaleAndLaunchDetails;
