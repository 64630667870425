import { useMemo } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  EntityTypes,
  ReportingDashboardState,
} from '../reportingDashboardReducer';
import {
  LeaderBoardStatsModel,
  TimePeriodType,
} from '../../../types/Reporting';

export const useLeaderboardStatistics = (
  entityType: string | null,
  timePeriod: string | null
): LeaderBoardStatsModel | undefined => {
  const reportingDashboardState: ReportingDashboardState = useAppSelector(
    state => state.reportingDashboard
  );
  const { stats } = reportingDashboardState;

  const leaderboardStats = useMemo(() => {
    if (entityType == null || timePeriod == null) return undefined;

    let entityLeaderboardStats;

    switch (entityType) {
      // Individual/Team
      case EntityTypes[0].value:
        entityLeaderboardStats =
          stats?.leaderBoardStatistics.personalLeaderBoardStatistics;
        break;
      // Agency
      case EntityTypes[1].value:
        entityLeaderboardStats =
          stats?.leaderBoardStatistics.agencyLeaderBoardStatistics;
        break;
      default:
        return undefined;
    }

    switch (timePeriod) {
      case TimePeriodType.Month:
        return entityLeaderboardStats?.leaderBoardMonthlyStats;
      case TimePeriodType.Quarter:
        return entityLeaderboardStats?.leaderBoardQuarterlyStats;
      case TimePeriodType.Annual:
        return entityLeaderboardStats?.leaderBoardAnnualStats;
      default:
        return undefined;
    }
  }, [entityType, stats, timePeriod]);

  if (entityType == null || timePeriod == null) return undefined;

  return leaderboardStats;
};
