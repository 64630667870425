import { LegalDescription, PropertyDetails } from '@urbanx/agx-ui-components';
import { useAppSelector } from '../hooks/useAppSelector';
import { IconDetails } from '../features/elements/PropertyIcons/types';

const useFormPropertyDetailsSelector = (): Partial<PropertyDetails> => {
  const legalDescription = useAppSelector<LegalDescription | null>(
    state => state.form.formValues.LegalDescription
  );

  const propertyIcons = useAppSelector<IconDetails | null>(
    state => state.form.formValues.PropertyIcons
  );

  const propertyDetailsFromFormConfig = useAppSelector<PropertyDetails | null>(
    state => state.form.propertyDetails
  );

  return {
    ...propertyDetailsFromFormConfig,
    ...legalDescription,
    ...propertyIcons,
  };
};

export default useFormPropertyDetailsSelector;
