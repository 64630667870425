import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '@urbanx/agx-ui-components';
import { useAzureAuth } from './useAzureAuth';
import { useAppDispatch } from './useAppDispatch';
import { loadFormCampaign } from 'Api/Campaigns/campaignsApi';
import { setAndShowErrorToast } from '../store/config';
import { loadFormConfig } from '../features/form/formReducer';

export const useFormLoader = () => {
  const [, getAuthToken] = useAzureAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loadForm = useCallback(
    async (campaignId: string, formId: string) => {
      if (!getAuthToken) return;

      const authToken = await getAuthToken();

      if (!authToken) {
        dispatch(setAndShowErrorToast('Failed to load form'));
        return;
      }

      const form = await loadFormCampaign(authToken, campaignId, formId);

      if (!form) {
        dispatch(setAndShowErrorToast('Failed to load form'));
        return;
      }

      dispatch(loadFormConfig(form));
      navigate('/form');
    },
    [getAuthToken, dispatch, navigate]
  );

  const loadFormWithConfig = useCallback(
    async (form: Form) => {
      dispatch(loadFormConfig(form));
      navigate('/form');
    },
    [dispatch, navigate]
  );

  return {
    loadForm,
    loadFormWithConfig,
  };
};
