import { AgxApi } from '../../helpers/http';
import { HasAccessResponse, StoreAccessCodeResponse } from './Types/types';

export const docusignApi = (authToken: string) =>
  new AgxApi('docusign', authToken);

export const resendEnvelope = async (
  authToken: string,
  envelopeId: string,
  recipientId: string
) => {
  try {
    const { data } = await docusignApi(authToken).post<boolean>(
      'ResendEnvelope',
      {
        envelopeId,
        recipientId,
      }
    );

    return data;
  } catch (err) {
    console.error(err);
  }

  return false;
};

export const docuSignHasAccess = async (
  authToken: string
): Promise<HasAccessResponse | null> => {
  try {
    const { data } =
      await docusignApi(authToken).get<HasAccessResponse>('HasAccess');
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const storeDocuSignAccessCode = async (
  authToken: string,
  code: string
): Promise<StoreAccessCodeResponse | null> => {
  try {
    const { data } = await docusignApi(authToken).post<StoreAccessCodeResponse>(
      `StoreAccessCode?code=${code}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
