import { ReactNode } from 'react';
import { ElementType, FormPage } from '@urbanx/agx-ui-components';
import MarketingPackagesPreloader from '../features/elements/MarketingPackages/MarketingPackagesPreloader';
import PoolSafetyCertificatePreloader from '../features/elements/PoolSafetyCertificate/PoolSafetyCertificatePreloader';
import ListingAgentSelectorPreloader from '../features/elements/ListingAgentSelector/ListingAgentSelectorPreloader';
import ReviewAndSignPreloader from '../features/elements/ReviewAndSign/ReviewAndSignPreloader';
import LegalDescriptionPreloader from '../features/elements/LegalDescription/LegalDescriptionPreloader';
import ComparativeMarketAnalysisPreloader from '../features/elements/ComparativeMarketAnalysis/ComparativeMarketAnalysisPreloader';

export const usePreloadRenderer = () => {
  const renderPreloader = (pages: FormPage[]) => {
    const elementTypes = pages.reduce(
      (prev: ElementType[], curr: FormPage): ElementType[] => {
        const { elements: currElements } = curr;
        const currElementTypes = currElements?.map(
          element => element.elementType
        );

        return [...prev, ...(currElementTypes ?? [])].filter(
          elementType => elementType != null
        );
      },
      []
    );

    return elementTypes
      .map((elementType: ElementType): ReactNode => {
        switch (elementType) {
          case ElementType.ComparativeMarketAnalysis:
            return <ComparativeMarketAnalysisPreloader key={elementType} />;

          case ElementType.MarketingPackage:
            return <MarketingPackagesPreloader key={elementType} />;

          case ElementType.PoolSafetyCertificate:
            return <PoolSafetyCertificatePreloader key={elementType} />;

          case ElementType.VendorBuyerDetails:
          case ElementType.ListingAgentSelector:
          case ElementType.SecondaryAgentCommissionSplit:
            return <ListingAgentSelectorPreloader key={elementType} />;

          case ElementType.ReviewAndSign:
            return <ReviewAndSignPreloader key={elementType} />;

          case ElementType.LegalDescription:
            return <LegalDescriptionPreloader key={elementType} />;

          default:
            return null;
        }
      })
      .filter((element: ReactNode) => element != null);
  };

  return renderPreloader;
};
