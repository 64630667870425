import { formatCurrency } from 'utils/formatNumber';
import {
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxCaption,
  Images,
} from '@urbanx/agx-ui-components';
import './CMAPropertySelectedCard.scss';
import moment from 'moment';

export const CMAPropertySelectedCard = ({ property, onCloseSelectedCard }) => {
  const {
    propertyId,
    address,
    bedrooms,
    bathrooms,
    carSpaces,
    landArea,
    price,
    settlementDate,
  } = property;

  const timeAgo =
    settlementDate !== null ? moment(settlementDate).fromNow(true) : '';

  return (
    <AgxColumn
      extraClasses="cmaPropertySelectedCard"
      data-testid={propertyId}
      smallGap
    >
      <AgxRow spaceBetween>
        <AgxBodyText small ellipsisOverflow extraClasses="address">
          {address}
        </AgxBodyText>
        <Images.CloseOutline onClick={() => onCloseSelectedCard(propertyId)} />
      </AgxRow>
      <AgxRow mediumGap>
        <AgxRow smallGap centered>
          <Images.Bed />
          <AgxCaption small>{bedrooms}</AgxCaption>
        </AgxRow>
        <AgxRow smallGap centered>
          <Images.Bath />
          <AgxCaption small>{bathrooms}</AgxCaption>
        </AgxRow>
        <AgxRow smallGap centered>
          <Images.Car />
          <AgxCaption small>{carSpaces}</AgxCaption>
        </AgxRow>
        <AgxRow smallGap centered>
          <Images.Area />
          <AgxCaption small>{landArea}m²</AgxCaption>
        </AgxRow>
      </AgxRow>
      <AgxRow largeGap spaceBetween>
        <AgxBodyText small>{formatCurrency(price, 0)}</AgxBodyText>
        <AgxBodyText small>{timeAgo}</AgxBodyText>
      </AgxRow>
    </AgxColumn>
  );
};
