import { AUTH } from '../../../constants/AUTH';

const { AUTHORITY_DOMAIN, INSTANCE, DOMAIN, SIGNIN_SIGNUP_POLICY_ID } = AUTH;

export const b2cPolicies = {
  names: {
    signUpSignIn: SIGNIN_SIGNUP_POLICY_ID,
    // forgotPassword: "B2C_1A_signup_signin",
    // editProfile: "B2C_1A_signup_signin",
  },
  authorities: {
    signUpSignIn: {
      authority: `${INSTANCE}/${DOMAIN}/${SIGNIN_SIGNUP_POLICY_ID}`,
    },
    // forgotPassword: {
    //   authority:
    //     "https://agentxplatformdev.b2clogin.com/agentxplatformdev.onmicrosoft.com/B2C_1A_signup_signin",
    // },
    // editProfile: {
    //   authority:
    //     "https://agentxplatformdev.b2clogin.com/agentxplatformdev.onmicrosoft.com/B2C_1A_signup_signin",
    // },
  },
  authorityDomain: AUTHORITY_DOMAIN,
};
