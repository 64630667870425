import { ReactNode } from 'react';
import { AgxDivider } from '@urbanx/agx-ui-components';

export const addDividersBetweenElements = (
  elements?: ReactNode[] | null,
  expanded = false,
  addDividerToEnd = false
) => {
  if (elements == null || !elements || elements.length <= 1) {
    return elements && addDividerToEnd
      ? [...elements, <AgxDivider key={`divider-end`} expanded={expanded} />]
      : elements;
  }

  const fullArray = elements.flatMap((value, index, array) => {
    if (index === array.length - 1) return value;
    return [value, <AgxDivider key={`divider-${index}`} expanded={expanded} />];
  });

  if (addDividerToEnd) {
    fullArray.push(<AgxDivider key={`divider-end`} expanded={expanded} />);
  }

  return fullArray;
};

export function findLastIndex(
  array: any[],
  predicate: (value: any, index: number, obj: any[]) => boolean
) {
  if (!array) return -1;

  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i], i, array)) {
      return i;
    }
  }
  return -1; // Return -1 if no element is found
}

export function deleteIds(obj: any | any[]): any {
  if (Array.isArray(obj)) {
    return obj.map(item => deleteIds(item));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj: any = {};
    for (const key in obj) {
      if (key !== 'id' && key !== 'expandedSubpoints') {
        newObj[key] = deleteIds(obj[key]);
      }
    }
    return newObj;
  }
  return obj;
}
