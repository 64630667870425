import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { initStore } from 'store/store';
import { http } from './helpers/http';
import { v4 as uuidv4 } from 'uuid';
import App from './App';
import './index.scss';

// Store a session ID to be passed to the APIs as an x-session-id header.
sessionStorage.setItem(http.sessionName, uuidv4());

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

const store = initStore();

// Expose store when run in Cypress
if ((window as any).Cypress) {
  (window as any).store = store;
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
