import { formatCurrency } from 'utils/formatNumber';
import { CircularSelector } from './CircularSelector';
import clsx from 'clsx';
import {
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxCaption,
  Images,
} from '@urbanx/agx-ui-components';
import { Breakpoints, ScreenSize } from 'utils/screen';
import defaultImage from './assets/icons/house-placeholder.png';
import './CMAPropertyImageCard.scss';

export const CMAPropertyImageCard = ({
  property,
  timeAgo,
  onPropertyImageCheck,
  selectedCmaProperty,
}) => {
  const isSelected = selectedCmaProperty.some(
    selectedProperty => selectedProperty.propertyId === property.propertyId
  );

  const cardClasses = clsx('agxPropertyCard', isSelected && 'selected');

  const wrapperClickEvent =
    ScreenSize() === Breakpoints.Desktop
      ? () => onPropertyImageCheck(property)
      : null;

  return (
    <div
      className={cardClasses}
      data-testid={property.propertyId}
      onClick={wrapperClickEvent}
    >
      <div
        className="propertyImageWrapper"
        style={{
          backgroundImage: `url('${property.thumbnailUrl ?? defaultImage}')`,
        }}
      ></div>
      <AgxColumn smallGap fill spaceBetween>
        <AgxBodyText small>{property.address}</AgxBodyText>
        <AgxRow mediumGap centered>
          <AgxRow smallGap centered>
            <Images.Bed />
            <AgxCaption small>{property.bedrooms}</AgxCaption>
          </AgxRow>
          <AgxRow smallGap centered>
            <Images.Bath />
            <AgxCaption small>{property.bathrooms}</AgxCaption>
          </AgxRow>
          <AgxRow smallGap centered>
            <Images.Car />
            <AgxCaption small>{property.carSpaces}</AgxCaption>
          </AgxRow>
          <AgxRow smallGap centered>
            <Images.Area />
            <AgxCaption small>{property.landArea}m²</AgxCaption>
          </AgxRow>
        </AgxRow>
        <AgxRow largeGap>
          <AgxBodyText small>{formatCurrency(property.price, 0)}</AgxBodyText>
          <AgxBodyText small>{timeAgo}</AgxBodyText>
          <AgxBodyText small>
            {property.distanceFromProperty}km
            <span className="cmaDistancePrepend"> away</span>
          </AgxBodyText>
        </AgxRow>
      </AgxColumn>

      <AgxColumn centered centerJustified>
        <CircularSelector
          onClicked={() => onPropertyImageCheck(property)}
          selected={isSelected}
        />
      </AgxColumn>
    </div>
  );
};
