import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../utils/loadingState';
import { realEstateApi } from 'Api/RealEstate/realEstateApi';
import { AppDispatch } from '../../store/store';
import {
  GetPricingResponse,
  GetRealEstatePricingModel,
  ProductPricingModel,
} from 'Api/RealEstate/Types/types';

interface RealEstateReducerState {
  loadingState: LoadingState;
  contract: ProductPricingModel | null;
  products: ProductPricingModel[];
  key: string | null;
}

const initialState = {
  loadingState: LoadingState.NotLoaded,
  contract: null,
  products: [],
  key: null,
} satisfies RealEstateReducerState as RealEstateReducerState;

const slice = createSlice({
  name: 'realEstate',
  initialState,
  reducers: {
    clearLoadingState: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.NotLoaded,
      };
    },
    startLoadingRealEstate: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Loading,
      };
    },
    realEstateLoaded: (
      state,
      action: PayloadAction<{
        products: ProductPricingModel[];
        contract: ProductPricingModel | null;
        key: string;
      }>
    ) => {
      const { products, contract, key } = action.payload;

      return {
        ...state,
        products: products,
        contract: contract,
        key,
        loadingState: LoadingState.Loaded,
      };
    },
    noProductsFound: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Loaded,
      };
    },
    errorLoadingRealEstate: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Failed,
      };
    },
  },
});

export default slice.reducer;

const {
  clearLoadingState,
  startLoadingRealEstate,
  realEstateLoaded,
  errorLoadingRealEstate,
} = slice.actions;

export const reloadRealEstate = () => async (dispatch: AppDispatch) => {
  dispatch(clearLoadingState());
};

export const fetchRealEstate =
  (authToken: string, request: GetRealEstatePricingModel) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingRealEstate());
      const {
        data: { products, contract },
      } = await realEstateApi(authToken).get<GetPricingResponse>(
        'GetPricing',
        request
      );

      const key = `${request.suburb}|${request.state}|${request.postcode}|${request.listingType}`;
      dispatch(realEstateLoaded({ products, contract, key }));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(errorLoadingRealEstate());
      return err.message;
    }
  };
