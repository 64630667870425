import React from 'react';
import {
  AgxCaption,
  AgxColumn,
  AgxLabel,
  AgxRow,
  StakeholderType,
} from '@urbanx/agx-ui-components';

interface Signatory {
  id?: string;
  recipientId?: string;
  name?: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface PreviewSignatoriesProps {
  buyers?: Signatory[];
  buyerSolicitor?: Signatory;
  vendors?: Signatory[];
  vendorSolicitor?: Signatory;
  stakeholders?: StakeholderType[];
}

const PreviewSignatories: React.FC<PreviewSignatoriesProps> = ({
  buyers = [],
  buyerSolicitor,
  vendors = [],
  vendorSolicitor,
  stakeholders = [],
}) => {
  const renderSignatory = (signatory: Signatory, stakeholderName: string) => {
    const signatoryName =
      signatory.name ?? `${signatory.firstName} ${signatory.lastName}`;
    return (
      <AgxRow
        key={`${signatory.id ?? signatory.recipientId}-${stakeholderName}`}
        veryLargeGap
        spaceBetween
        extraClasses="borderBottom signatory-content"
      >
        <AgxColumn extraLargePadding>
          <AgxLabel>{signatoryName}</AgxLabel>
          <AgxLabel medium colour="neutral-grey-700">
            {signatory.email}
          </AgxLabel>
        </AgxColumn>
        <AgxColumn centerJustified extraClasses="fitContent">
          <AgxCaption colour="neutral-grey-700">{stakeholderName}</AgxCaption>
        </AgxColumn>
      </AgxRow>
    );
  };

  return (
    <AgxColumn fill extraClasses="signatory-row borderTop">
      {stakeholders.map(stakeholder => {
        if (stakeholder === StakeholderType.Buyer) {
          return (
            buyers &&
            buyers.length > 0 &&
            buyers.map((signatory, index) =>
              renderSignatory(signatory, `Buyer ${index + 1}`)
            )
          );
        }
        if (stakeholder === StakeholderType.BuyerSolicitor) {
          return (
            buyerSolicitor &&
            renderSignatory(buyerSolicitor, "Buyer's Solicitor")
          );
        }
        if (stakeholder === StakeholderType.Vendor) {
          return (
            vendors &&
            vendors.length > 0 &&
            vendors.map((signatory, index) =>
              renderSignatory(signatory, `Vendor ${index + 1}`)
            )
          );
        }
        if (stakeholder === StakeholderType.VendorSolicitor) {
          return (
            vendorSolicitor &&
            renderSignatory(vendorSolicitor, "Vendor's Solicitor")
          );
        }
        return null;
      })}
    </AgxColumn>
  );
};

export default PreviewSignatories;
