import {
  CampaignDetailModel,
  CampaignCompletionState,
} from '@urbanx/agx-ui-components';

export interface CampaignInfoProps {
  campaign: CampaignDetailModel;
  currentTab: string;
  mobile?: boolean;
}

export enum CampaignStageAction {
  Restore = 'Restore',
  RevertToCurrent = 'RevertToCurrent',
  MarkAsUnderContract = 'MarkAsUnderContract',
  MarkAsSold = 'MarkAsSold',
  Archive = 'Archive',
  Withdraw = 'Withdraw',
}

export interface CampaignActionRequest {
  campaignId: string;
  campaignAction: CampaignStageAction;
}

export interface CampaignActionResponse {
  campaignId: string;
  actionSuccessful: boolean;
  completionState: CampaignCompletionState;
}
