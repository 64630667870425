import { AgxLabel, AgxRow } from '@urbanx/agx-ui-components';
import clsx from 'clsx';
import './SegmentedPicker.scss';

const SegmentedPicker = ({ segments }) => {
  return (
    <AgxRow extraClasses="segmentedPicker">
      {segments.map((segment, idx) => {
        const { title, mobileTitle, isSelected, onSelected } = segment;

        let mobileTitleText = mobileTitle ?? title;

        return (
          <Segment
            key={idx}
            title={title}
            mobileTitle={mobileTitleText}
            isSelected={isSelected}
            onClick={onSelected}
          />
        );
      })}
    </AgxRow>
  );
};

const Segment = ({ title, mobileTitle, isSelected, onClick }) => {
  const classes = clsx('segmentPicker', isSelected && 'selected');

  return (
    <div className={classes} onClick={onClick}>
      <AgxLabel medium extraClasses={classes}>
        <span className="title">{title}</span>
        <span className="mobileTitle">{mobileTitle}</span>
      </AgxLabel>
    </div>
  );
};

export default SegmentedPicker;
