import {
  CampaignDetailModel,
  ContractDetailModel,
  EnvelopeSubmissionType,
} from '@urbanx/agx-ui-components';
import { Tabs } from 'types/Tabs';
import ResumeContractRequest from './ResumeContractRequest';
import ContractRequestSigningPanel from './ContractRequestSigningPanel';

interface ContractRequestInfoPanelProps {
  campaign: CampaignDetailModel;
  contract: ContractDetailModel;
  currentTab: Tabs;
  mobile?: boolean;
}

const ContractRequestInfoPanel = ({
  campaign,
  contract,
  currentTab,
  mobile = false,
}: ContractRequestInfoPanelProps) => {
  if (
    [EnvelopeSubmissionType.DocuSign, EnvelopeSubmissionType.Email].includes(
      contract.envelopeSubmission?.type ?? EnvelopeSubmissionType.Manual
    )
  ) {
    return (
      <ContractRequestSigningPanel
        campaign={campaign}
        currentTab={currentTab}
        mobile={mobile}
      />
    );
  } else {
    return (
      <ResumeContractRequest
        campaign={campaign}
        contract={contract}
        currentTab={currentTab}
        mobile={mobile}
      />
    );
  }
};

export default ContractRequestInfoPanel;
