import { AgxColumn, AgxInputText, AgxLabel } from '@urbanx/agx-ui-components';
import useFormAddressSelector from 'selectors/useFormAddressSelector';
import { useMemo } from 'react';
import { cleanTwoLineAddress } from 'utils/formatAddress';

const VendorDetailsDisplay = ({ defaultValue: vendorNames }) => {
  const address = useFormAddressSelector();

  const [addressLineOne, addressLineTwo] = useMemo(
    () => cleanTwoLineAddress(address),
    [address, cleanTwoLineAddress]
  );

  const vendorNamesDisplayText = useMemo(() => {
    return vendorNames?.join(', ') ?? '';
  }, [vendorNames]);

  return (
    <AgxColumn extraLargeGap fill>
      <AgxColumn fill mediumGap>
        <AgxLabel large>Property Address</AgxLabel>
        <AgxInputText>
          {addressLineOne}
          <br />
          {addressLineTwo}
        </AgxInputText>
      </AgxColumn>
      <AgxColumn fill mediumGap>
        <AgxLabel large>Vendors</AgxLabel>
        <AgxInputText>{vendorNamesDisplayText}</AgxInputText>
      </AgxColumn>
    </AgxColumn>
  );
};

export default VendorDetailsDisplay;
