import React from 'react';
import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxDivider,
  AgxHeader,
  AgxSlideUpModal,
  Images,
} from '@urbanx/agx-ui-components';
import { Breakpoints, ScreenSize } from 'utils/screen';

interface ErrorModalProps {
  errorList: string[];
  setShowModal: (showModal: boolean) => void;
}

export const ErrorModal = ({ errorList, setShowModal }: ErrorModalProps) => {
  const errorContent = (
    <AgxColumn veryLargeGap veryLargeSidePadding>
      <Images.AlertCircleOutline />
      <AgxHeader size={3}>Your agreement is missing required fields</AgxHeader>
      <AgxBodyText>
        Complete required fields on these pages before sending to DocuSign:
      </AgxBodyText>
      <AgxBodyText>
        <AgxColumn smallGap>
          {errorList &&
            errorList.map((error, idx) => <li key={idx}>{error}</li>)}
        </AgxColumn>
      </AgxBodyText>
      <AgxDivider />
      <AgxButton
        dataTestId="agx-okay-button"
        primary
        wide
        large
        text="Okay"
        rightIcon={<Images.Checkmark />}
        onClick={() => setShowModal(false)}
      />
    </AgxColumn>
  );

  return (
    <AgxSlideUpModal
      closeButton
      content={errorContent}
      desktop={!(ScreenSize() === Breakpoints.Mobile)}
      onClose={() => setShowModal(false)}
    />
  );
};

export default ErrorModal;
