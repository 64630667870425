export const openFileInNewTab = async (
  isDesktop: boolean,
  fileLink: string | null
) => {
  if (!fileLink) return;

  try {
    const anchor = document.createElement('a');
    anchor.href = fileLink;
    anchor.id = 'downloadPdfsAnchor';
    anchor.target = isDesktop ? '_blank' : '';
    document.body.appendChild(anchor);
    anchor.click();
    setTimeout(function () {
      const downloadPdfsAnchor = document.getElementById('downloadPdfsAnchor');
      if (downloadPdfsAnchor) {
        document.body.removeChild(downloadPdfsAnchor);
      }
    }, 200);
  } catch (error) {
    console.error(error);
  }
};
