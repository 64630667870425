import React, { useEffect, useState } from 'react';
import {
  AgxBodyText,
  AgxControlledCheckbox,
  AgxRow,
} from '@urbanx/agx-ui-components';

const ComplimentaryItem = ({
  id,
  productName,
  selected,
  onSelectProductChange,
}) => {
  const [productSelected, setProductSelected] = useState(selected);

  useEffect(() => {
    setProductSelected(selected === true);
  }, [selected]);

  useEffect(() => {
    onSelectProductChange({
      id,
      productName,
      pricePerUnit: null,
      value: productSelected,
    });
  }, [productSelected]);

  const onValueChanged = value => {
    setProductSelected(value === true);
  };

  return (
    <AgxRow
      fill
      spaceBetween
      centered
      mediumGap
      extraClasses="marketingPackageItem"
    >
      <AgxControlledCheckbox
        naked
        label={productName}
        checked={productSelected}
        onValueChanged={onValueChanged}
      />
      <AgxBodyText small>Complimentary</AgxBodyText>
    </AgxRow>
  );
};

export default ComplimentaryItem;
