export interface MarketingPackage {
  id: string;
  name: string;
  displayName: string;
  includeRealEstateListing: boolean;
  includeDomainListing: boolean;
  displayItemisedPricingOnPdf: boolean;
  specialConditions: string;
  items: string[];
  addOns: string[];
  assignedAgentIds: string[];
}

export interface MarketingItem {
  id: string;
  productName: string;
  perPriceUnit: number | null;
  itemType: MarketingItemType;
  supplier: string | null;
  stockType: string | null;
}

export enum MarketingItemType {
  Price,
  Complementary,
  AgentToFill,
  Custom = 999,
}
