import { useEffect, useMemo } from 'react';
import { LoadingState } from '../../../utils/loadingState';
import { useAzureAuth } from '../../../hooks/useAzureAuth';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  fetchPoolCertificate,
  clearPoolCertificateData,
} from '../../poolCertificate/poolCertificateReducer';
import useFormAddressSelector from '../../../selectors/useFormAddressSelector';
import useFormPropertyDetailsSelector from '../../../selectors/useFormPropertyDetailsSelector';
import useFormCampaignId from '../../../hooks/useFormCampaignId';

const PoolSafetyCertificatePreloader = () => {
  const [, getAuthToken] = useAzureAuth();
  const dispatch = useAppDispatch();

  const address = useFormAddressSelector();
  const propertyDetails = useFormPropertyDetailsSelector();
  const campaignId = useFormCampaignId();
  const {
    loadingState: poolCertLoadingState,
    lotNumber: poolCertLotNumber,
    planType: poolCertPlanType,
    planNumber: poolCertPlanNumber,
  } = useAppSelector(state => state.poolDocumentation);

  const poolDocumentation = useAppSelector(
    state => state.form.formValues.poolDocumentation
  );

  const primaryParcel = useMemo(
    () =>
      propertyDetails?.parcels?.find(p => p.isPrimaryPlan) ??
      propertyDetails?.parcels?.[0],
    [propertyDetails]
  );

  useEffect(() => {
    if (
      primaryParcel &&
      (primaryParcel.lotNumber !== poolCertLotNumber ||
        primaryParcel.planType !== poolCertPlanType ||
        primaryParcel.planNumber !== poolCertPlanNumber)
    ) {
      dispatch(clearPoolCertificateData());
    }
  }, [primaryParcel]);

  useEffect(() => {
    if (!getAuthToken) return;
    if (poolCertLoadingState !== LoadingState.NotLoaded) return;
    if (
      !address ||
      !campaignId ||
      !primaryParcel?.lotNumber ||
      !primaryParcel?.planType ||
      !primaryParcel.planNumber
    )
      return;
    if (poolDocumentation?.poolSafetyCertificate?.certificate != null) return;

    getAuthToken().then(async token => {
      if (!token) return;

      dispatch(
        fetchPoolCertificate(
          token,
          campaignId,
          primaryParcel.lotNumber,
          primaryParcel.planType,
          primaryParcel.planNumber
        )
      );
    });
  }, [
    poolCertLoadingState,
    primaryParcel,
    address,
    campaignId,
    poolDocumentation,
  ]);

  return null;
};

export default PoolSafetyCertificatePreloader;
