import {
  AgxColumn,
  AgxHeader,
  AgxRow,
  Images,
} from '@urbanx/agx-ui-components';

interface ImageProps {
  id: string;
  label: string;
  defaultValue: string;
}

const Image = ({ id, label, defaultValue }: ImageProps) => {
  const Image = Images[defaultValue] || Images.SparkleForm;

  return (
    <AgxColumn id={`image-component-${id}`} largeGap fill centered>
      <AgxHeader size={3} centered>
        {label}
      </AgxHeader>
      <AgxRow centered>
        <Image />
      </AgxRow>
    </AgxColumn>
  );
};

export default Image;
