import { useMemo } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { CampaignDetailModel } from '@urbanx/agx-ui-components';

export const useSelectedCampaign = (): CampaignDetailModel | undefined => {
  const { selectedCampaignId, campaigns, archivedCampaigns } = useAppSelector(
    state => state.campaigns
  );

  const selectedCampaign = useMemo(
    () =>
      [...campaigns, ...archivedCampaigns].find(
        c => c.campaignId === selectedCampaignId
      ),
    [selectedCampaignId, campaigns, archivedCampaigns]
  );

  return selectedCampaign;
};
