import {
  AgxHeader,
  RenderAnimation,
  RenderAnimationType,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import GenerationStatus from '../GenerationStatus';

interface AgencyAgreementSubmissionGeneratingProps {
  generationStatus: GenerationStatus;
}

const AgencyAgreementSubmissionGenerating = ({
  generationStatus,
}: AgencyAgreementSubmissionGeneratingProps) => {
  const formName = useAppSelector(state => state.form.formName);

  if (generationStatus === GenerationStatus.Generating) {
    return (
      <>
        <RenderAnimation icon={RenderAnimationType.Mail} />
        <AgxHeader size={2} centered inverse>
          Just a sec
        </AgxHeader>
        <div className="headerSubText">Submitting your {formName}...</div>
      </>
    );
  }

  if (generationStatus === GenerationStatus.Generated) {
    return (
      <>
        <RenderAnimation icon={RenderAnimationType.Champagne} />
        <AgxHeader size={2} centered inverse>
          Submitted!
        </AgxHeader>
      </>
    );
  }
};

export default AgencyAgreementSubmissionGenerating;
