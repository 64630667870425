import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Agent } from '@urbanx/agx-ui-components';
import { setAndShowErrorToast } from 'store/config';
import { LoadingState } from '../../utils/loadingState';
import { agenciesApi } from 'Api/Agencies/agenciesApi';
import { AppDispatch } from 'store/store';

interface ListingAgentState {
  loadingState: LoadingState;
  agents: Agent[];
}

const initialState = {
  loadingState: LoadingState.NotLoaded,
  agents: [],
} satisfies ListingAgentState as ListingAgentState;

const slice = createSlice({
  name: 'listingAgent',
  initialState,
  reducers: {
    startLoadingListingAgents: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Loading,
      };
    },
    listingAgentsLoaded: (state, action: PayloadAction<Agent[]>) => {
      const listingAgents = action.payload;

      return {
        ...state,
        agents: [...listingAgents],
        loadingState: LoadingState.Loaded,
      };
    },
    failedLoadingListingAgents: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Failed,
      };
    },
  },
});

export default slice.reducer;

const {
  startLoadingListingAgents,
  listingAgentsLoaded,
  failedLoadingListingAgents,
} = slice.actions;

export const fetchListingAgents =
  (authToken: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingListingAgents());
      const { data: agents } =
        await agenciesApi(authToken).get<Agent[]>('GetAgentsInAgency');

      dispatch(listingAgentsLoaded(agents));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(failedLoadingListingAgents());
      return err.message;
    }
  };
