import StateFormNames from 'constants/StateFormNames';
import Labels from './Labels';

class NswLabels extends Labels {
  constructor() {
    super();
    this.formName = StateFormNames.NSWFormName;
  }
}

export default NswLabels;
