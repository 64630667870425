import React, { useState, useEffect } from 'react';
import { AgxLabel, AgxRadio } from '@urbanx/agx-ui-components';
import FlatCommission from '../FlatCommission/FlatCommission';
import './AdjustmentCommission.scss';

const AdjustmentCommissionType = {
  NoAdjustment: 'NoAdjustment',
  YesAdjustment: 'YesAdjustment',
};

const AdjustmentCommission = ({
  id,
  label,
  defaultValue,
  onValueChanged,
  validate,
  required,
}) => {
  const [adjustmentCommissionState, setAdjustmentCommissionState] = useState({
    condition: defaultValue?.condition ?? AdjustmentCommissionType.NoAdjustment,
    adjustedCommissionRate: defaultValue?.adjustedCommissionRate,
  });

  const { condition, adjustedCommissionRate } = adjustmentCommissionState;

  useEffect(() => {
    onValueChanged({ id, value: { ...adjustmentCommissionState } });
  }, [adjustmentCommissionState]);

  return (
    <div className="agxRadio">
      <AgxLabel medium>{label}</AgxLabel>
      <div className="agxRadio-group">
        <AgxRadio
          id="noAdjustment"
          label="No adjustment"
          onCheckChanged={() =>
            setAdjustmentCommissionState({
              ...adjustmentCommissionState,
              condition: AdjustmentCommissionType.NoAdjustment,
            })
          }
          checked={condition === AdjustmentCommissionType.NoAdjustment}
        />
        <AgxRadio
          id="yesAdjustment"
          label="Yes"
          onCheckChanged={() =>
            setAdjustmentCommissionState({
              ...adjustmentCommissionState,
              condition: AdjustmentCommissionType.YesAdjustment,
            })
          }
          checked={condition === AdjustmentCommissionType.YesAdjustment}
          subContent={
            condition === AdjustmentCommissionType.YesAdjustment && [
              <FlatCommission
                id="adjustedCommissionRate"
                key="adjustedCommissionRate"
                onValueChanged={({ value }) =>
                  setAdjustmentCommissionState({
                    ...adjustmentCommissionState,
                    adjustedCommissionRate: value,
                  })
                }
                defaultValue={adjustedCommissionRate}
                validate={validate}
                required={true}
                small={true}
              />,
            ]
          }
        />
      </div>
    </div>
  );
};

export default AdjustmentCommission;
