export interface GetRealEstatePricingModel {
  campaignId: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
  section?: Section;
  listingType?: ListingType;
}

export interface GetPricingResponse {
  contract: ProductPricingModel | null;
  products: ProductPricingModel[];
}

export interface ProductPricingModel {
  productId: string;
  productName: string;
  displayPrice: string;
  price: number;
  currency: string;
}

export enum Section {
  Buy,
  Rent,
  Sold,
}

export enum ListingType {
  Residential,
  Land,
  Rural,
}
