import { CampaignStage } from '@urbanx/agx-ui-components';

function getEnumValue<T>(enumObject: T, key: string): T[keyof T] {
  return enumObject[key as keyof T];
}

function getCampaignStageEnumKeyByValue(value: string): string | undefined {
  return Object.keys(CampaignStage).find(
    key => CampaignStage[key as keyof typeof CampaignStage] === value
  );
}

export { getEnumValue, getCampaignStageEnumKeyByValue };
