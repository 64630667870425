import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CMAPropertyResultPage } from './CMAPropertyResultPage';
import { CMASelectedProperties } from './CMASelectedProperties';
import {
  AgxButton,
  AgxRow,
  AgxColumn,
  AgxBodyText,
  Images,
} from '@urbanx/agx-ui-components';
import { cleanStreetAddress } from 'utils/formatAddress';
import { Breakpoints, ScreenSize } from 'utils/screen';
import './SearchNow.scss';

const MaxSelectedProperties = 5;
const SearchNowPage = {
  Filters: 'Filters',
  PropertyResults: 'PropertyResults',
  SelectedProperties: 'SelectedProperties',
};

const SearchNow = ({ id, onValueChanged, defaultValue }) => {
  const { PropertyAddress: propertyAddress } = useSelector(
    state => state.form.formValues
  );

  const { cmaPropertyResults } = useSelector(
    ({ cmaPropertySearch }) => cmaPropertySearch
  );

  const [searchNowPage, setSearchNowPage] = useState(
    defaultValue?.length > 0
      ? SearchNowPage.SelectedProperties
      : SearchNowPage.Filters
  );

  const [selectedProperties, setSelectedProperties] = useState(
    defaultValue ?? []
  );

  const handleCMAPropertySearch = e => {
    e.preventDefault();

    setSearchNowPage(SearchNowPage.PropertyResults);
  };

  const handlePreviousPageClick = e => {
    e.preventDefault();
    setSearchNowPage(SearchNowPage.Filters);
  };

  useEffect(() => {
    const formattedAddresses = selectedProperties.map(property => {
      return {
        PropertyAddress: property.PropertyAddress,
        SoldPrice: property.SoldPrice,
        DistanceInKilometers: property.DistanceInKilometers,
        PropertyDetails: {
          ...property.PropertyDetails,
        },
      };
    });
    onValueChanged({ id, value: formattedAddresses });
  }, [selectedProperties]);

  if (searchNowPage === SearchNowPage.PropertyResults) {
    return (
      <div className="agx-fullPageOverlay">
        <CMAPropertyResultPage
          onPreviousPageClick={handlePreviousPageClick}
          onShowSelectedProperties={selectedProperties => {
            setSelectedProperties(selectedProperties);
            setSearchNowPage(SearchNowPage.SelectedProperties);
          }}
          maxSelectedProperties={MaxSelectedProperties}
        />
      </div>
    );
  } else if (searchNowPage === SearchNowPage.SelectedProperties) {
    return (
      <CMASelectedProperties
        onEditSelectedProperties={() => {
          setSelectedProperties([]);

          if (cmaPropertyResults?.length > 0) {
            setSearchNowPage(SearchNowPage.PropertyResults);
          } else {
            setSearchNowPage(SearchNowPage.Filters);
          }
        }}
        selectedProperties={selectedProperties}
      />
    );
  } else {
    return (
      <AgxColumn veryLargeGap fill>
        <AgxBodyText medium>
          Automatically find comparable properties near{' '}
          <b>{cleanStreetAddress(propertyAddress)}</b>
        </AgxBodyText>
        <AgxRow>
          <AgxButton
            dataTestId="agx-findComparables-button"
            text="Find Comparables"
            primary
            large
            rightIcon={<Images.SearchOutline />}
            onClick={handleCMAPropertySearch}
            wide={ScreenSize() === Breakpoints.Mobile}
          />
        </AgxRow>
      </AgxColumn>
    );
  }
};

export default SearchNow;
