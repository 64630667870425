import {
  AgxHeader,
  SubmitType,
  RenderAnimation,
  RenderAnimationType,
  AustralianState,
} from '@urbanx/agx-ui-components';
import GenerationStatus from '../GenerationStatus';

interface SalesContractGeneratingProps {
  generationStatus: GenerationStatus;
  submitType: SubmitType;
  state: AustralianState;
}

const GeneratingHeaderTextPdf = {
  [AustralianState.QLD]: 'Generating your Contract...',
  [AustralianState.NSW]: 'Generating Sales Advice...',
};
const GeneratingHeaderTextSend = {
  [AustralianState.QLD]: 'Generating your Contract...',
  [AustralianState.NSW]: 'Sending request...',
};

const SalesContractGenerating = ({
  generationStatus,
  submitType,
  state,
}: SalesContractGeneratingProps) => {
  if (generationStatus === GenerationStatus.Generating) {
    return (
      <>
        <RenderAnimation
          icon={
            submitType === SubmitType.GeneratePdf
              ? RenderAnimationType.Settings
              : state === AustralianState.NSW
                ? RenderAnimationType.Paper
                : RenderAnimationType.Mail
          }
        />
        <AgxHeader size={2} centered inverse>
          Just a sec
        </AgxHeader>
        <div className="headerSubText">
          {submitType === SubmitType.GeneratePdf
            ? GeneratingHeaderTextPdf[
                state as keyof typeof GeneratingHeaderTextPdf
              ]
            : GeneratingHeaderTextSend[
                state as keyof typeof GeneratingHeaderTextPdf
              ]}
        </div>
      </>
    );
  }

  if (generationStatus === GenerationStatus.Generated) {
    return (
      <>
        <RenderAnimation
          icon={
            submitType === SubmitType.GeneratePdf
              ? RenderAnimationType.Mail
              : RenderAnimationType.Champagne
          }
        />
        <AgxHeader size={2} centered inverse>
          {submitType === SubmitType.GeneratePdf ? 'Generated!' : 'Success!'}
        </AgxHeader>
      </>
    );
  }
};

export default SalesContractGenerating;
