import { useEffect, useState } from 'react';
import { useAppSelector } from './useAppSelector';

const defaultDomainBackendPrice = 5000000;

export const usePropertyPriceSelector = () => {
  const { campaigns } = useAppSelector(state => state.campaigns);
  const {
    campaignId,
    formValues: { BackendPrice, EstimatedSellingPrice },
  } = useAppSelector(state => state.form);

  const [propertyPrice, setProperyPrice] = useState(defaultDomainBackendPrice);

  useEffect(() => {
    let price: number | null = defaultDomainBackendPrice;

    const relevantPriceRange = EstimatedSellingPrice ?? BackendPrice;

    if (relevantPriceRange != null) {
      const backendPriceToBeConfirmed =
        relevantPriceRange?.toBeConfirmed ?? false;

      if (!backendPriceToBeConfirmed) {
        if (relevantPriceRange?.priceRange != null) {
          const { upperRange, lowerRange } = relevantPriceRange.priceRange;
          price = upperRange ?? lowerRange;
        } else {
          const campaignBackendPriceRange = campaigns.find(
            c => c.campaignId === campaignId
          )?.backendPriceRange;
          if (campaignBackendPriceRange) {
            const { upperRange, lowerRange } = campaignBackendPriceRange;
            price = upperRange ?? lowerRange;
          }
        }
      }
    }

    if (price == null || Number.isNaN(Number(price))) {
      price = defaultDomainBackendPrice;
    }

    if (price !== propertyPrice) {
      setProperyPrice(price);
    }
  }, [campaigns, campaigns, BackendPrice, EstimatedSellingPrice]);

  return propertyPrice;
};
