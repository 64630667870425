import { useState } from 'react';
import './Common.scss';
import { AgxCaption, AgxLabel } from '@urbanx/agx-ui-components';

const RPDataDisclaimer = () => {
  const [showDisclaimerOne, setShowDisclaimerOne] = useState(false);
  const [showDisclaimerTwo, setShowDisclaimerTwo] = useState(false);

  const disclaimerOneContent =
    'This publication reproduces materials and content owned or licenced by RP Data Pty Ltd trading as CoreLogic Asia Pacific (CoreLogic) and may include data, statistics, estimates, indices, photographs, maps, tools, calculators (including their outputs), commentary, reports and other information (CoreLogic Data). © Copyright 2022. CoreLogic and its licensors are the sole and exclusive owners of all rights, title and interest (including intellectual property rights) the CoreLogic Data contained in this publication. All rights reserved.';

  const disclaimerTwoContentPartOne =
    'Online Use: all relevant disclaimers may be housed on a separate link house on a separate page or footer housed on the same page as which the data is being displayed (Disclaimers).';
  const disclaimerTwoContentPartTwo =
    'The CoreLogic Data provided in this publication is of a general nature and should not be construed as specific advice or relied upon in lieu of appropriate professional advice.';
  const disclaimerTwoContentPartThree =
    'While CoreLogic uses commercially reasonable efforts to ensure the CoreLogic Data is current, CoreLogic does not warrant the accuracy, currency or completeness of the CoreLogic Data and to the full extent permitted by law excludes all loss or damage howsoever arising (including through negligence) in connection with the CoreLogic Data.';

  return (
    <>
      <AgxLabel small extraClasses="rpdataDisclaimerLabel">
        Australian Copyright Notice{' '}
        {!showDisclaimerOne && (
          <span
            className="showHideDisclaimer"
            onClick={() => setShowDisclaimerOne(true)}
          >
            {' '}
            Read
          </span>
        )}
        {showDisclaimerOne && (
          <span
            className="showHideDisclaimer"
            onClick={() => setShowDisclaimerOne(false)}
          >
            {' '}
            Hide
          </span>
        )}
      </AgxLabel>
      {showDisclaimerOne && (
        <AgxCaption extraClasses="disclaimerText">
          {disclaimerOneContent}
        </AgxCaption>
      )}
      <AgxLabel small extraClasses="rpdataDisclaimerLabel">
        CoreLogic General Disclaimer{' '}
        {!showDisclaimerTwo && (
          <span
            className="showHideDisclaimer"
            onClick={() => setShowDisclaimerTwo(true)}
          >
            {' '}
            Read
          </span>
        )}
        {showDisclaimerTwo && (
          <span
            className="showHideDisclaimer"
            onClick={() => setShowDisclaimerTwo(false)}
          >
            {' '}
            Hide
          </span>
        )}
      </AgxLabel>
      {showDisclaimerTwo && (
        <AgxCaption extraClasses="disclaimerText">
          {disclaimerTwoContentPartOne}
          <br />
          <br />
          {disclaimerTwoContentPartTwo}
          <br />
          <br />
          {disclaimerTwoContentPartThree}
        </AgxCaption>
      )}
    </>
  );
};

export default RPDataDisclaimer;
