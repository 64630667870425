import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { LoadingState } from '../../../utils/loadingState';
import { useAzureAuth } from '../../../hooks/useAzureAuth';
import { fetchListingAgents } from '../../listingAgent/listingAgentReducer';

const ListingAgentSelectorPreloader = () => {
  const { loadingState: agentsLoadingState } = useAppSelector(
    state => state.listingAgent
  );

  const dispatch = useAppDispatch();

  const [, getAuthToken] = useAzureAuth();

  useEffect(() => {
    if (agentsLoadingState !== LoadingState.NotLoaded) return;

    getAuthToken().then(token => {
      if (!token) return;
      dispatch(fetchListingAgents(token));
    });
  }, [agentsLoadingState]);

  return null;
};

export default ListingAgentSelectorPreloader;
