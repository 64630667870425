import { FormType } from '@urbanx/agx-ui-components';
import { useSelectedContract } from 'hooks/useSelectedContract';
import ListingInfoPanel from './ListingInfoPanel';
import ContractRequestInfoPanel from './ContractRequest/ContractRequestInfoPanel';
import ContractSubmissionInfoPanel from './ContractSubmission/ContractSubmissionInfoPanel';

const ListingsPanel = ({ campaign, currentTab, mobile = false }) => {
  const selectedContract = useSelectedContract();
  if (selectedContract == null) {
    return (
      <ListingInfoPanel
        campaign={campaign}
        currentTab={currentTab}
        mobile={mobile}
      />
    );
  }

  switch (
    selectedContract.inProgressForm?.formType ??
    FormType.ContractSubmission
  ) {
    case FormType.ContractRequest:
      return (
        <ContractRequestInfoPanel
          campaign={campaign}
          contract={selectedContract}
          currentTab={currentTab}
          mobile={mobile}
        />
      );
    case FormType.ContractSubmission:
    default:
      return (
        <ContractSubmissionInfoPanel
          state={campaign.state}
          contract={selectedContract}
          currentTab={currentTab}
          mobile={mobile}
        />
      );
  }
};

export default ListingsPanel;
