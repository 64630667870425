import { combineReducers } from 'redux';

import agenciesReducer from '../features/agencies/agenciesReducer';
import agentInfoReducer from './agentInfoReducer';
import campaignsReducer from '../features/campaigns/campaignsReducer';
import cmaPropertySearchReducer from './cmaPropertySearch/cmaPropertySearchReducer';
import configReducer from './config';
import domainReducer from '../features/domain/domainReducer';
import formReducer from '../features/form/formReducer';
import formsReducer from '../features/forms/formsReducer';
import formSettingsReducer from '../features/form/formSettingsReducer';
import listingAgentReducer from '../features/listingAgent/listingAgentReducer';
import notificationsReducer from './notificationsReducer';
import poolCertificateReducer from '../features/poolCertificate/poolCertificateReducer';
import realEstateReducer from '../features/realEstate/realEstateReducer';
import reportingDashboardReducer from '../features/reportingDashboard/reportingDashboardReducer';
import rpDataReducer from 'features/rpData/rpDataReducer';
import topPerformersReducer from 'features/reportingDashboard/topPerformersReducer';

const rootReducer = combineReducers({
  agencies: agenciesReducer,
  agentInfo: agentInfoReducer,
  campaigns: campaignsReducer,
  cmaPropertySearch: cmaPropertySearchReducer,
  config: configReducer,
  domain: domainReducer,
  form: formReducer,
  forms: formsReducer,
  formSettings: formSettingsReducer,
  listingAgent: listingAgentReducer,
  notifications: notificationsReducer,
  poolDocumentation: poolCertificateReducer,
  realEstate: realEstateReducer,
  reportingDashboard: reportingDashboardReducer,
  rpData: rpDataReducer,
  topPerformers: topPerformersReducer,
});

export default rootReducer;
