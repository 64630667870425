import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { AgxSelect } from '@urbanx/agx-ui-components';

const ListingAgentSelector = ({ id, onValueChanged, defaultValue = null }) => {
  const { agents } = useSelector(state => state.listingAgent);

  const [userAccount] = useAzureAuth();
  const [listingAgents, setListingAgents] = useState({
    leadAgentId: defaultValue?.leadAgentId ?? '',
    secondAgentId: defaultValue?.secondAgentId ?? '',
  });

  const { leadAgentId, secondAgentId } = listingAgents;

  const agentOptions = useMemo(() => {
    return [
      {
        value: '',
        label: 'Please select an agent',
      },
    ].concat(
      agents.map(agent => {
        return {
          value: agent.id,
          label: `${agent.name?.firstName} ${agent.name?.lastName}`,
        };
      })
    );
  }, [agents]);

  const leadAgentOptions = useMemo(
    () =>
      agentOptions.filter(
        option => option.value !== secondAgentId || option.value === ''
      ),
    [agentOptions, secondAgentId]
  );

  const secondAgentOptions = useMemo(
    () =>
      agentOptions.filter(
        option => option.value !== leadAgentId || option.value === ''
      ),
    [agentOptions, leadAgentId]
  );

  useEffect(() => {
    if (leadAgentId === secondAgentId) {
      setListingAgents({
        ...listingAgents,
        secondAgentId: '',
      });
    }
  }, [leadAgentId]);

  useEffect(() => {
    onValueChanged({ id, value: listingAgents });
  }, [listingAgents]);

  useEffect(() => {
    if (userAccount && listingAgents.leadAgentId === '') {
      setListingAgents({
        ...listingAgents,
        leadAgentId: userAccount.agent,
      });
    }
  }, [userAccount, listingAgents]);

  const leadAgentDefaultValue = useMemo(() => {
    return agentOptions.find(agent => agent.value === leadAgentId);
  }, [leadAgentId]);

  const secondAgentDefaultValue = useMemo(() => {
    return agentOptions.find(agent => agent.value === secondAgentId);
  }, [secondAgentId]);

  return (
    <>
      <AgxSelect
        key={`${id}-leadAgent`}
        id={`${id}-leadAgent`}
        label="Lead Agent"
        options={leadAgentOptions}
        onValueChanged={({ value }) =>
          setListingAgents({
            ...listingAgents,
            leadAgentId: value,
          })
        }
        dataTestId={`agx-listingAgentSelector${id}-leadAgent`}
        required
        defaultValue={leadAgentDefaultValue}
      />
      <AgxSelect
        key={`${id}-secondAgent`}
        id={`${id}-secondAgent`}
        label="Second Agent"
        options={secondAgentOptions}
        onValueChanged={({ value }) =>
          setListingAgents({
            ...listingAgents,
            secondAgentId: value,
          })
        }
        dataTestId={`agx-listingAgentSelector${id}-secondAgent`}
        defaultValue={secondAgentDefaultValue}
      />
    </>
  );
};

export default ListingAgentSelector;
