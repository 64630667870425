import React from 'react';
import {
  AgxBodyText,
  AgxColumn,
  AgxHeader,
  RenderAnimation,
  RenderAnimationType,
} from '@urbanx/agx-ui-components';
import { PropertySuggestionItem } from 'Api/RpData/Types/types';
import './PropertySearchLoading.scss';

interface PropertySearchLoadingProps {
  suggestionItem: PropertySuggestionItem | null;
}

export const PropertySearchLoading: React.FC<PropertySearchLoadingProps> = ({
  suggestionItem,
}) => {
  return (
    <AgxColumn fill centered extraClasses="propertySearchLoaderContent">
      <AgxHeader size={1} inverse>
        Sit tight!
      </AgxHeader>
      <AgxColumn centerJustified centered extraClasses="searchLoaderAnimation">
        <RenderAnimation icon={RenderAnimationType.Settings} />
      </AgxColumn>
      <AgxColumn fill extraClasses="propertySearchDetailText">
        <AgxBodyText large secondary centered>
          We're loading data for:
        </AgxBodyText>
        {suggestionItem && (
          <AgxHeader size={2} inverse centered>
            {suggestionItem.suggestionLine1}
            <br />
            {suggestionItem.suggestionLine2}
          </AgxHeader>
        )}
      </AgxColumn>
    </AgxColumn>
  );
};
