import { Breakpoints, ScreenSize } from 'utils/screen';
import { AgxHeader, Images } from '@urbanx/agx-ui-components';
import { stringToKebabCase } from 'utils/formatString';
import './PageTitle.scss';

export const PageTitle = ({ onClick, title, breadcrumbsAvailable }) => {
  const isMobile = ScreenSize() === Breakpoints.Mobile;

  if (!title) return null;

  return (
    <div
      className="pageTitle"
      data-testid={`agxForm-${stringToKebabCase(title)}`}
    >
      <AgxHeader
        size={3}
        inverse
        onClick={() => isMobile && breadcrumbsAvailable && onClick && onClick()}
      >
        <span className="pageTitleContent">
          <span>{title}</span>
          {isMobile && breadcrumbsAvailable && <Images.Dropdown />}
        </span>
      </AgxHeader>
    </div>
  );
};
