import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import LogRocket from 'logrocket';

export const initStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        LogRocket.reduxMiddleware()
      ),
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ReturnType<typeof initStore>['dispatch'];
