import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AgxBodyText, AgxColumn, AgxHeader } from '@urbanx/agx-ui-components';
import { useAzureAuth } from 'hooks/useAzureAuth';
import useQuery from 'hooks/useQuery';
import { storeDocuSignAccessCode } from 'Api/DocuSign/docusignApi';
import { StoreAccessCodeResult } from 'Api/DocuSign/Types/types';
import './Authentication.scss';

interface DocuSignAuthenticatedProps {
  children?: React.ReactNode;
}

const DocuSignAuthenticated = (props: DocuSignAuthenticatedProps) => {
  const query = useQuery();
  const navigate = useNavigate();
  const [, getAuthToken] = useAzureAuth();
  const [storeAccessCodeResult, setStoreAccessCodeResult] = useState<
    StoreAccessCodeResult | undefined
  >(undefined);

  const storeAccessCode = useCallback(
    async (code: string) => {
      if (!getAuthToken) return;

      const token = await getAuthToken();

      if (!token) return;

      const storeAccessCodeResponse = await storeDocuSignAccessCode(
        token,
        code
      );
      if (
        storeAccessCodeResponse &&
        storeAccessCodeResponse.result === StoreAccessCodeResult.Success
      ) {
        navigate('/');
      }

      setStoreAccessCodeResult(storeAccessCodeResponse?.result);
    },
    [getAuthToken]
  );

  useEffect(() => {
    const code = query.get('code');

    if (!code) {
      navigate('/');
      return;
    }

    storeAccessCode(code);
  }, []);

  if (storeAccessCodeResult === StoreAccessCodeResult.Error) {
    return (
      <AgxColumn extraClasses="authentication-error">
        <AgxColumn extraClasses="authentication-error-body">
          <AgxHeader centered size={3}>
            An error occurred
          </AgxHeader>
          <AgxBodyText>
            Autopilot failed to setup your DocuSign account. Contact UrbanX
            support at <a href="mailto:support@urbanx.io">support@urbanx.io</a>{' '}
            for assistance.
          </AgxBodyText>
        </AgxColumn>
      </AgxColumn>
    );
  } else if (storeAccessCodeResult === StoreAccessCodeResult.InvalidAccount) {
    return (
      <AgxColumn extraClasses="authentication-error">
        <AgxColumn extraClasses="authentication-error-body docuSign-body">
          <AgxHeader centered size={3}>
            Autopilot failed to setup your DocuSign account
          </AgxHeader>
          <AgxBodyText>Please try the following to fix the issue:</AgxBodyText>
          <ol>
            <li>
              Visit{' '}
              <a
                href="https://apps.docusign.com/"
                target="_blank"
                rel="noreferrer"
              >
                https://apps.docusign.com/
              </a>
            </li>
            <li>
              Log out by selecting your profile icon at the top right and
              clicking 'Log Out'
            </li>
            <li>
              <Link to="/">Retry logging into Autopilot again</Link> using a
              DocuSign account linked to the UrbanX organisation account
            </li>
          </ol>
          <AgxBodyText>
            If the problem persists then contact UrbanX support at{' '}
            <a href="mailto:support@urbanx.io">support@urbanx.io</a> for
            assistance.
          </AgxBodyText>
        </AgxColumn>
      </AgxColumn>
    );
  } else {
    return props.children;
  }
};

export default DocuSignAuthenticated;
