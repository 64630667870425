import {
  AgxHeader,
  RenderAnimation,
  RenderAnimationType,
} from '@urbanx/agx-ui-components';
import GenerationStatus from '../GenerationStatus';

interface SalesContractSubmissionGeneratingProps {
  generationStatus: GenerationStatus;
}

const SalesContractSubmissionGenerating = ({
  generationStatus,
}: SalesContractSubmissionGeneratingProps) => {
  if (generationStatus === GenerationStatus.Generating) {
    return (
      <>
        <RenderAnimation icon={RenderAnimationType.Mail} />
        <AgxHeader size={2} centered inverse>
          Just a sec
        </AgxHeader>
        <div className="headerSubText">
          Congratulations! Submitting your Contract...
        </div>
      </>
    );
  }

  if (generationStatus === GenerationStatus.Generated) {
    return (
      <>
        <RenderAnimation icon={RenderAnimationType.Champagne} />
        <AgxHeader size={2} centered inverse>
          Success!
        </AgxHeader>
      </>
    );
  }
};

export default SalesContractSubmissionGenerating;
