import { useState, useEffect } from 'react';
import {
  AgxLabel,
  AgxSelect,
  AgxRadio,
  AgxTextInput,
  AgxCaption,
  AgxAutoCompleteAddress,
} from '@urbanx/agx-ui-components';
import './ExternalConjunction.scss';

interface ExternalConjunctionProps {
  id: string;
  label: string;
  defaultValue: {
    isExternalConjunctionOrReferral?: boolean;
    conjunctingAgentName?: string;
    conjunctingCompanyName?: string;
    conjunctingAgentEmail?: string;
    conjunctionFeeType: string;
    conjunctionFee?: number;
  };
  onValueChanged: (value: { id: string; value: any }) => void;
  validate?: boolean;
}

const ConjunctionFeeType = {
  Percentage: 'Percentage',
  FlatFee: 'FlatFee',
};

const ExternalConjunction = ({
  id,
  label,
  defaultValue,
  onValueChanged,
  validate,
}: ExternalConjunctionProps) => {
  const [externalConjunctionState, setExternalConjunctionState] = useState({
    isExternalConjunctionOrReferral:
      defaultValue?.isExternalConjunctionOrReferral,
    conjunctingAgentName: defaultValue?.conjunctingAgentName,
    conjunctingCompanyName: defaultValue?.conjunctingCompanyName,
    conjunctingAgentEmail: defaultValue?.conjunctingAgentEmail,
    conjunctionFeeType:
      defaultValue?.conjunctionFeeType ?? ConjunctionFeeType.Percentage,
    conjunctionFee: defaultValue?.conjunctionFee,
  });

  const conjunctionFeeOptions = [
    {
      value: 'Percentage',
      label: 'Percentage',
    },
    {
      value: 'FlatFee',
      label: 'Flat Fee',
    },
  ];

  const {
    isExternalConjunctionOrReferral,
    conjunctingAgentName,
    conjunctingAgentEmail,
    conjunctionFeeType,
    conjunctionFee,
  } = externalConjunctionState;

  useEffect(() => {
    onValueChanged({ id, value: { ...externalConjunctionState } });
  }, [externalConjunctionState]);

  const showValidationError = isExternalConjunctionOrReferral && validate;

  let externalConjunctionBody = [];

  if (isExternalConjunctionOrReferral) {
    externalConjunctionBody.push(
      <AgxAutoCompleteAddress
        key={`${isExternalConjunctionOrReferral}conjunctingCompanyName`}
        id="conjunctingCompanyName"
        label="Agency name"
        defaultValue={{
          formattedAddress:
            externalConjunctionState.conjunctingCompanyName ?? '',
        }}
        onClick={value =>
          setExternalConjunctionState({
            ...externalConjunctionState,
            conjunctingCompanyName: value.name,
          })
        }
        onAddressSelect={address => {
          if (address.selected) {
            setExternalConjunctionState({
              ...externalConjunctionState,
              conjunctingCompanyName: address.value,
            });
          }
        }}
        onAddressInputValueChange={value => {
          if (value === '') {
            setExternalConjunctionState({
              ...externalConjunctionState,
              conjunctingCompanyName: '',
            });
          }
        }}
        type="establishment"
        stretch
        error={
          validate &&
          externalConjunctionState &&
          externalConjunctionState.conjunctingCompanyName?.trim() === ''
            ? 'Enter conjuncting agency name'
            : ''
        }
      />
    );

    externalConjunctionBody.push(
      <AgxTextInput
        key={`${isExternalConjunctionOrReferral}conjunctingAgentName`}
        id="conjunctingAgentName"
        label="Agent Name"
        defaultValue={conjunctingAgentName}
        onInputValueChange={({ value }) =>
          setExternalConjunctionState({
            ...externalConjunctionState,
            conjunctingAgentName: value,
          })
        }
        stretch
        noOptionalLabel
        required={externalConjunctionState.isExternalConjunctionOrReferral}
        error={
          showValidationError && !conjunctingAgentName
            ? 'Enter conjuncting agent name'
            : ''
        }
      />
    );

    externalConjunctionBody.push(
      <AgxTextInput
        key={`${isExternalConjunctionOrReferral}externalConjunctingAgentEmail`}
        id="externalConjunctingAgentEmail"
        label="Email"
        email
        noOptionalLabel
        defaultValue={conjunctingAgentEmail}
        onInputValueChange={({ value }) =>
          setExternalConjunctionState({
            ...externalConjunctionState,
            conjunctingAgentEmail: value,
          })
        }
        stretch
        required={externalConjunctionState.isExternalConjunctionOrReferral}
        error={
          showValidationError && !conjunctingAgentEmail
            ? 'Enter agent email'
            : ''
        }
      />
    );

    externalConjunctionBody.push(
      <div className="agx-conjunctionFee">
        <AgxLabel medium>Conjunction/referral fee</AgxLabel>
        <div className="agx-conjunctionFee-inputs">
          <AgxSelect
            id={`${id}conjunctionFeeSelect`}
            options={conjunctionFeeOptions}
            onValueChanged={({ value }) =>
              setExternalConjunctionState({
                ...externalConjunctionState,
                conjunctionFeeType: value,
                conjunctionFee: undefined,
              })
            }
            defaultValue={
              conjunctionFeeOptions.find(
                option => option.value === conjunctionFeeType
              ) ?? conjunctionFeeOptions[0]
            }
            noHeader
          />
          {conjunctionFeeType === ConjunctionFeeType.FlatFee ? (
            <AgxTextInput
              id={`${id}-conjunctionFeeFlatFee`}
              onInputValueChange={({ value }) => {
                const conjunctionFee = Number(value);

                setExternalConjunctionState({
                  ...externalConjunctionState,
                  conjunctionFee:
                    conjunctionFee && !Number.isNaN(conjunctionFee)
                      ? conjunctionFee
                      : undefined,
                });
              }}
              noHeader
              currency={conjunctionFeeType === ConjunctionFeeType.FlatFee}
              defaultValue={conjunctionFee?.toString() ?? ''}
              parentControlValue
              required={
                externalConjunctionState.isExternalConjunctionOrReferral
              }
              error={
                showValidationError && !conjunctionFee
                  ? 'Enter conjuncting fee'
                  : ''
              }
            />
          ) : (
            <AgxTextInput
              id={`${id}-conjunctionFeePercentage`}
              onInputValueChange={({ value }) =>
                setExternalConjunctionState({
                  ...externalConjunctionState,
                  conjunctionFee: value ? parseFloat(value) : undefined,
                })
              }
              noHeader
              percent={conjunctionFeeType === ConjunctionFeeType.Percentage}
              defaultValue={conjunctionFee?.toString() ?? ''}
              parentControlValue
              required={
                externalConjunctionState.isExternalConjunctionOrReferral
              }
              error={
                showValidationError && !conjunctionFee ? 'Enter percentage' : ''
              }
            />
          )}
        </div>
        {conjunctionFeeType === ConjunctionFeeType.Percentage && (
          <AgxCaption>Including GST</AgxCaption>
        )}
      </div>
    );
  }

  return (
    <div className="agxRadio">
      <AgxLabel medium>{label}</AgxLabel>
      <div className="agxRadio-group">
        <AgxRadio
          id="noConjunction"
          label="No"
          onCheckChanged={() =>
            setExternalConjunctionState({
              ...externalConjunctionState,
              isExternalConjunctionOrReferral: false,
            })
          }
          checked={!isExternalConjunctionOrReferral}
        />
        <AgxRadio
          id="yesConjunction"
          label="Yes"
          onCheckChanged={() =>
            setExternalConjunctionState({
              ...externalConjunctionState,
              isExternalConjunctionOrReferral: true,
            })
          }
          checked={!!isExternalConjunctionOrReferral}
          subContent={externalConjunctionBody}
        />
      </div>
    </div>
  );
};

export default ExternalConjunction;
