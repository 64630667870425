import {
  AgxColumn,
  AgxButton,
  AgxBodyText,
  AgxHeader,
  Images,
} from '@urbanx/agx-ui-components';
import './RedirectToProd.scss';
import React from 'react';

const RedirectToProd = () => {
  return (
    <AgxColumn
      fill
      centered
      extraClasses={'fadeInAnimation alignVCenter'}
      extraLargeGap
    >
      <Images.Tickets />
      <AgxHeader size={2} centered>
        Thanks for being a part of the Autopilot Beta!
      </AgxHeader>
      <AgxBodyText medium centered>
        You can no longer access Autopilot on this link.
      </AgxBodyText>
      <AgxColumn fill centered>
        <AgxBodyText medium centered>
          Autopilot is now accessible through the live website:
        </AgxBodyText>
        <AgxBodyText medium centered>
          <a className="hyperlink" href={'https://autopilot.urbanx.io/'}>
            autopilot.urbanx.io
          </a>
        </AgxBodyText>
      </AgxColumn>
      <AgxButton
        medium
        text="Access Autopilot"
        extraClasses={'redirectButtonStyle'}
        rightIcon={<Images.ArrowForwardOutlineLight />}
        onClick={() => {
          window.location.href = 'https://autopilot.urbanx.io/';
        }}
      />
    </AgxColumn>
  );
};

export default RedirectToProd;
