import { useMemo } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useSelectedCampaign } from './useSelectedCampaign';

export const useSelectedContract = () => {
  const { selectedContractId } = useAppSelector(state => state.campaigns);
  const selectedCampaign = useSelectedCampaign();

  const selectedContract = useMemo(
    () =>
      selectedCampaign?.contractDetails?.find(
        c => c.contractId === selectedContractId
      ),
    [selectedContractId, selectedCampaign]
  );

  return selectedContract;
};
