import StateFormNames from 'constants/StateFormNames';
import Labels from './Labels';

class QldLabels extends Labels {
  constructor() {
    super();
    this.formName = StateFormNames.QldFormName;
  }
}

export default QldLabels;
