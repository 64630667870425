import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  AgxRow,
  AgxColumn,
  AgxDatePicker,
  AgxTextInput,
} from '@urbanx/agx-ui-components';
import './AuctionDetails.scss';

const AuctionDetails = ({
  id,
  validate,
  required,
  onValueChanged,
  defaultValue = null,
}) => {
  const [auctionDetails, setAuctionDetails] = useState({
    auctionDate:
      (defaultValue?.auctionDate && moment(defaultValue.auctionDate)) ??
      moment().startOf('day'),
    location: defaultValue?.location ?? '',
  });

  const { auctionDate, location } = auctionDetails;
  const [auctionTime, setAuctionTime] = useState(auctionDate.format('HH:mm'));

  useEffect(() => {
    onValueChanged({
      id,
      value: {
        ...auctionDetails,
        auctionDate: auctionDate.format('YYYY-MM-DDTHH:mm:ss'),
      },
    });
    setAuctionTime(auctionDate.format('HH:mm'));
  }, [auctionDetails]);

  return (
    <AgxColumn largeGap fill key={id}>
      <AgxRow extraClasses="startAlign" largeGap fill>
        <AgxDatePicker
          id={`${id}-date`}
          key={`${id}-date`}
          label="Auction Date"
          required
          defaultValue={auctionDate.format('YYYY-MM-DD')}
          onValueChanged={({ value }) => {
            const datePortion = moment(value, 'YYYY-MM-DD');
            const constructedDate = moment(
              `${datePortion.format('YYYY-MM-DD')}, ${auctionDate.format(
                'HH:mm'
              )}}`,
              'YYYY-MM-DD, HH:mm'
            );
            setAuctionDetails({
              ...auctionDetails,
              auctionDate: constructedDate,
            });
          }}
          date
          stretch
          error={
            validate &&
            (!defaultValue?.auctionDate ||
              defaultValue?.auctionDate === 'Invalid date')
              ? 'Enter a date'
              : undefined
          }
        />
        <AgxDatePicker
          id={`${id}-time`}
          key={`${id}-time`}
          label="Auction Time"
          required={required}
          blankValue={auctionTime !== '00:00' ? false : true}
          defaultValue={auctionTime}
          onValueChanged={({ value }) => {
            const timePortion = value ? moment(value, 'HH:mm') : null;
            if (timePortion) setAuctionTime(timePortion);
            const constructedDate = moment(
              `${auctionDate.format('YYYY-MM-DD')}, ${
                timePortion
                  ? timePortion?.format('HH:mm')
                  : moment().startOf('day').format('HH:mm')
              }}`,
              'YYYY-MM-DD, HH:mm'
            );
            setAuctionDetails({
              ...auctionDetails,
              auctionDate: constructedDate,
            });
          }}
          date={false}
          stretch
        />
      </AgxRow>
      <AgxTextInput
        id={'auctionDetailsLocation'}
        label="Location"
        required={required}
        onInputValueChange={({ value }) => {
          setAuctionDetails({
            ...auctionDetails,
            location: value,
          });
        }}
        stretch
        defaultValue={location}
      ></AgxTextInput>
    </AgxColumn>
  );
};

export default AuctionDetails;
