import {
  AgxColumn,
  AgxControlledCheckbox,
  AgxDatePicker,
} from '@urbanx/agx-ui-components';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const LaunchDetails = ({ id, onValueChanged, defaultValue, validate }) => {
  const [details, setDetails] = useState(
    defaultValue ?? {
      launchDate: moment().format('YYYY-MM-DD'),
      launchDateIsTentative: false,
    }
  );

  const { launchDate, launchDateIsTentative } = details;
  useEffect(() => {
    onValueChanged({ id, value: details });
  }, [details]);

  return (
    <AgxColumn largeGap fill>
      <AgxDatePicker
        id="launchDate"
        onValueChanged={({ value }) =>
          setDetails({
            ...details,
            launchDate: value,
          })
        }
        date
        required={true}
        label="Launch Date"
        stretch
        defaultValue={launchDate}
        error={validate && launchDate === '' ? 'Enter launch date' : undefined}
      />
      <AgxControlledCheckbox
        onValueChanged={value =>
          setDetails({
            ...details,
            launchDateIsTentative: value,
          })
        }
        checked={launchDateIsTentative}
        naked
        label="Tentative Date (likely to change)"
      />
    </AgxColumn>
  );
};

export default LaunchDetails;
