import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import { closePopup } from 'store/config';
import { AgxButton } from '@urbanx/agx-ui-components';
import './Popup.scss';

const Popup = ({ selector }) => {
  const {
    popup: {
      id,
      open,
      color,
      title,
      body,
      onConfirm,
      buttonText: { close, confirm },
    },
  } = useSelector(state => state.config);

  const dispatch = useDispatch();

  const popupStyles = {
    overlay: {
      backgroundColor: 'rgba(40,40,40, 0.75)',
    },
    content: {
      height: 'fit-content',
      width: 'fit-content',
      border: '1px solid #ccc',
      background: '#fff',
      borderRadius: '16px',
      outline: 'none',
      margin: '10rem auto',
    },
  };

  const RenderPopup = () => {
    return (
      <>
        <span
          className="absolute cursor-pointer text-lg text-gray-400 top-0 right-4 btn-x"
          onClick={() => dispatch(closePopup())}
        >
          x
        </span>
        {title && (
          <h2 className="text-xl font-semibold text-center">{title}</h2>
        )}
        {body && <p className="text-md text-center">{body}</p>}
        <div className="flex justify-center mt-4">
          {close && (
            <AgxButton
              outline
              classes="mx-1 btn-close"
              onClick={() => dispatch(closePopup())}
              text={close}
            />
          )}
          {confirm && (
            <AgxButton
              classes={`bg-${color} mx-1 btn-confirm`}
              onClick={() => onConfirm()}
              text={confirm}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <ReactModal
      id={id}
      testId={id}
      style={popupStyles}
      parentSelector={() => document.querySelector(selector)} //Portal
      ariaHideApp={false}
      isOpen={open}
      onRequestClose={() => dispatch(closePopup())}
    >
      <RenderPopup />
    </ReactModal>
  );
};

export default Popup;
