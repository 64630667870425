import { useReducer } from 'react';

export const ADD_FILES = 'ADD_FILES';
export const REMOVE_FILE = 'REMOVE_FILE';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const ADD_UPLOADED_FILE = 'ADD_UPLOADED_FILE';

export interface FilePayload {
  id: string;
  progress?: Number;
  uploadedFile?: File;
  fileName?: string;
  fileSize?: Number;
  formData?: Blob | FormData;
  abortController?: AbortController;
}

interface Action {
  type: string;
  payload: FilePayload | FilePayload[];
}
const initialState: FilePayload[] = [];

export const uploadingFilesReducer = (state: FilePayload[], action: Action) => {
  switch (action.type) {
    case ADD_FILES:
      return [...state, ...(action.payload as FilePayload[])];
    case REMOVE_FILE:
      return state.filter(
        file => file.id !== (action.payload as FilePayload).id
      );
    case UPDATE_PROGRESS:
      return state.map(file => {
        if (file.id === (action.payload as FilePayload).id) {
          return {
            ...file,
            progress: (action.payload as FilePayload).progress,
          };
        }

        return file;
      });
    case ADD_UPLOADED_FILE:
      return state.map(file => {
        if (file.id === (action.payload as FilePayload).id) {
          return {
            ...file,
            uploadedFile: (action.payload as FilePayload).uploadedFile,
          };
        }

        return file;
      });
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const useUploadingFilesReducer = () => {
  const [uploadingFiles, dispatch] = useReducer(
    uploadingFilesReducer,
    initialState
  );

  const updateFileUploadProgress = (id: string, progress: number) => {
    dispatch({ type: UPDATE_PROGRESS, payload: { id, progress } });
  };

  const addUploadingFiles = (uploadingFiles: FilePayload[]) => {
    dispatch({ type: ADD_FILES, payload: uploadingFiles });
  };

  const removeUploadingFile = (uploadingFile: FilePayload) => {
    dispatch({ type: REMOVE_FILE, payload: uploadingFile });
  };

  const addFileToUploadingFile = (
    uploadingFile: FilePayload,
    uploadedFile: File
  ) => {
    dispatch({
      type: ADD_UPLOADED_FILE,
      payload: { id: uploadingFile.id, uploadedFile },
    });
  };

  return {
    uploadingFiles,
    updateFileUploadProgress,
    addUploadingFiles,
    removeUploadingFile,
    addFileToUploadingFile,
  };
};
