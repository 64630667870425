import { PropertyDetails, UploadedDocument } from '@urbanx/agx-ui-components';

export interface Comparatives {
  comparativeType: ComparativeType;
  searchAddresses: ComparativeAddress;
  manualAddresses: ComparativeAddress;
  attachedFileInfo: UploadedDocument;
}

export enum ComparativeType {
  Search = 'Search',
  Manual = 'Manual',
  AttachAsFile = 'AttachAsFile',
  NotAvailable = 'NotAvailable',
}

export interface ComparativeAddress {
  PropertyAddress: string | null;
  SoldPrice: number | null;
  DistanceInKilometers: number | null;
  PropertyDetails?: PropertyDetails | null;
  SettlementDate?: Date | null;
}
