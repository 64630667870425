import { useSelectedCampaign } from 'hooks/useSelectedCampaign';
import ResumeContractSubmission from './ResumeContractSubmission';
import ContractSubmitPanel from './ContractSubmitPanel';
import {
  QldContractStage,
  NswContractStage,
  AustralianState,
} from '@urbanx/agx-ui-components';
import { getEnumValue } from 'helpers/enumHelper';

const ContractSubmissionInfoPanel = ({
  state,
  contract,
  currentTab,
  mobile = false,
}) => {
  const selectedCampaign = useSelectedCampaign();
  const { contractStage } = contract;

  const resumeStages = [
    QldContractStage.ContractDraft,
    QldContractStage.BuyerSigning,
    QldContractStage.SignedByBuyer,
    QldContractStage.SignedByAllParties,
    QldContractStage.SubmissionIncomplete,
    NswContractStage.ContractDraft,
    NswContractStage.SalesAdviceDraft,
    NswContractStage.SalesAdviceSent,
    NswContractStage.ContractRequested,
    NswContractStage.SubmissionIncomplete,
  ];

  const submitStages = [
    QldContractStage.Accepted,
    QldContractStage.Unconditional,
    QldContractStage.Terminated,
    QldContractStage.Settled,
    NswContractStage.Accepted,
    NswContractStage.Unconditional,
    NswContractStage.Terminated,
    NswContractStage.Settled,
  ];

  let currentStage;
  if (state === AustralianState.QLD)
    currentStage = getEnumValue(QldContractStage, contractStage);
  else if (state === AustralianState.NSW)
    currentStage = getEnumValue(NswContractStage, contractStage);

  if (resumeStages.includes(currentStage))
    return (
      <ResumeContractSubmission
        currentTab={currentTab}
        campaign={selectedCampaign}
        contract={contract}
        mobile={mobile}
      />
    );
  if (submitStages.includes(currentStage))
    return (
      <ContractSubmitPanel campaign={selectedCampaign} contract={contract} />
    );

  return null;
};

export default ContractSubmissionInfoPanel;
