import { PropertyAddress } from '@urbanx/agx-ui-components';
import { useAppSelector } from '../hooks/useAppSelector';

const useFormAddressSelector = (): PropertyAddress | undefined => {
  const addressSetInForm = useAppSelector(
    state => state.form?.formValues?.PropertyAddress
  );
  const addressProvidedFromPreviousForm = useAppSelector(
    state => state.form.address
  );
  return addressSetInForm ?? addressProvidedFromPreviousForm;
};

export default useFormAddressSelector;
