import { agenciesApi } from 'Api/Agencies/agenciesApi';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAzureAuth } from '../../../hooks/useAzureAuth';
import {
  EntityTypes,
  ReportingDashboardState,
  goalsLoaded,
  goalsStartedLoading,
} from '../reportingDashboardReducer';
import { useEffect, useMemo } from 'react';
import { LoadingState } from '../../../utils/loadingState';
import { EntityGoalsModel, SaleGoals } from 'Api/Agencies/Types/Agencies';

export const GoalsEntityType = { AgentOrTeam: 0, Agency: 1 };

const useGoals = (
  entityType: string
): [
  SaleGoals | undefined,
  (entityType: string, saleGoals: SaleGoals) => void,
] => {
  const reportingDashboardState: ReportingDashboardState = useAppSelector(
    state => state.reportingDashboard
  );

  const { goals, goalsLoadingState } = reportingDashboardState;

  const dispatch = useAppDispatch();

  const [userAccount, getAuthToken] = useAzureAuth();

  const loadGoals = async () => {
    if (!getAuthToken) return;

    dispatch(goalsStartedLoading());
    const token = await getAuthToken();

    if (!token) return;

    const goalsResponse =
      await agenciesApi(token).get<EntityGoalsModel>('GetEntityGoals');
    const goals = goalsResponse.data;

    dispatch(goalsLoaded({ ...goals }));
  };

  useEffect(() => {
    if (!userAccount) return;

    if (goalsLoadingState === LoadingState.NotLoaded) {
      loadGoals();
    }
  }, [goalsLoadingState, userAccount]);

  const saveGoals = async (entityType: string, saleGoals: SaleGoals) => {
    if (!getAuthToken) return;

    const goalsEntityType =
      entityType === EntityTypes[0].value
        ? GoalsEntityType.AgentOrTeam
        : GoalsEntityType.Agency;

    const token = await getAuthToken();

    if (!token) return;

    await agenciesApi(token).post('SetEntityGoals', {
      entityType: goalsEntityType,
      saleGoals,
    });
    await loadGoals();
  };

  const relevantGoals = useMemo(() => {
    if (entityType === EntityTypes[0].value) {
      return goals?.agentOrTeamGoals;
    }

    if (entityType === EntityTypes[1].value) {
      return goals?.agencyGoals ?? undefined;
    }
  }, [entityType, goals]);

  return [relevantGoals, saveGoals];
};

export default useGoals;
