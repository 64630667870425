import React, { useState, useCallback } from 'react';
import {
  PropertyAddress,
  PropertyDetails,
  PropertyImageUrls,
  VendorBuyer,
} from '@urbanx/agx-ui-components';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { beginCampaign } from 'Api/Campaigns/campaignsApi';
import PropertySearch from './PropertySearch/PropertySearch';
import ManualAddress from './ManualAddress/ManualAddress';
import './BeginCampaign.scss';

interface BeginCampaignProps {
  id: string;
}

enum BeginCampaignSteps {
  AddressSearchForm = 'AddressSearchForm',
  ManualAddressForm = 'ManualAddressForm',
}

const BeginCampaign: React.FC<BeginCampaignProps> = ({ id }) => {
  const [step, setStep] = useState(BeginCampaignSteps.AddressSearchForm);
  const [disableBeginCampaignButtons, setDisableBeginCampaignButtons] =
    useState(false);

  const [, getAuthToken] = useAzureAuth();

  const dispatch = useAppDispatch();

  const { formType, state } =
    useAppSelector(state => state.form.selectedForm) || {};

  const beginPropertyCampaign = useCallback(
    async (
      isManualAddress: boolean,
      propertyAddress: PropertyAddress,
      propertyImageUrls: PropertyImageUrls | undefined = undefined,
      propertyDetails: PropertyDetails | undefined = undefined,
      vendors: VendorBuyer[] | undefined = undefined
    ) => {
      if (disableBeginCampaignButtons) return;

      setDisableBeginCampaignButtons(true);
      if (getAuthToken === null || !formType || !state) return;

      const authToken = await getAuthToken();

      if (!authToken) {
        setDisableBeginCampaignButtons(false);
        return;
      }

      await beginCampaign(
        authToken,
        dispatch,
        propertyAddress,
        formType,
        state,
        isManualAddress,
        propertyImageUrls,
        propertyDetails,
        vendors
      );

      setDisableBeginCampaignButtons(false);
    },
    [
      disableBeginCampaignButtons,
      getAuthToken,
      beginCampaign,
      formType,
      state,
      dispatch,
    ]
  );

  let beginCampaignBody = null;

  if (step === BeginCampaignSteps.AddressSearchForm)
    beginCampaignBody = (
      <PropertySearch
        id={id}
        onManualAddress={() => setStep(BeginCampaignSteps.ManualAddressForm)}
        onBeginCampaign={(address, imageUrls, propertyDetails, vendors) =>
          beginPropertyCampaign(
            false,
            address,
            imageUrls,
            propertyDetails,
            vendors
          )
        }
        disabled={disableBeginCampaignButtons}
      />
    );

  if (step === BeginCampaignSteps.ManualAddressForm) {
    beginCampaignBody = (
      <ManualAddress
        disabled={disableBeginCampaignButtons}
        onAddressSearch={() => setStep(BeginCampaignSteps.AddressSearchForm)}
        onBeginCampaign={address => beginPropertyCampaign(true, address)}
      />
    );
  }

  return <div className="agx-noForm inverted">{beginCampaignBody}</div>;
};

export default BeginCampaign;
