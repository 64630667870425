interface FilterActionTypes {
  prop: string;
  value: string | number;
}
const ACTION_PROPS = [
  'fromPrice',
  'toPrice',
  'minBedrooms',
  'maxBedrooms',
  'minBathrooms',
  'maxBathrooms',
  'minCarSpaces',
  'maxCarSpaces',
  'minLandArea',
  'maxLandArea',
  'propertyType',
  'distanceOption',
];

export const filterReducer = (state: any, action: FilterActionTypes) => {
  if (ACTION_PROPS.includes(action.prop)) {
    return { ...state, [action.prop]: action.value };
  }
  return state;
};
