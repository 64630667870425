import React, { useState, useEffect } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import DepositPayable from './DepositPayable';
import './ContractDeposit.scss';
import {
  DepositType,
  ContractDepositValue,
  ContractDepositErrorState,
  DepositValue,
  ErrorState,
} from './types';
import {
  MaxDepositPercentage,
  ErrorMessage,
  DefaultErrorState,
  CalculateAmount,
} from './Deposit';
import { AgxDivider } from '@urbanx/agx-ui-components';

interface ContractDepositProps {
  id: string;
  onValueChanged: (change: { id: string; value: ContractDepositValue }) => void;
  defaultValue: ContractDepositValue;
  validate: boolean;
}

const ContractDeposit: React.FC<ContractDepositProps> = ({
  id,
  onValueChanged,
  defaultValue,
  validate,
}) => {
  const { PurchasePrice } = useAppSelector(state => state.form.formValues);

  const [contractDeposit, setContractDeposit] = useState({
    initialDeposit: {
      ...(defaultValue?.initialDeposit ?? {}),
      depositType: DepositType.Initial,
    },
    balanceDeposit: {
      ...(defaultValue?.balanceDeposit ?? {}),
      depositType: DepositType.Balance,
    },
  });

  const [errorState, setErrorState] = useState<ContractDepositErrorState>({
    initial: DefaultErrorState,
    balance: DefaultErrorState,
  });

  const ValidateContractDepositTotal = (
    initialDeposit: DepositValue,
    balanceDeposit: DepositValue
  ) => {
    const initialAmount = CalculateAmount(PurchasePrice, initialDeposit);
    const isInitialDepositInvalid =
      initialAmount > PurchasePrice * MaxDepositPercentage;
    let initialError: ErrorState = {
      depositError: isInitialDepositInvalid,
      message: isInitialDepositInvalid ? ErrorMessage : null,
    };

    const balanceAmount = CalculateAmount(PurchasePrice, balanceDeposit);
    let balanceError: ErrorState = DefaultErrorState;

    if (balanceAmount > 0) {
      const isBalanceDepositInvalid =
        initialAmount + balanceAmount > PurchasePrice * MaxDepositPercentage;
      balanceError = {
        depositError: isBalanceDepositInvalid,
        message: isBalanceDepositInvalid ? ErrorMessage : null,
      };
      initialError = balanceError;
    }

    setErrorState({
      ...errorState,
      initial: initialError,
      balance: balanceError,
    });
  };

  useEffect(() => {
    ValidateContractDepositTotal(
      contractDeposit?.initialDeposit,
      contractDeposit?.balanceDeposit
    );
    onValueChanged({ id, value: contractDeposit });
  }, [contractDeposit]);

  return (
    <>
      <DepositPayable
        key={DepositType.Initial}
        id={DepositType.Initial}
        label={`${DepositType.Initial} Deposit`}
        onValueChanged={({ value }) =>
          setContractDeposit({ ...contractDeposit, initialDeposit: value })
        }
        defaultValue={contractDeposit?.initialDeposit}
        validate={validate}
        required={true}
        error={errorState.initial}
      />
      <AgxDivider expanded />
      <DepositPayable
        key={DepositType.Balance}
        id={DepositType.Balance}
        label={`${DepositType.Balance} Deposit`}
        onValueChanged={({ value }) =>
          setContractDeposit({ ...contractDeposit, balanceDeposit: value })
        }
        defaultValue={contractDeposit?.balanceDeposit}
        validate={validate}
        error={errorState.balance}
      />
    </>
  );
};

export default ContractDeposit;
