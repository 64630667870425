import { PropertyImageUrls } from '@urbanx/agx-ui-components';
import { useAppSelector } from '../hooks/useAppSelector';

const usePropertyImageSelector = (): PropertyImageUrls | null => {
  const imagesFromFormConfig = useAppSelector(
    state => state.form.propertyImageUrls
  );

  return imagesFromFormConfig;
};

export default usePropertyImageSelector;
