import React from 'react';
import {
  AgxButton,
  AgxRow,
  AgxColumn,
  AgxTextInput,
  AgxBodyText,
  Images,
  AgxDivider,
} from '@urbanx/agx-ui-components';
import { formatCurrency } from '../../../utils/formatNumber';
import './CMASelectedProperties.scss';
import RPDataDisclaimer from '../../../components/common/RPDataDisclaimer';
import moment from 'moment';

export const CMASelectedProperties = ({
  selectedProperties,
  onEditSelectedProperties,
}) => {
  return (
    <>
      <AgxColumn fill extraClasses="selectedProperties">
        {selectedProperties.map((property, i) => {
          const {
            PropertyAddress: address,
            SoldPrice: price,
            DistanceInKilometers: distanceFromProperty,
            PropertyDetails: {
              NumberOfBathrooms: bathrooms,
              NumberOfBeds: bedrooms,
              NumberOfCarSpaces: carSpaces,
              TotalLandArea: landArea,
            },
            settlementDate,
          } = property;

          const timeAgo =
            settlementDate !== null ? moment(settlementDate).fromNow(true) : '';

          return (
            <div key={`address${i}+input`} className="searchNowProperty">
              <AgxTextInput
                defaultValue={address}
                readonly
                id={`address${i}`}
                stretch
                extraClasses="addressInput"
                label={`Comparable ${i + 1}`}
                optionalLabelText={`${distanceFromProperty}km away`}
              />
              <AgxTextInput
                defaultValue={formatCurrency(price, 0)}
                readonly
                noHeader
                id={`address${i}-input`}
                stretch
                extraClasses="priceInput"
              />
              <AgxRow mediumGap centered extraClasses="details">
                <AgxRow smallGap centered>
                  <Images.Bed />
                  <AgxBodyText small>{bedrooms}</AgxBodyText>
                </AgxRow>
                <AgxRow smallGap centered>
                  <Images.Bath />
                  <AgxBodyText small>{bathrooms}</AgxBodyText>
                </AgxRow>
                <AgxRow smallGap centered>
                  <Images.Car />
                  <AgxBodyText small>{carSpaces}</AgxBodyText>
                </AgxRow>
                <AgxRow smallGap centered>
                  <Images.Area />
                  <AgxBodyText small>{landArea}m²</AgxBodyText>
                </AgxRow>
                <AgxRow smallGap centered>
                  <AgxBodyText small>{timeAgo}</AgxBodyText>
                </AgxRow>
              </AgxRow>
            </div>
          );
        })}
        <AgxButton
          id="editComparables"
          text="Edit Comparables"
          primary
          medium
          onClick={e => {
            e.preventDefault();
            onEditSelectedProperties();
          }}
          rightIcon={<Images.EditOutline />}
        />
        <AgxDivider />
        <RPDataDisclaimer />
      </AgxColumn>
    </>
  );
};
