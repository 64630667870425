import { PublicClientApplication } from '@azure/msal-browser';
import { authConfig } from '../features/SignIn/config/authConfig';

const useAuth = () => {
  const config = authConfig();

  const msalInstance = new PublicClientApplication(config);

  return { msalInstance };
};

export default useAuth;
