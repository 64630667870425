import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { AgxRow, AgxLabel, AgxDatePicker } from '@urbanx/agx-ui-components';

const DateTime = ({
  id,
  label,
  inputType,
  minLength,
  maxLength,
  elementType,
  required,
  defaultValue,
  value,
  onValueChanged,
}) => {
  const [contractDateTime, setContractDateTime] = useState(
    (defaultValue && moment(defaultValue)) ?? moment()
  );

  useEffect(() => {
    onValueChanged({
      id,
      value: contractDateTime.format('YYYY-MM-DDTHH:mm:ss'),
    });
  }, [contractDateTime]);

  return (
    <>
      <AgxLabel medium>{label}</AgxLabel>
      <AgxRow largeGap fill>
        <AgxDatePicker
          id={`${id}-date`}
          key={`${id}-date`}
          noHeader
          noOptionalLabel
          required={required}
          defaultValue={contractDateTime}
          onValueChanged={({ value }) => {
            const datePortion = moment(value, 'YYYY-MM-DD');
            const constructedDate = moment(
              `${datePortion.format('YYYYMMDD')}, ${contractDateTime.format(
                'HH:mm'
              )}}`,
              'YYYYMMDD, HH:mm'
            );
            setContractDateTime(constructedDate);
          }}
          date
          stretch
        />
        <AgxDatePicker
          id={`${id}-time`}
          key={`${id}-time`}
          noHeader
          noOptionalLabel
          required={required}
          defaultValue={contractDateTime}
          onValueChanged={({ value }) => {
            const timePortion = moment(value, 'HH:mm');
            const constructedTime = moment(
              `${contractDateTime.format('YYYYMMDD')}, ${timePortion.format(
                'HH:mm'
              )}}`,
              'YYYYMMDD, HH:mm'
            );
            setContractDateTime(constructedTime);
          }}
          date={false}
          stretch
        />
      </AgxRow>
    </>
  );
};

export default DateTime;
