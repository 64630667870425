const HomeIconThemed = ({ width = 22, height = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7537 14.248H5.25371V10.125C5.25371 9.71079 4.91792 9.375 4.50371 9.375C4.08949 9.375 3.75371 9.71079 3.75371 10.125V14.248C3.75371 15.0765 4.42528 15.748 5.25371 15.748H12.7537C13.5804 15.7456 14.2492 15.0747 14.2492 14.248V10.125C14.2492 9.71079 13.9134 9.375 13.4992 9.375C13.085 9.375 12.7492 9.71079 12.7492 10.125L12.7537 14.248ZM14.5327 8.82554C14.6844 8.95514 14.8827 9.01679 15.0812 8.9961C15.2797 8.9754 15.461 8.87416 15.5827 8.71604C15.8441 8.38162 15.7979 7.90111 15.4777 7.62254L9.51895 2.44754C9.21252 2.18415 8.75963 2.18415 8.4532 2.44754L2.52445 7.60154C2.20433 7.88008 2.15789 8.36032 2.4187 8.69504C2.54057 8.85297 2.72183 8.95407 2.92024 8.97476C3.11865 8.99545 3.31687 8.93393 3.4687 8.80454L8.99545 4.01354L14.5274 8.82929L14.5327 8.82554ZM9.01721 11.9905C9.84256 11.9881 10.5099 11.3174 10.5082 10.492C10.5066 9.66669 9.83657 8.99872 9.01122 8.99954C8.18586 9.00037 7.51722 9.66968 7.51721 10.495C7.51969 11.3217 8.19054 11.9905 9.01721 11.9905Z"
      fill={getComputedStyle(document.documentElement).getPropertyValue(
        '--brand-secondary-color'
      )}
    />
  </svg>
);

export default HomeIconThemed;
