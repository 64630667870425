import React from 'react';
import ContentLoader from 'react-content-loader';
import './PropertyResultsLoader.scss';

const numberOfLoaders = 8;

export const PropertyResultsLoader = () => {
  const loaders = [];

  for (var i = 0; i < numberOfLoaders; i++) {
    loaders.push(
      <ContentLoader
        key={`propertyResultsLoader-${i}`}
        backgroundColor="#fafafa"
        foregroundColor="#cdcdcd"
        backgroundOpacity={1}
        foregroundOpacity={1}
        viewBox="0 0 388 108"
        className="propertyResultsLoader"
      >
        <rect x="9" y="9" rx="10" ry="10" width="80" height="90" />
        <rect x="98" y="18" rx="5" ry="5" width="240" height="23" />
        <rect x="98" y="45" rx="5" ry="5" width="200" height="23" />
        <rect x="98" y="72" rx="5" ry="5" width="220" height="23" />
        <rect x="350" y="45" rx="90" ry="90" width="30" height="30" />
      </ContentLoader>
    );
  }

  return <>{loaders}</>;
};
