import React, { useEffect, useState } from 'react';
import {
  AgxControlledCheckbox,
  AgxCurrency,
  AgxRow,
} from '@urbanx/agx-ui-components';
import { parseFloatReturnEmptyIfZero } from 'utils/parseFloatReturnEmptyIfZero';
import '../MarketingPackages.scss';

const AgentToFillItem = ({
  id,
  productName,
  pricePerUnit,
  selected,
  validate,
  onSelectProductChange,
}) => {
  const [agentPrice, setAgentPrice] = useState(pricePerUnit);
  const [productSelected, setProductSelected] = useState(selected);

  useEffect(() => {
    setProductSelected(selected);
  }, [selected]);

  useEffect(() => {
    const number = Number(agentPrice);

    onSelectProductChange({
      id,
      productName,
      pricePerUnit: !Number.isNaN(agentPrice) ? number : null,
      value: productSelected,
    });
  }, [agentPrice, productSelected]);

  const onValueChanged = value => {
    setProductSelected(value === true);
  };

  const onInputValueChange = ({ value }) => {
    const newValue = value?.toString()?.replace(/,/g, '');
    setAgentPrice(newValue ? Number(newValue) : null);
  };

  return (
    <AgxRow
      fill
      spaceBetween
      centered
      mediumGap
      extraClasses="marketingPackageItem"
    >
      <AgxControlledCheckbox
        naked
        label={productName}
        checked={productSelected}
        onValueChanged={onValueChanged}
      />
      <AgxCurrency
        id={`marketingpackage-item-text-${productName}-${id}`}
        required
        defaultValue={parseFloatReturnEmptyIfZero(agentPrice)}
        onInputValueChange={onInputValueChange}
        decimalPoints={2}
        small={true}
        alignRight={true}
        extraClasses={`agentToFillCurrency ${
          productSelected &&
          validate &&
          !parseFloatReturnEmptyIfZero(agentPrice) &&
          'error'
        }`}
        displayErrors={
          productSelected &&
          validate &&
          !parseFloatReturnEmptyIfZero(agentPrice)
        }
        errorMessage={
          productSelected &&
          validate &&
          !parseFloatReturnEmptyIfZero(agentPrice) &&
          'Enter price'
        }
        validate={productSelected && validate}
      />
    </AgxRow>
  );
};

export default AgentToFillItem;
