import {
  AgxBodyText,
  AgxColumn,
  AgxHeader,
  formatCurrency,
  formatNumber,
} from '@urbanx/agx-ui-components';
import { StatCardData } from './StatCard';

const formattedValue = (
  noData: boolean,
  bold: boolean,
  item: StatCardData
): string => {
  if (noData || !item.value) {
    return bold ? '- - -' : '- -';
  }

  if (item.isPercentage) {
    return `${formatNumber(item.value.toString(), 1)}%`;
  }
  if (item.isCurrency) {
    return formatCurrency(item.value, 0);
  }

  return item.value.toString();
};

interface StatCardTitleSectionProps {
  item: StatCardData;
  bold: boolean;
  noData: boolean;
  loading: boolean;
  subText: string | null;
  fill?: boolean;
}

const StatCardTitleSection = (props: StatCardTitleSectionProps) => {
  return (
    <AgxColumn fill={props.fill ?? false}>
      <AgxBodyText medium>{props.item.title}</AgxBodyText>
      {!props.loading && (
        <AgxHeader size={props.bold ? 1 : 2}>
          {formattedValue(props.noData, props.bold, props.item)}
        </AgxHeader>
      )}
      {!props.loading && props.subText && (
        <AgxBodyText medium neutralGrayColor>
          {props.subText}
        </AgxBodyText>
      )}
    </AgxColumn>
  );
};

export default StatCardTitleSection;
