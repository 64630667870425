import { useAppDispatch } from './useAppDispatch';
import { setFormSettings } from '../features/form/formSettingsReducer';

export interface FormSettingsTypes {
  listingAddress: null;
  showBreadcrumbs: boolean;
  showListingDetails: boolean;
  displayTitle: string | null;
  displayTitleVisible: boolean;
  formBackgroundInverted: boolean;
  viewPreFill: boolean;
  growContent: boolean;
  noGap: boolean;
}

export const useFormSettings = () => {
  const dispatch = useAppDispatch();

  const updateFormSettings = (settings: Partial<FormSettingsTypes>) => {
    dispatch(
      setFormSettings({
        ...settings,
        growContent: settings.growContent || false,
        noGap: settings.noGap || false,
      })
    );
  };

  return updateFormSettings;
};
