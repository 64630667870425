import React, { useEffect, useState } from 'react';
import {
  AgxBodyText,
  AgxButton,
  AgxCaption,
  AgxControlledCheckbox,
  AgxRow,
} from '@urbanx/agx-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from 'utils/loadingState';
import { reloadDomain } from '../../../domain/domainReducer';
import { formatCurrencyAsNumber } from 'utils/formatNumber';

const DomainItem = ({ id, productName, selected, onSelectProductChange }) => {
  const dispatch = useDispatch();
  const { loadingState: domainLoadingState, contract } = useSelector(
    state => state.domain
  );

  const [productSelected, setProductSelected] = useState(selected);

  useEffect(() => {
    setProductSelected(selected === true);
  }, [selected]);

  useEffect(() => {
    onSelectProductChange({
      id,
      productName,
      pricePerUnit: contract?.price,
      value: productSelected,
    });
  }, [productSelected]);

  const onValueChanged = value => {
    setProductSelected(value === true);
  };

  let domainItemBody;

  if (domainLoadingState === LoadingState.Failed) {
    domainItemBody = (
      <AgxCaption>
        <AgxButton
          small
          naked
          onClick={() => {
            dispatch(reloadDomain());
          }}
          text={`Failed to retrieve ${productName} pricing. Click here to try again.`}
        ></AgxButton>
      </AgxCaption>
    );
  } else if (domainLoadingState === LoadingState.Loading) {
    domainItemBody = (
      <AgxBodyText small>Loading {productName} pricing</AgxBodyText>
    );
  } else {
    domainItemBody = (
      <AgxBodyText small>
        {contract != null
          ? `$${formatCurrencyAsNumber(contract.price, 2)}`
          : 'Complimentary'}
      </AgxBodyText>
    );
  }

  return (
    <AgxRow
      fill
      spaceBetween
      centered
      mediumGap
      extraClasses="marketingPackageItem"
    >
      <AgxControlledCheckbox
        naked
        label={productName}
        checked={productSelected}
        disabled={domainLoadingState === LoadingState.Failed}
        onValueChanged={onValueChanged}
      />
      {domainItemBody}
    </AgxRow>
  );
};

export default DomainItem;
