import {
  UploadedDocument,
  DocumentTypes,
  CertificateInfo,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from '../hooks/useAppSelector';

const useListingDocumentsSelector = () => {
  const generatedFormDocuments: UploadedDocument[] = useAppSelector(
    state => state.form.generatedFormDocuments
  );
  const certificateInfo = useAppSelector(
    state => state.form.formValues?.PoolDocumentation?.poolSafetyCertificate
  ) as CertificateInfo;
  const documents = [
    ...generatedFormDocuments,
    ...(certificateInfo?.hasCertificate
      ? [
          {
            ...certificateInfo?.certificate,
            documentType: DocumentTypes.PoolCertificate,
          },
        ]
      : []),
  ];

  return documents ?? [];
};

export default useListingDocumentsSelector;
